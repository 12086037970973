import React from "react";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { addressFields, buildAddressData, getAddressEditables } from "../../../auto/js/lists/AddressList";
import { buildEmailData, emailFields, getEmailEditables } from "../../../auto/js/lists/EmailList";
import { buildPhoneNumberData, getPhoneNumberEditables, phoneNumberFields } from "../../../auto/js/lists/PhoneNumberList";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { t } from "../../../auto/js/services";

export class ContactComponent extends React.Component {
	constructor(props) {
		super(props);
	}

    render() {
        let AddressList = createTableComponent(addressFields);
		let EmailList = createTableComponent(emailFields);
		let PhoneNumberList = createTableComponent(phoneNumberFields);

        return (
			<>
            <Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>{t`Address`}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<AddressList loadData={async (query) => buildAddressData(query, this.props.id)} editable={getAddressEditables(this.props.id)} />
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>{t`Email`}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<EmailList loadData={async (query) => buildEmailData(query, this.props.id)} editable={getEmailEditables(this.props.id)} />
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>{t`PhoneNumber`}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<PhoneNumberList loadData={async (query) => buildPhoneNumberData(query, this.props.id)} editable={getPhoneNumberEditables(this.props.id)} />
					</AccordionDetails>
				</Accordion>
            </>
        )
    }
}