import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from "../../events/Gui";
import { rest, t } from "../../services";
import { AlertDialog } from "../../widgets";
import { FileUploader } from "../../widgets/FileUploader";
import { buildEmailData, getEmailEditables } from "../../lists/EmailList";
import { buildPhoneNumberData, getPhoneNumberEditables, } from "../../lists/PhoneNumberList";
import { loadCivilStatusMtlbAttachment } from "../../../../main/js/forms/birthRegistration/BirthRegistrationFormCommon";
import { loadFormData } from "../CivilRecordForm";
import { buildAddressData, getAddressEditables } from "../../lists/AddressList";
import { MatchForm } from "./AdjudicateIdMtlbForm";

export class MatchFormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<MatchForm readOnly ref={this.myRef} key={this.props.key} loadData={async () => loadFormData(this.props.id, true)} id={this.props.id} buttons={() => null} />	
		</>
		)
	}

}
