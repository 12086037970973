import { process } from "../../../main/js/tasks/DeathApplication.jsx";
import { Task } from "../../../auto/js/widgets/Task";

export class DeathApplicationTask extends Task {
    constructor(id, name, gui, args) {
        super(id, name, gui, args);
    }
    
    start = (onFinishCallback) => {
        this.onFinishCallback = onFinishCallback;
        process(this);
    }
}