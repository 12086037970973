import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent";
import { displayReadOccupationForm } from "../../forms/metadata/OccupationForm";
import { getServiceUri } from "../../../../auto/js/metadata";
import { OCCUPATION_METADATA_ORDER_BY_NAME } from "../../../../auto/js/metadata/OccupationMetadataOrderBy";
import { OPEN_CONFIRMATION_DIALOG } from "../../../../auto/js/events/Gui";

const fields = [
    {title: "id", field: "id"},
    {title: "name", field: "name"},
];

export const displayOccupationList = () => {
    let OccupationList = createTableComponent(fields);
    let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <OccupationList key={uuid} loadData={async (query) => buildData(query)} actions={getTableActions()} />
	});
}

const buildData = async (query) => {
    let filter = query;
    let data;
    filter["occupation-metadata"] = {};
    filter["orderBy"] = OCCUPATION_METADATA_ORDER_BY_NAME;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='')
        filter["occupation-metadata"]["name"]= query.search;
    return await getOccupationsData(filter).then(response => {
        data = response;
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
}

const countData = async (filter) => {
    return await rest.count("occupation-metadata", filter);
}

const getOccupationsData = async (filter) => {
    return await rest.search("occupation-metadata", filter);
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    displayReadOccupationForm(rowData.id);
                }
        }
    )
    return actions;   
}