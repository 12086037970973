import React, {useState} from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Facets from "../widgets/Facets";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";

import { OPEN_ADVANCED_SEARCH, OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent"
import { displayReadApprovedDivorceRegistrationForm } from "../../../auto/js/forms/divorceRegistration/ApprovedDivorceRegistrationForm";
import { UNION_RECORD_ORDER_BY_PARTNER1_FIRSTNAME } from "../../../auto/js/metadata/UnionRecordOrderBy";
import { displayUnionRecordForm } from "../../../main/js/forms/UnionRecordForm";
import { getErrorList, transformAdvancedSearchData, advancedSearchfields, filterData } from "../../../main/js/lists/UnionRecordListCommon"

const fields = [
    {title: "ID", field: "id"},
	{title: "partner1VitalRecordId", field: "partner1VitalRecordId"},
	{title: "partner1Surname", field: "partner1Surname"},
    {title: "partner1Firstname", field: "partner1Firstname"},
	{title: "partner2VitalRecordId", field: "partner2VitalRecordId"},
	{title: "partner2Surname", field: "partner2Surname"},
    {title: "partner2Firstname", field: "partner2Firstname"},
    {title: "place", field: "place"},
	{title: "Marriage Status", field: "marriageStatus"}
];

const UnionRecordListPage = ({uuid, gui, searchData}) => {
	const[selectAllError, setSelectAllError] = useState(false);
	const [errorList, setErrorList] = useState([]);
	const [advancedSearchData, setAdvancedSearchData] = useState(searchData);
	
	let UnionRecordList = createTableComponent(fields);
	
	const onErrorFacetChange = (key, value) => {
	    let list = _.clone(errorList);
	    if (key === "selectAllError") {
	        if (value.target.checked)
	            list = selectAllErrors();
	        else
	            list = [];
	        setErrorList(list);
	        setSelectAllError(value.target.checked)
	    }
	    else if (value.target.checked && !list.includes(key)) {
	        list.push(key);
	        setErrorList(list);
	    }
	    else if (!value.target.checked && list.includes(key)) {
	        let index = list.indexOf(key);
	        list.splice(index, 1);
	        setErrorList(list);
	    }
	}
	
	if (advancedSearchData != searchData)
		setAdvancedSearchData(searchData);

	const buildData = async (query) => {
	    let filter = query;
	    let data;
	    filter["union-record"] = {};
	    filter['and'] = false;
	    filter["orderBy"] = UNION_RECORD_ORDER_BY_PARTNER1_FIRSTNAME;
	    if (advancedSearchData)
            filter["query"] = advancedSearchData;
	    filter.orderDirection = null;
	    filter.offset = query.page * query.pageSize;
	    let unionRecordTags =[];
	    
	     errorList.forEach(element => {
	        unionRecordTags.push( {content: element} )
	    });
		filter["union-record"]["unionRecordTags"] =  unionRecordTags;
	    if (query.search && query.search!='') {
	        pojoMetadata["union-record"].columns.forEach(element => {
	            if(element.type=='text' ){
	                filter["union-record"][element.key]= query.search;
	            }
	        });
	    }
	    return await getUnionRecordData(filter).then(response => {
	        data = filterData(response);
	        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
	    });
    }
	    const getUnionRecordData = async (filter) => {
	    	if (advancedSearchData) {
	    		return await rest.request(getServiceUri() + "union-record/advanced-search", "POST", filter);
	    	} 
	    	else
		    	return await rest.search("union-record", filter)
		}
		
		const countData = async (filter) => {
			if (advancedSearchData) {
				return await rest.request(getServiceUri() + "union-record/count/advanced-search", "POST", filter);
			}
			else
		    	return await rest.count("union-record", filter);
		}
		
		const getErrorCodeFacets = () => {
	       let list = [{key: "selectAllError", value: selectAllError, label: t`select/deselect all`, separator: true}];
	       	getErrorList().forEach(error => {
	            list.push({key: error, value: errorList.includes(error), label: t(error)});
	
	        });
	        return list;
	    }
	    
	    const onAdvancedSearchSubmit = (data) => {
        	let dto = transformAdvancedSearchData(data);
        	setAdvancedSearchData(dto);
    	}
	    
	 	const buildFacets = (key, value, label, handleChange) => {
		    return (
		        <>
		        <FormControlLabel
		        control={<Checkbox
		          checked={value}
		          onChange={(event) => handleChange(key, event)}
		          color="primary"
		          value={value}
		        />
		        }
		        label={label}
		        style={{width: '100%'}}
		        />
		        </>
		    )
		}
	    
	    return (
	    <div>
	        <Facets title={t`Errors`} facets={getErrorCodeFacets()} facetsComponents={(key, value) => buildFacets(key, value, t(key), onErrorFacetChange)} />
	        <UnionRecordList key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions(gui)} advancedSearchFields={advancedSearchfields} onSubmit={onAdvancedSearchSubmit}/>
	    </div>
		)
}

export const displayUnionRecordList = (gui, searchData) => {
	const readUnionRecordList = (onFinich) => () => {
		let uuid = uuidv4();
		return {
			uuid, view: () =>  <UnionRecordListPage uuid={uuid} gui={gui} searchData={searchData}/>
		};
	}
	gui.goTo(readUnionRecordList())
}

export const displayUnionRecordAdvancedSearch = (gui) => {
    let advancedSearchFilters = {fields: advancedSearchfields, name: t`Union Record`, onSubmit: (filter) => onAdvancedSearchSubmit(filter, gui)};
	OPEN_ADVANCED_SEARCH.publish(advancedSearchFilters);
}

const onAdvancedSearchSubmit = (filter, gui) => {
    displayUnionRecordList(gui, transformAdvancedSearchData(filter));
}

const selectAllErrors = () => {
 	let list = ["selectAllError"];
    getErrorList().forEach(error => {
            list.push(error);
    });
    return list;
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`View`,
                onClick: (event, rowData) => {
                    displayUnionRecordForm(gui, rowData.id);
                }
        }
    )
    return actions;   
}