import { rest } from "../../../auto/js/services/RestClient";
import { getServiceUri } from "./MetadataLoader";

class GeoDataMetadataLoader {

    constructor() {
        if (!!GeoDataMetadataLoader.instance) {
            return GeoDataMetadataLoader.instance;
    }
    GeoDataMetadataLoader.instance = this;
    this.areas= {};
    this.children = {};
    this.locationsdata = [];
    this.maxAreaId = 0;
	this.maxRootAreaId = 0;
    return this;
}

	load = async () => {
		let filter = {};
		filter['geo-data'] = {disabled: false};
		filter.orderBy = null;
        this.areas= {};
		this.lastLevelAreaIdList = [];
		return rest.search('geo-data', filter)
			.then(response => {
				response.forEach(element => {
					this.areas[element.areaId] = { "name": element.name, "id": element.id };
					if (element.type < 7 && !this.children[element.areaId] ) {
						this.children[element.areaId] = [];
					}
					if (element.type == 6) {
						this.lastLevelAreaIdList.push(element.areaId);
						if (parseInt(element.areaId) > this.maxAreaId)
							this.maxAreaId = parseInt(element.areaId)
					}
					if (!this.isRootNode(element.areaId)) {
						let parentareaid = this.getParentAreaId(element.areaId);
						this.children[parentareaid]? this.children[parentareaid].push(element.areaId) :
						this.children[parentareaid] = [element.areaId];
					} else
						if (parseInt(element.areaId) > this.maxRootAreaId)
							this.maxRootAreaId = parseInt(element.areaId);
				});
			})
			.then(()=> {
			this.locationsdata = [];
			this.preparelocationsdata();
			});
	}

	preparelocationsdata = () => {
		var eldetails = [];
		const getLocationsFlat = (ellist, eldetail) => {
			ellist.forEach(el => {
			var elname = eldetail + "#" + this.areas[el].name + "::" + this.areas[el].id;
			if (Array.isArray(this.children[el]) && (this.children[el].length)) {
				getLocationsFlat(this.children[el], elname);
			}
			else {
				eldetails.push({"Hierarchy":elname, "Areaid": el});
			}
			});
			return eldetails;
		}
		this.lastLevelAreaIdList.forEach(element => {
			if (this.children[element].length)
				eldetails = getLocationsFlat(this.children[element],
					this.areas[element].name + "::" + this.areas[element].id)
		});
		
		eldetails.forEach(el => {
			const ArrayData = el.Hierarchy.split("#");
			this.locationsdata.push({
			    "country": ArrayData[0]?.split("::")[0],
			    "countryId": ArrayData[0]?.split("::")[1],
			    "province": ArrayData[1]?.split("::")[0],
			    "provinceId": ArrayData[1]?.split("::")[1],
			    "zone": ArrayData[2]?.split("::")[0],
			    "zoneId": ArrayData[2]?.split("::")[1],
			    "district": ArrayData[3]?.split("::")[0],
			    "districtId": ArrayData[3]?.split("::")[1],
			    "island": ArrayData[4]?.split("::")[0],
			    "islandId": ArrayData[4]?.split("::")[1],
			    "location": ArrayData[5]?.split("::")[0],
			    "locationId": ArrayData[5]?.split("::")[1],
			    "areaId": el.Areaid
			})
		});
	}

	getParentAreaId = (areaId) => {
		return areaId.substring(0, areaId.lastIndexOf("."))
	}

	isRootNode = (areaId) => {
		return areaId.lastIndexOf(".") === -1;
	}

	getRootNodes = () => {
  		let areaids = Object.keys(this.areas).filter(key => key.lastIndexOf(".") === -1);
	    let result = Object.fromEntries(areaids.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result;
	}

	getChilds = (id) => {
		let areaid = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let result = Object.fromEntries(this.children[areaid].map(x => [this.areas[x].id, this.areas[x].name]));
		return result;
	}
	
    getAreaId = (id) => {
        return Object.keys(this.areas).find(key => this.areas[key].id == id);
    }
    
    getChildsByAreaId = (areaId) => {
		if (this.children[areaId])
			return Object.fromEntries(this.children[areaId].map(x => [this.areas[x].id, this.areas[x].name]));
		else
			return []
	}
    
    getArea = (areaId) => {
        return this.areas[areaId];
    }
    
    getAreaLevelName = (areaId) => {
		let areaComponents = areaId.split(".");
		switch (areaComponents.length) {
			case (1):
				return "country";
			case (2):
				return "province";
			case (3):
				return "zone";
			case (4):
				return "district";
			case (5):
				return "island";
			case (6):
				return "location";
		}
	}
	
	getLevelChildsByIndex = (index) => {
		let areaids = Object.keys(this.areas).filter(key => key.lastIndexOf(".") === index);
	    let result = Object.fromEntries(areaids.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result;
	}
	getCountryList = async () => {
		let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
		let countryList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 1);
		areaids.forEach(element => {
			let country = this.getArea(element);
			countryList.push({
			    "country": country.name,
			    "countryId": country.id,
			    "areaId": element,
				"flag": getServiceUri() + 'geo-data/flag/' + country.id + "/" + token
			})
		});
		return countryList;
	}
	getProvinceList = () => {
		let provinceList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 2);
		areaids.forEach(element => {
			let province = this.getArea(element);
			let areaComponents = element.split(".");
			let countryAreaId = areaComponents[0];
			let country = this.getArea(countryAreaId);
			provinceList.push({
				"country": (country)?country.name:"",
			    "countryId": (country)?country.id:"",
			    "province": province.name,
			    "provinceId": province.id,
			    "areaId": element,
			})
		});
		return provinceList;
	}
	getZoneList = () => {
		let zoneList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 3);
		areaids.forEach(element => {
			let zone = this.getArea(element);
			let areaComponents = element.split(".");
			let countryAreaId = areaComponents[0];
			let country = this.getArea(countryAreaId);
			let provinceAreaId = areaComponents[0] + "." + areaComponents[1];
			let province = this.getArea(provinceAreaId);
			zoneList.push({
				"country": (country)?country.name:"",
			    "countryId": (country)?country.id:"",
				"province": (province)?province.name:"",
			    "provinceId": (province)?province.id:"",
			    "zone": zone.name,
			    "zoneId": zone.id,
			    "areaId": element,
			})
		});
		return zoneList;
	}
	getDistrictList = () => {
		let districtList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 4);
		areaids.forEach(element => {
			let district = this.getArea(element);
			let areaComponents = element.split(".");
			let countryAreaId = areaComponents[0];
			let country = this.getArea(countryAreaId);
			let provinceAreaId = areaComponents[0] + "." + areaComponents[1];
			let province = this.getArea(provinceAreaId);
			let zoneAreaId = areaComponents[0] + "." + areaComponents[1] + "." + areaComponents[2];
			let zone = this.getArea(zoneAreaId);
			districtList.push({
				"country": (country)?country.name:"",
			    "countryId": (country)?country.id:"",
				"province": (province)?province.name:"",
			    "provinceId": (province)?province.id:"",
				"zone": (zone)?zone.name:"",
			    "zoneId": (zone)?zone.id:"",
			    "district": district.name,
			    "districtId": district.id,
			    "areaId": element,
			})
		});
		return districtList;
	}
	getIslandList = () => {
		let islandList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 5);
		areaids.forEach(element => {
			let island = this.getArea(element);
			let areaComponents = element.split(".");
			let countryAreaId = areaComponents[0];
			let country = this.getArea(countryAreaId);
			let provinceAreaId = areaComponents[0] + "." + areaComponents[1];
			let province = this.getArea(provinceAreaId);
			let zoneAreaId = areaComponents[0] + "." + areaComponents[1] + "." + areaComponents[2];
			let zone = this.getArea(zoneAreaId);
			let districtAreaId = areaComponents[0] + "." + areaComponents[1] + "." + areaComponents[2] + "." + areaComponents[3];
			let district = this.getArea(districtAreaId);
			islandList.push({
				"country": (country)?country.name:"",
			    "countryId": (country)?country.id:"",
				"province": (province)?province.name:"",
			    "provinceId": (province)?province.id:"",
				"zone": (zone)?zone.name:"",
			    "zoneId": (zone)?zone.id:"",
				"district": (district)?district.name:"",
			    "districtId": (district)?district.id:"",
			    "island": island.name,
			    "islandId": island.id,
			    "areaId": element,
			})
		});
		return islandList;
	}
	getLocationList = () => {
		let locationList = [];
		let areaids = Object.keys(this.areas).filter(key => key.split(".").length === 6);
		areaids.forEach(element => {
			let location = this.getArea(element);
			let areaComponents = element.split(".");
			let countryAreaId = areaComponents[0];
			let country = this.getArea(countryAreaId);
			let provinceAreaId = areaComponents[0] + "." + areaComponents[1];
			let province = this.getArea(provinceAreaId);
			let zoneAreaId = areaComponents[0] + "." + areaComponents[1] + "." + areaComponents[2];
			let zone = this.getArea(zoneAreaId);
			let districtAreaId = areaComponents[0] + "." + areaComponents[1] + "." + areaComponents[2] + "." + areaComponents[3];
			let district = this.getArea(districtAreaId);
			let islandAreaId = areaComponents[0] + "." + areaComponents[1] + "." + areaComponents[2] + "." + areaComponents[3] + "." + areaComponents[4];
			let island = this.getArea(islandAreaId);
			locationList.push({
				"country": (country)?country.name:"",
			    "countryId": (country)?country.id:"",
				"province": (province)?province.name:"",
			    "provinceId": (province)?province.id:"",
				"zone": (zone)?zone.name:"",
			    "zoneId": (zone)?zone.id:"",
				"district": (district)?district.name:"",
			    "districtId": (district)?district.id:"",
				"island": (island)?island.name:"",
			    "islandId": (island)?island.id:"",
			    "location": location.name,
			    "locationId": location.id,
			    "areaId": element,
			})
		});
		return locationList;
	}
    
    getGeoLevel = (id, level) => {
		let areaId = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let areaIds = Object.keys(this.areas).filter(key => key.split(".").length === level + 1 && key.startsWith(areaId))
		let result = Object.fromEntries(areaIds.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result; 
	}
	
	getNewAreaId = () => {
		this.maxAreaId = this.maxAreaId + 1;
		return this.maxAreaId;
	}

	incrementAreaIdFromParentId = (id) => {
		let nextAreaId = 0;
		if (id) {
			let areaid = Object.keys(this.areas).find(key => this.areas[key].id == id);
			nextAreaId = `${areaid}.0`;
			if (this.children[areaid].length) {
				let biggerAreaId = Math.max(...this.children[areaid].map(x => x.split(".").slice(-1)[0]));
				nextAreaId = `${areaid}.${biggerAreaId + 1}`;
			}
		} else {
			nextAreaId = `${this.maxRootAreaId + 1}`
		}
		
		return nextAreaId;	
	}

}

export const geoDataMetadataLoader = new GeoDataMetadataLoader();
