import React, { useRef } from "react";
import { v4 as uuidv4 } from 'uuid';

import { setContextualOptions } from '../../../auto/js/widgets/RibbonTab'; 
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT, PRINT_EVENT, OPEN_SIDE_PANEL } from '../../../auto/js/events/Gui';
import { rest, t } from "../../../auto/js/services";
import { buildEmptyObject, getServiceUri, metadataLoader, pojoMetadata } from "../../../auto/js/metadata";
import { showNotification } from "../../../auto/js/utils";
import { certificateStyle } from "./CertificateStyleObj";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploader } from "../widgets/FileUploader";

import { createTableComponent } from "../widgets/TableComponent";
import { civilRecordFormfields } from "../../../main/js/forms/CivilRecordFormCommon"
import { AttachmentsArea } from "../widgets";
import { BirthCertificate } from './BirthCertificate';
import * as Yup from 'yup';

import '../../css/printable.css';
import QRCode from "react-qr-code";
import { MTLB_TYPE_ID_CARD } from "../../../auto/js/metadata/MtlbType";
import { displayNewAmendmentApplicationForm } from "./amendmentApplication/NewAmendmentApplicationForm";
import { displayNewIdCardApplicationForm } from "./idCardApplication/NewIdCardApplicationForm";
import { Button } from "@mui/material";
import { NotesGrid } from "../../../auto/js/widgets/NotesGrid";
import { HistoryGrid } from "../../../auto/js/widgets/HistoryGrid";
import { TypeOfAcquisition } from "../metadata/TypeOfAcquisition";
import { displayNewAdoptionApplicationForm } from "./adoptionApplication/NewAdoptionApplicationForm";
import { displayIdCardApplicationManager } from "./IdCardApplicationManager";
import { displayDeathApplicationManager } from "./DeathApplicationManager";

const printBirthCertificate = (data) => () => {
	const printable = {
				content:<BirthCertificate data={data}/>,
				style:{certificateStyle}.certificateStyle,
				copyParentStyle:false
	}
	PRINT_EVENT.publish(printable);
}
const applyIdCard = (id) => () => {
	let dto = pojoMetadata['id-mtlb'].form2dto(buildEmptyObject('id-mtlb'));
	dto.vitalRecordId = id;
	dto.mtlbType = MTLB_TYPE_ID_CARD;
	rest.request(getServiceUri() + 'apply/create-id-mtlb','POST', dto);
}
export const readCivilRecordForm = (onFinish) => (id) => {
	let CivilRecordForm = createFormComponent(civilRecordFormfields);
	let uuid = uuidv4();
	return {
		uuid,
		view: () => {
			return (
				<>
					<Button className="link-button" onClick={() => openHistorySidePanel(id)}>{t`History`}</Button>
					<Button className="link-button" onClick={() => openSidePanel(id)}>{t`Notes`}</Button>
					<CivilRecordForm key={uuid} readOnly loadData={async () => loadFormData(id)} buttons={() => null}/>
					<AttachmentsArea label={t`Attachments`} previewUrl={(id) => getPreviewUrl(id)} loadData={async () => loadCivilRecordAttachment(id)} handleClick={(fileId) => handleCivilRecordAttachmentClick(fileId)} readOnly/>
				</>
			)
		}
	}
}
export const displayReadCivilRecordForm = async (id, gui) => {
	
	gui.goTo(readCivilRecordForm(), id);
}

const handleCivilRecordAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'vital-record-attachment' + '/' + id + '/' + token;
};

const getPreviewUrl = (id) => {
	return getServiceUri() + 'vital-record-attachment' + '/preview/' + id;
}

const loadCivilRecordAttachment = async (id) => {
	let filter = {and: true};
	filter['vital-record-attachment'] = {};
	filter['vital-record-attachment']['vitalRecordId'] = id;
	return rest.search('vital-record-attachment', filter)
}

const openSidePanel = (id) => {
	let noteFilter = {and: true};
	noteFilter['vital-record-note'] = {vitalRecordId: id};
	rest.search('vital-record-note', noteFilter).then(notes => {
		OPEN_SIDE_PANEL.publish(
			{body: <NotesGrid list={notes}/>, title: t`Notes`}
		)
	})
}

const openHistorySidePanel = (id) => {
	let historyFilter = {and: true};
	historyFilter['vital-record-history'] = {id: id};
	rest.search('vital-record-history', historyFilter).then(histories => {
		OPEN_SIDE_PANEL.publish(
			{body: <HistoryGrid list={histories}/>, title: t`History`}
		)
	})
}

export const loadFormData = async (id, noActions) => {
	let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	return await rest.read('vital-record', id).then(async response => {
		let form = response;
		if (response.faceId != null) {
			const imageUrl = getServiceUri() + 'face/image/' + response.faceId + "/" + token;
			form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: (imageUrl != null) ? imageUrl : '/public/avatar.png', isEmpty: false };
		} else
			form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true };
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = { key: response.birthCountry, value: countryMetadata[response.birthCountry] }
		}
		if (response.otherCitizenshipCsv == null || response.otherCitizenshipCsv === "")
			form.otherCitizenshipCsv = []
		else {
			let values = [];
			let components = response.otherCitizenshipCsv.split(",");
			components.forEach(element => {
				values.push(parseInt(element))
			});
			form.otherCitizenshipCsv = values;
		}
		if (response.birthTime !== null) {
			response.birthTime = new Date().setHours(response.birthTime[0], response.birthTime[1]);
		}
		if (response.typeOfAcquisition != null)
        	form.typeOfAcquisition = TypeOfAcquisition[response.typeOfAcquisition]
		if (response.motherId == null) {
			if (response.reportedMotherName != null)
					form.motherInfo = "motherWithoutBirthRecord"
			else if (response.unknownMother != null && response.unknownMother)
				form.motherInfo = "unknownMother"
			else
				form.motherInfo = "motherWithoutBirthRecord"
		} else
			form.motherInfo = "motherWithBirthRecord"
	
		if (response.fatherId == null) {
			if (response.reportedFatherName != null)
				form.fatherInfo = "fatherWithoutBirthRecord"
			else if (response.unknownFather != null && response.unknownFather)
				form.fatherInfo = "unknownFather"
			else
				form.fatherInfo = "fatherWithoutBirthRecord"
		} else
			form.fatherInfo = "fatherWithBirthRecord"
		let tagFilter = { and: true };
		tagFilter['vital-record-tag'] = { vitalRecordId: id };
		if (!noActions) {
			await rest.read('custom/get-birth-certificate-data', id).then(async data => {
				setContextualOptions({
				"civil-records": {
					submenu: {
						"actions": {
							options: {
								"amendment": {
									label: "Amend", do: (gui) => displayNewAmendmentApplicationForm(gui, form)
								},
								...(!data.dead) && (response.primaryCitizenship === "191" || response.primaryCitizenship === null) && {"adoption": {
									label: "Apply for Adoption", do: (gui) => displayNewAdoptionApplicationForm(gui, form)
								}},
								...(response.primaryCitizenship === "191" || response.primaryCitizenship === null ||
									(response.birthPlace?.startsWith("191")) ) && {"birth-certificate": { 
									label: "Print Birth Certificate", do: printBirthCertificate(data)
								}},
								...(!data.dead) && (response.primaryCitizenship === "191" || response.primaryCitizenship === null) && {"id-card" : {
									label: "Apply for ID Card", do: (gui) => displayIdCardApplicationManager(gui, form)
								}},
								...(!data.dead) && {"death" : {
									label: "Apply for Death", do: (gui) => displayDeathApplicationManager(gui, form)
								}}
							},
							label: "Actions"
						}
					}
				}
			})});
		}
		
		let prevFatherFilter = {and: true};
		prevFatherFilter['adoptive-fathers'] = {current: true, childId: id}
		return rest.search('adoptive-fathers', prevFatherFilter).then(fathers => {
			if (fathers.length) {
				form.adoptiveFatherId = fathers[0].vitalRecordId;
				form.fatherAdoptionDate = fathers[0].fatherAdoptionDate;
			}
			let prevMotherFilter = {and: true};
			prevMotherFilter['adoptive-mothers'] = {current: true, childId: id}
			return rest.search('adoptive-mothers', prevMotherFilter).then(mothers => {
				if (mothers.length) {
					form.adoptiveMotherId = mothers[0].vitalRecordId;
					form.motherAdoptionDate = mothers[0].motherAdoptionDate;
				}
				return rest.search('vital-record-tag', tagFilter).then(tags => {
					form['tags'] = tags;
					return form;
				})
			})
		})
	})
}
