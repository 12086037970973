import React, { useState } from "react";
import { useField } from "formik";
import { FormLabel } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay-ts';
import { rest, t } from "../../../auto/js/services";
import { ImageViewer, buildEmptyObject, createFormComponent } from "../../../auto/js/widgets";
import { formatDate } from "../../../auto/js/utils";
import { geoDataMetadataLoader } from "../../../auto/js/metadata/GeoDataMetadataLoader";
import { Button } from "@material-ui/core";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { VITAL_RECORD_ORDER_BY_FIRSTNAME } from "../../../auto/js/metadata/VitalRecordOrderBy";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { birthRecordColumns, parentsSearchFormfields, transformFormData } from "../forms/CivilRecordFormCommon";
import { DraggableFilterDialog } from "./DraggableFilterComponentUpgrade";
import { formState } from "../../../auto/js/forms/FormState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointLeft } from "@fortawesome/free-regular-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: '50%', width: '50%' }} />
    </Draggable>
  );
}
function PaperListComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-list"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: '50%', width: '50%' }} />
    </Draggable>
  );
}

export const AdvancedSearchPersonComponent = ({...props}) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [searchResult, setSearchResult] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [open, setOpen] = useState(false);
    const [listOpen, setListOpen] = useState(false);
    const [formData, setFormData] = useState(null);
    const [transformedData, setTransformedData] = useState(null);
    
  const onRequestSearchWithId = (id) => {
        setValue(id);
        setSearchResult(true);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleListClose = () => {
    setListOpen(false);
  };
  const submit = (formData) => {
    const columns = transformFormData(formData);
    setFormData(formData);
    setTransformedData(columns);
    setListOpen(true);
    return columns ;
  }
    
    const openDraggableComponent = () => {
      setOpen(true);
    }

    const getButtons = () => {
      return <button style={{ minWidth: '5rem', position: 'fixed', right: '20px', top: '5px' }} type="submit"> {t`Find`} </button>
    }

    const buildFormData = async () => {
      return formData;
    }
    let SearchForm = createFormComponent((props.parentsSearchFormfields)?props.parentsSearchFormfields:parentsSearchFormfields);
    let CivilRecordsList = createTableComponent(birthRecordColumns);

  const getVitalRecordFromSearch = async (filter) => {
     return await rest.request(getServiceUri() + "vital-record/advanced-search", "POST", filter);
  }

  const countData = async (filter) => {
     return await rest.request(getServiceUri() + "vital-record/count/advanced-search", "POST", filter);
  }

    const filterData = (DefaultRows, token) => {
      const newRows = [];
      for (let i in DefaultRows) {
        let faceUrl = null;
        let row = DefaultRows[i];
        if (row.faceId != null) {
          faceUrl = getServiceUri() + "face/image/" + row.faceId + "/" + token;
        }
        row.image = (faceUrl != null)?faceUrl:"/public/avatar.png";          
        let date = row.birthdate
        if (date !== null)
            row.birthdate = date[2] + '-' + date[1] + '-' + date[0];
        newRows.push(row);
      }
      return newRows;
    }

    const buildData = async (query) => {
      const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
      let filter = query;
      let data;
      filter["vital-record"] = {inactiveList: [false]};
      filter["orderBy"] = VITAL_RECORD_ORDER_BY_FIRSTNAME;
      filter.orderDirection = null;
      filter["filter"] = query.search;
      filter["query"] = transformedData;
      filter.offset = query.page * query.pageSize;
      if (query.search && query.search!='') {
          pojoMetadata["vital-record"].columns.forEach(element => {
              if(element.type=='text'){
                  filter["vital-record"][element.key]= query.search;
              }
          });
      }
      return await getVitalRecordFromSearch(filter).then(response => {
          data = filterData(response, token)
          return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
      });
    }

    const getTableActions = () => {
      let actions = [];
      actions.push(
        {
          icon: () => <FontAwesomeIcon icon={faHandPointLeft}/>,
            tooltip: t`Choose`,
            onClick: (event, rowData) => {
              onRequestSearchWithId(rowData.id);
              setListOpen(false);
              setOpen(false);
            }
        }
      )
      return actions;
    }
  

    return(
        <>
        
        {(!props.readOnly) && 
        <>
        <FormLabel onClick={() => openDraggableComponent()} style={{textDecoration: 'underline', cursor: 'pointer'}} >
        {(props.label)?t(props.label):t(props.name)}
        <FontAwesomeIcon icon={faMagnifyingGlass} onClick={() => openDraggableComponent()} style={{ fontSize: '1.1em', marginLeft: '10px' }}/>
        </FormLabel>
        </>}   
      {(searchResult && value)?
      <Person loadData={ async () => props.loadData(value)} disabledPhoto={props.disabledPhoto} fieldName={props.name}/>
      :null}
      {open && <DraggableFilterDialog open={open} onClose={handleClose} title={"Filter your search"} paperComponent={PaperComponent} 
      description={""} children={
        <SearchForm loadData={
             async () => {
                if (props.buildEmptyObject !== undefined) {
                  return props.buildEmptyObject((props.parentsSearchFormfields)?props.parentsSearchFormfields:parentsSearchFormfields);
                } else {
                  return buildEmptyObject((props.parentsSearchFormfields)?props.parentsSearchFormfields:parentsSearchFormfields);
                }
              }
          } buttons={getButtons} onSubmit={submit} />
      }/>}
      {listOpen && <DraggableFilterDialog open={listOpen} onClose={handleListClose} title={"Civil Record list"} paperComponent={PaperListComponent} description={"You can choose the user you want to export"} children={<CivilRecordsList loadData={async (query) => buildData(query)} columns={birthRecordColumns} actions={getTableActions()}  />} />}
          </>
      )
}

class Person extends React.Component {
    constructor(props) {
      super(props);
      this.formRef = React.createRef()
      this.state = {
        data: undefined,
        loading: true,
        loadingMessage: ''
      }
      this.props.loadData().then((data) => {
        if (data) {
          formState.addState(this.props.fieldName + "-gender",data.gender)
          formState.addState(this.props.fieldName + "-birthdate",data.birthdate)
        }
        this.setState({ data: data, loading: false })
      });
	  }

    componentDidUpdate(prevProps, prevState) {
      this.props.loadData().then((data) => {
        if (data) {
          formState.addState(this.props.fieldName + "-gender",data.gender)
          formState.addState(this.props.fieldName + "-birthdate",data.birthdate)
          if (prevState.data) {
            if (prevState.data.id !== data.id)
              this.setState({ data: data, loading: false })
          }
          else
            this.setState({ data: data, loading: false })
        }
      });
    }

    openPersonTab = (id) => {
        var currentUrl = window.location.href;
        var url;
        if (currentUrl.includes("#")) {
            var parts = currentUrl.split("#");
            url = parts[0] + '#' + 'viewvr:id=' + id;
        } 
        else
            url = window.location.hash + '#' + 'viewvr:id=' + id;
        window.open(url, '_blank');
    }
    
    render() {
        let fullname = '';
        let birthdate;
        let birthPlace = [];
        let address = [];
        if (this.state.data) {
            birthdate = this.state.data.birthdate? formatDate(this.state.data.birthdate):t`Unknown`;
            fullname += this.state.data.firstname ? this.state.data.firstname + ' ' : '';
            fullname += this.state.data.secondname ? this.state.data.secondname + ' ' : '';
            fullname += this.state.data.thirdname ? this.state.data.thirdname + ' ' : '';
            fullname += this.state.data.fourthname ? this.state.data.fourthname + ' ' : '';
            fullname += this.state.data.fifthname ? this.state.data.fifthname + ' ' : '';
            if (this.state.data.birthPlace != null) {
                let birthPlaceComponents = this.state.data.birthPlace.split(".");
                let place = this.state.data.birthPlace;
                birthPlace.push(
                    <p style={{marginBottom: "0px"}}>{(geoDataMetadataLoader.getArea(place))?t(geoDataMetadataLoader.getArea(place).name):t("INVALID DATA") + " (" + t(geoDataMetadataLoader.getAreaLevelName(place)) + ")"} </p>
                )
                for (let i = 0; i < birthPlaceComponents.length - 1 ; i ++) {
                    let parentAreaId = place.substring(0, place.lastIndexOf("."));
                    place = parentAreaId;
                    birthPlace.push(
                        <p style={{marginBottom: "0px"}}>{(geoDataMetadataLoader.getArea(parentAreaId))?t(geoDataMetadataLoader.getArea(parentAreaId).name):t("INVALID DATA") + " (" + t(geoDataMetadataLoader.getAreaLevelName(parentAreaId)) + ")"} </p>
                    )                
                }
            }
            else
                birthPlace.push(<p>{t`Unknown Birth Place`}</p>)
            if (this.state.data.address) {
                let addressComponents = this.state.data.address.split(".");
                let place = this.state.data.address;
                address.push(
                    <p style={{marginBottom: "0px"}}>{(geoDataMetadataLoader.getArea(place))?t(geoDataMetadataLoader.getArea(place).name):t("INVALID DATA") + " (" + t(geoDataMetadataLoader.getAreaLevelName(place)) + ")"} </p>
                )
                for (let i = 0; i < addressComponents.length - 1 ; i ++) {
                    let parentAreaId = place.substring(0, place.lastIndexOf("."));
                    place = parentAreaId;
                    address.push(
                        <p style={{marginBottom: "0px"}}>{(geoDataMetadataLoader.getArea(parentAreaId))?t(geoDataMetadataLoader.getArea(parentAreaId).name):t("INVALID DATA") + " (" + t(geoDataMetadataLoader.getAreaLevelName(parentAreaId)) + ")"} </p>
                    )                
                }
            }
            else
                address.push(<p>{t`Unknown`}</p>);
        }
        return (
            <>
            <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text={this.state.loadingMessage}
            >
            {(this.state.data)?
            <div class="row rv5info">
            {(!this.props.disabledPhoto) &&
            <div class="col-md-2">
                <ImageViewer image={this.state.data.image} width={125} height={125}/>  
            </div>}
             <div class="col-md-4"><div class="results" style={{paddingLeft:"0px"}}><h4>{t`Personal Details`}</h4>
                 <p>{fullname}</p>
                 <p>{t`ID`} #: {this.state.data.id}</p>
                 <p style={{fontWeight: "bold"}}>{t`Mother`}:</p>
                 {(this.state.data.motherId)?
                 <p>{t`ID`} # {this.state.data.motherId}</p>:
                 <p>{t`Reported Name`}: {this.state.data.reportedMotherName}</p>
                 }
                 <p style={{fontWeight: "bold"}}>{t`Father`}:</p>
                 {(this.state.data.fatherId)?
                 <p>{t`ID`} #: {this.state.data.fatherId}</p>:
                 <p>{t`Reported Name`}: {this.state.data.reportedFadherName}</p>
                }
             </div>
             </div>
    
             <div class="col-md-3">
                <div class="results">
                  <h4>{t`Birth Details`}</h4>
                 <p>{birthdate}<br />
                {birthPlace}</p>
                <h4>{t`Current Residency`}</h4>
                {address}
                </div>
            </div>
            <div class="col-md-2">
                <div class="results">
                  <h4>{t`Other Details`}</h4>
                 <p>{this.state.data.maritalStatus}</p>
                 <p>{this.state.data.gender}</p>
                </div>
            </div>
            <div class="col-md-1">
                <Button className="link-button" onClick={() => this.openPersonTab(this.state.data.id)}>{t`View Person`}</Button>
            </div>
            </div>:
            <div className="ValidationError">{t`Person not found`}</div>
            }
            </LoadingOverlay>
            </>
        )    
    }
}