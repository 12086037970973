import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../../../auto/js/widgets/FormComponent';
import { OPEN_VIEW_EVENT, OPEN_SIDE_PANEL } from '../../../../auto/js/events/Gui';
import { birthRegistrationFields } from "../../../../main/js/forms/birthRegistration/BirthRegistrationFormCommon";
import { readDigitalizationAttachment, openNewImageTab } from "./DigitalizationSearchForm";
import { Button } from "@mui/material";
import { NotesGrid } from "../../../../auto/js/widgets/NotesGrid";
import { t, rest } from "../../../../auto/js/services";
import { loadBirthRegistrationFormData } from "../birthRegistration/BirthRegistrationApi";

let DigitalizationApplicationForm = createFormComponent(birthRegistrationFields);
	
class FormComponent extends React.Component {
	constructor(props) {
		super(props);
    }

	render() {
		return (
		<>
			<img alt="approved.png" src="/public/approved.png" style={{maxWidth: '130px'}}/>
			<Button className="link-button" onClick={() => openSidePanel(this.props.id)}>{t`Notes`}</Button>
			<DigitalizationApplicationForm key={this.props.key} loadData={async () => loadBirthRegistrationFormData(this.props.id)} readOnly onSubmit={() => null} id={this.props.id} buttons={() => null} />
		</>
		)
	}

}

const openSidePanel = (id) => {
	let noteFilter = {and: true};
	noteFilter['civil-status-mtlb-note'] = {civilStatusMtlbId: id};
	rest.search('civil-status-mtlb-note', noteFilter).then(notes => {
		OPEN_SIDE_PANEL.publish(
			{body: <NotesGrid list={notes}/>, title: t`Notes`}
		)
	})
}

	

export const displayReadApprovedDigitalizationApplicationForm = (gui, id) => {
	const readApprovedDigitalizationApplicationForm = (onFinish) => () => {
		let uuid = uuidv4();
		return{
			uuid, view: () =>  {
				return (
					<>
						<FormComponent uuid={uuid} id={id} />
					</>
				)}
		};
	}
	readDigitalizationAttachment(id).then(async response => {
		openNewImageTab(response[0].id);
		gui.goTo(readApprovedDigitalizationApplicationForm())
	})
}
