import React from "react";
import {v4 as uuidv4} from 'uuid';
import PrintIcon from '@material-ui/icons/Print';

import { pojoMetadata } from "../../../auto/js/metadata";
import { JOB_MTLB_ORDER_BY_TIMESTAMP } from "../../../auto/js/metadata/JobMtlbOrderBy";
import { MTLB_TYPE_GENERAL_STATISTICS } from "../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../auto/js/services";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import GeneralStatistics from "../GeneralStatistics";
import { PRINT_EVENT } from "../../../auto/js/events/Gui";
import { MTLB_STATUS_ARCHIVED } from "../../../auto/js/metadata/MtlbStatus";
import { formatDateTime } from "../../../auto/js/utils";

const reportingFields = [
    {title: "Application Time", field: "timestamp"},
    {title: "Status", field: "mtlbStatus"}
]

export const displayGeneralStatisticsList = (gui) => {
    
    let GeneralStatisticsList = createTableComponent(reportingFields);
    
    const readList = (onFinish) => () => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <GeneralStatisticsList key={uuid} gui={gui} loadData={async (query) => buildData(query)} actions={getTableActions()} editables={getEditables()} />
        };
    }
    gui.goTo(readList())
}



const buildData = async (query) => {
    let filter = query;
    let data;
    filter["job-mtlb"] = {mtlbType: MTLB_TYPE_GENERAL_STATISTICS};
    filter["orderBy"] = JOB_MTLB_ORDER_BY_TIMESTAMP;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["job-mtlb"].columns.forEach(element => {
            if(element.type=='text'){
                filter["job-mtlb"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getData(filter).then(response => {
        data = filterData(response);
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

const filterData = (defaultRows) => {
    const newRows = [];
    for (let i in defaultRows) {
        let row = defaultRows[i];
        let timestamp = row.timestamp;
        if (timestamp != null)
            row.timestamp = formatDateTime(timestamp);
        newRows.push(row);
    }
    return newRows;
}

const getData = async (filter) => {
    return await rest.search('job-mtlb', filter)
}

const countData = async (filter) => {
        return await rest.count('job-mtlb', filter);
}

const getTableActions = () => {
    let actions = [];
    actions.push(
        {
            icon: () => <PrintIcon  />,
            tooltip: t`Print`,
            onClick: (event, rowData) => {
                if (rowData.mtlbStatus === MTLB_STATUS_ARCHIVED)
                    printJob(rowData.id);
                else
                    alert('Reporting processing not finished yet !')
            }
        }
    )
    return actions;   
}

const getEditables = () => {
    let editables = {}
    editables.onRowDelete = oldData =>
        new Promise((resolve, reject) => {
            try {
                return rest.purge('job-mtlb', oldData.id).then(() => resolve());
            } catch (err) {
                alert(err);
            }   
        })
    return editables;
}

const printJob = (id) => {
    rest.read('job-mtlb', id).then(response => {
        const printable = {
            content:<GeneralStatistics data={JSON.parse(response.content)} timestamp={formatDateTime(response.timestamp)} />,
            copyParentStyle:true
        }   
        PRINT_EVENT.publish(printable)
    })
}