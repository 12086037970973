import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { CIVIL_STATUS_MTLB_ORDER_BY_FIRSTNAME } from "../../../../auto/js/metadata/CivilStatusMtlbOrderBy";
import { MTLB_TYPE_DEATH_REGISTRATION } from "../../../../auto/js/metadata/MtlbType";
import { rest } from "../../../../auto/js/services";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";


export const deathRegistrationListColumns = [
    { title: "id", field: "vitalRecordId" },
    { title: "fourthname", field: "fourthname" },
    { title: "secondname", field: "secondname" },
    { title: "thirdname", field: "thirdname" },
    { title: "firstname", field: "firstname" },
    { title: "dateOfDeath", field: "dateOfDeath" },

];

export const getData = async (filter) => {
    let searchEndpoint = (filter.query) ? "civil-status-mtlb/advanced-search" : "civil-status-mtlb/search-by-mtlb-type-and-status/";
    return await rest.request(getServiceUri() + searchEndpoint, "POST", filter);
}

export const countData = async (filter) => {
    let countEndpoint = (filter.query) ? "civil-status-mtlb/count/advanced-search" : "civil-status-mtlb/count/search-by-mtlb-type-and-status/";
    return await rest.request(getServiceUri() + countEndpoint, "POST", filter);
}

export const getOrderBy = () => {
    return CIVIL_STATUS_MTLB_ORDER_BY_FIRSTNAME
}

export const getMtlbTypeList = () => {
    return [MTLB_TYPE_DEATH_REGISTRATION]
}

export const filterData = (DefaultRows, token) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        const imageUrl = getServiceUri() + 'civil-status-mtlb/face/' + row.id + "/" + token;
        row.image = (imageUrl != null) ? imageUrl : "/public/avatar.png";
        let date = row.dateOfDeath
        if (date !== null)
            row.dateOfDeath = date[2] + '-' + date[1] + '-' + date[0];
        newRows.push(row);
    }
    return newRows;
}

export const getErrorList = () => {
    let list = [];
    list.push("E19");
    list.push("E22");
    return list;
}

export const advancedSearchfields = [
    { name: "caseSensitiveSearch", label: "Case Sensitive Search", type: "checkbox", x: 1, y: 1, layout: "col-md-3" },
    { name: "firstname", type: "text", x: 1, y: 6, layout: "col-md-6" },
    {
        name: "firstnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 6, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    { name: "secondname", type: "text", x: 1, y: 4, layout: "col-md-6" },
    {
        name: "secondnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 4, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    { name: "thirdname", type: "text", x: 1, y: 5, layout: "col-md-6" },
    {
        name: "thirdnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 5, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    { name: "fourthname", type: "text", x: 1, y: 3, layout: "col-md-6" },
    {
        name: "fourthnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 3, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    {
        name: "genderBox", label: "Gender", components: [
            { name: "male", label: "Male", type: "checkbox", x: 1, y: 1, layout: "col-md-3" },
            { name: "female", label: "Female", type: "checkbox", x: 2, y: 1, layout: "col-md-3" },
        ], type: "box", x: 3, y: 7, layout: "col-md-11 ms-2 mt-2"
    },//mx-auto
    {
        name: "deathDateBox", label: "Death Date", components: [
            { name: "deathdateRange", label: "deathdateRange", type: "checkbox", x: 1, y: 1, layout: "col-md-12" },
            {
                name: "exactdeathdate", type: "date", x: 1, y: 2, layout: "col-md-6", display: (data) => {
                    return !data.deathdateRange;
                }
            },

            {
                name: "deathdateStart", type: "date", x: 1, y: 3, layout: "col-md-6", display: (data) => {
                    return data.rangedeathdate;
                }
            },
            {
                name: "deathdateEnd", type: "date", x: 2, y: 3, layout: "col-md-6", display: (data) => {
                    return data.rangedeathdate;
                }
            },
        ], type: "box", x: 1, y: 8, layout: "col-md-11 ms-2 mt-2 pt-1 pb-2"
    },

    {
        name: "deathBox", label: "Death Place", components: [
            {
                name: "deathPlace", type: "custom", x: 1, y: 2, layout: "col-md-6",
                component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} />,
                display: (data) => { return !data.anyBornOutsideCountry }
            },
        ], type: "box", x: 2, y: 10, layout: "col-md-11 ms-2 mt-2 pt-1 pb-2"
    }
];

export const transformAdvancedSearchData = (formData) => {
    const columns = [];

    const addColumn = (name, type, value, condition) => {
        columns.push({
            name,
            type,
            [type === 'date' ? 'dateValue' : type === 'boolean' ? 'booleanValue' : (type === 'text' ? 'textValue' : 'longValue')]: value,
            searchType: condition ? condition : null,
        });
    };
    (formData.firstname !== "") && addColumn('firstname', 'text', formData.firstname, formData.firstnameCondition);

    (formData.secondname !== "") && addColumn('secondname', 'text', formData.secondname, formData.secondnameCondition);

    (formData.thirdname !== "") && addColumn('thirdname', 'text', formData.thirdname, formData.thirdnameCondition);

    (formData.fourthname !== "") && addColumn('fourthname', 'text', formData.fourthname, formData.fourthnameCondition);
    addColumn('mtlbType', 'number', 12, null);
    addColumn('draft', 'boolean', false, null);
    (formData.deathPlace) && addColumn('deathPlace', 'text', formData.deathPlace, formData.deathPlace ? "startsWith" : null);
    const deathdateRange = formData.deathdateStart && formData.deathdateEnd
        ? {
            min: formData.deathdateStart ? formData.deathdateStart : null,
            max: formData.deathdateEnd ? formData.deathdateEnd : null,
        }
        : null;

    (deathdateRange != null || formData.exactdeathdate != null) && addColumn('dateOfDeath', 'date', formData.exactdeathdate, null);
    if ((deathdateRange != null || formData.exactdeathdate != null))
        columns[columns.length - 1].dateRange = deathdateRange;

    const gender = [];
    formData.male ? gender.push("1") : null;
    formData.female ? gender.push("2") : null;
    if (gender.length)
        columns.push({
            name: 'gender',
            type: 'enum',
            enumValues: gender,
        });

    return { columns, caseSensitiveSearch: formData.caseSensitiveSearch };
};