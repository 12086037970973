import React from "react";

import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { MTLB_TYPE_RV4_BIRTH_FAMILY_TREE_RECORD, MTLB_TYPE_RV4_BIRTH_VITAL_RECORD } from "../../../../auto/js/metadata/MtlbType";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import * as Yup from 'yup';
import { formState } from "../../../../auto/js/forms/FormState";
import { AddressComponent } from "../../../../auto/js/widgets/AddressComponent";
import { PersonComponent } from "../../../../auto/js/widgets/PersonComponent";
import { loadPersonData } from "../../utils";

const gender = {1:"MALE", 2:"FEMALE"};
const maritalStatus = {1:"SINGLE", 2:"MARRIED", 3:"DIVORCED", 4:"WIDOWED"};

export const familyTreeRegistrationFields = [
	{name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
	{name: "childDetails", type: "custom", x:1, y:2, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "image", type: "image", x: 1, y: 3, layout: "col-md-12" },
	{name: "vitalRecordId", type:"number", x: 1, y: 4, layout: "col-md-12"},
	{name: "firstname", type: "text", x:1, y:6, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(2, t`First name must be at least two characters long`).max(14, t`First name must be less than fifteen characters long`).required(t`First name is required`).matches(/[\p{Letter}\p{Mark}\s]+$/u, t`Only alphabets are allowed for this field `)},
	{name: "middlename", type: "text", x:2, y:5, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(2, t`Middle name must be at least four characters long`).max(28, t`Middle name must be less than twenty eight characters long`)},
	{name: "fourthname", type: "text", x:1, y: 5, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(2, t`Family name must be at least four characters long`).max(14, t`Family name must be less than fifteen characters long`).required(t`Family name is required`).matches(/[\p{Letter}\p{Mark}\s]+$/u, t`Only alphabets are allowed for this field `)},
	{name: "fifthname", type: "text", x:2, y: 6, layout:"col-md-6",
		"validation": Yup.string().nullable().default(undefined).min(2, t`Melanesian name must be at least four characters long`).max(14, t`Marriage name must be less than fifteen characters long`).matches(/[\p{Letter}\p{Mark}\s]+$/u, t`Only alphabets are allowed for this field `)},
	{name: "gender", type: "select", x:1, y: 7, layout:"col-md-6", metadata: () => gender,
		"validation": Yup.string().nullable().default(undefined).required(t`Gender is required`)},
	{name: "birthdate", type: "date", x:1, y: 8, layout:"col-md-6",
		"validation": Yup.date().nullable().default(undefined).required('A date of birth is required')
	},
	{name: "birthTime", type: "time", x:2, y:8, layout:"col-md-6"},
	{name: "birthDayUnknown", type: "checkbox", x:1, y:9, layout:"col-md-4"},
    {name: "birthMonthUnknown", type: "checkbox", x:2, y:9, layout:"col-md-4"},
    {name: "birthYearUnknown", type: "checkbox", x:3, y:9, layout:"col-md-4"},
	{name: "disability", type: "checkbox", x:1, y:10, layout:"col-md-6"},
//	{name: "birthPlace", type: "custom", x:1, y:12, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
//	{name: "birthPlace", type: "custom", x:1, y:13, layout:"col-md-12", component: (name, disabled) => <AddressComponent name={name} disabled={disabled}/>},
	{name: "motherDetails", type: "custom", x:1, y:14, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "unknownMother", type: "checkbox", x:1, y:15, layout:"col-md-12"},
	{name: "motherId", type: "custom", x:1, y:16, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
	{name: "reportedMotherName", type: "text", x:2, y:17, layout:"col-md-12"},
	{name: "fatherDetails", type: "custom", x:1, y:18, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "unknownFather", type: "checkbox", x:1, y:19, layout:"col-md-12"},
	{name: "fatherId", type: "custom", x:1, y:20, layout:"col-md-12", component: (name, disabled) => <PersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "reportedFatherName", type: "text", x:1, y:21, layout:"col-md-12"},
	{name: "extras", type: "custom", x:1, y:28, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "notes", type: "text", x:1, y:29, layout:"col-md-12"},
	
];

const checkAttachment = () => {
	var attachments = formState.getAttachmentList();
	return attachments.length;
}

export const saveFamilyTreeRegistrationForm = async (formData) => {
	let dto = pojoMetadata['rvfour-birth-mtlb'].form2dto(formData);
	dto.mtlbType = MTLB_TYPE_RV4_BIRTH_FAMILY_TREE_RECORD;
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	if (formData.middlename != null) {
		let names = formData.middlename.split(" ");
		dto.secondname = names[0]
		if (names.length > 1)
			dto.thirdname = names[1];
	}
	if (formData.birthCountry)
		dto.birthCountry = formData.birthCountry.key;
	try {
		return rest.request(getServiceUri() + 'apply/create-rv-four-birth-mtlb', 'POST', dto)
	} catch (err) {
		alert(err);
	}
}

export const loadFamilyTreeRegistrationFormData = async (id) => {
	return await rest.read('rvfour-birth-mtlb', id).then(response => {
		let form = response;
		let face = null;
		if (response.face != null) {
			let mimeType = response['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", response.face)
		}
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = {key: response.birthCountry, value: countryMetadata[response.birthCountry]}
		}
		if (response.secondename != null) {
			form['middlename'] = response.secondename;
			if (response.thirdname != null)
				form['middlename'] = form['middlename'] + " " + response.thirdname;
		}
		if (response.birthtime !== null)
			response.birthtime = new Date().setHours(response.birthtime[0], response.birthtime[1]);
		if (response.birthPlace == null)
			form.birthPlace = "191";
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: true};

		let tagFilter = {and: true};
		tagFilter['rvfour-birth-mtlb-tag'] = {rvfourBirthMtlbId: id};
		
		return rest.search('rvfour-birth-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
			
			return form;
		})
	})
}

export const updatePendingFamilyTreeRegistrationForm = async (formData) => {
	let dto = pojoMetadata['rvfour-birth-mtlb'].form2dto(formData);
	form2dto(formData, dto);
	try {
		return rest.request(getServiceUri() + 'rvfour-birth-mtlb/pending/update', 'POST', dto);
	} catch (err) {
		alert(err);
	}
}

export const updateFamilyTreeRegistrationForm = async (formData) => {
	let dto = pojoMetadata['rvfour-birth-mtlb'].form2dto(formData);
	form2dto(formData, dto);
	try {
		return rest.update('rvfour-birth-mtlb', dto);
	} catch (err) {
		alert(err);
	}
}

export const deleteFamilyTreeRegistrationForm = async (id) => {
	try {
		return rest.delete('rvfour-birth-mtlb', id);
	} catch (err) {
		alert(err);
	}
}

export const updateRejectedFamilyTreeRegistrationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let dto = pojoMetadata['rvfour-birth-mtlb'].form2dto(formData);
	form2dto(formData, dto);
	try {
		return rest.request(getServiceUri() + 'rvfour-birth-mtlb/rejected/update', 'POST', dto);
	} catch (err) {
		alert(err);
	}
}

export const rejectFamilyTreeRegistrationForm = async (id) => {
	try {
		return rest.request(getServiceUri() + 'rvfour-birth-mtlb/pending/reject', 'POST', id);
	} catch (err) {
		alert(err);
	}
}

export const rejectReadyFamilyTreeRegistrationForm = async (id) => {
	try {
		return rest.request(getServiceUri() + 'rvfour-birth-mtlb/ready/reject', 'POST', id);
	} catch (err) {
		alert(err);
	}
}

export const approveReadyFamilyTreeRegistrationForm = async (id) => {
	try {
		return rest.request(getServiceUri() + 'rvfour-birth-mtlb/ready/approve', 'POST', id);
	} catch (err) {
		alert(err);
	}
}

export const buildFamilyTreeRegistrationFormEmptyObject  = () => {
	const empty = buildEmptyObject(familyTreeRegistrationFields);
	empty['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url:'/public/avatar.png', isEmpty: true};
	empty['birthCountry'] = {key: 179, value: 'Trinidad and Tobago'}
	return empty;
}

export const loadCivilStatusMtlbAttachment = async (id) => {
	let filter = {and: true};
	filter['rvfour-birth-mtlb-attachment'] = {};
	filter['rvfour-birth-mtlb-attachment']['civilStatusMtlbId'] = id;
	return rest.search('rvfour-birth-mtlb-attachment', filter)
}

const form2dto = (formData, dto) => {
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	if (formData.birthCountry)
		dto.birthCountry = formData.birthCountry.key;
	if (formData.birthtime != null && typeof(formData.birthtime) != 'string') {
		const date = new Date(formData.birthTime)
		let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.birthtime = birthHour + ":" + birthMinute;
	}
	if (formData.middlename != null) {
		let names = formData.middlename.split(" ");
		dto.secondname = names[0]
		if (names.length > 1)
			dto.thirdname = names[1];
	}
}

export const dto2form = (response) => {
	let form = response;
	let face = null;
	if (response.face != null) {
		let mimeType = response['faceMimeType'];
		face = "data:".concat(mimeType, ";base64,", response.face)			
	}
	if (response.secondename != null) {
		form['middlename'] = response.secondename;
		if (response.thirdname != null)
			form['middlename'] = form['middlename'] + " " + response.thirdname;
	}
	if (response.birthtime !== null)
		response.birthtime = new Date().setHours(response.birthtime[0], response.birthtime[1]);
	if (response.birthPlace == null)
		form.birthPlace = "191";
	form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: (face == null)};
	
	return form;
}

const Section = ({name}) => {
	return (
		<>
		<div class="col-md-12 sectiontitle">
			<h2 style={{ display: "inline" }}>{t(name)}</h2>
		</div>
		</>
	)
}