import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { t } from "../../../auto/js/services";
import { StepperBrowser } from "../../../auto/js/browsers/StepperBrowser";
import { BirthApplicationTask } from "../tasks/BirthApplicationTask";
import { ServerApprovalTask } from "../tasks/ServerApprovalTask";
import { ApproveApplicationTask } from "../tasks/ApproveApplicationTask";
import { RecordRegistrationTask } from "../tasks/RecordRegistrationTask";
import { PrintCertificateTask } from "../tasks/PrintCertificateTask";

export const displayTemporaryCertificateWorkflow = (gui) => {
  let steps = [
    t`Birth Application`,
    t`Server Approval`,
    t`Approve Application`,
    t`Record Registration`,
    t`Print Cerificate`,
  ];

  class TemporaryCertificateStepperBrowser extends StepperBrowser {
    constructor(props) {
      super(props)
      this.tasks.push(new BirthApplicationTask(0, steps[0], this));
      this.tasks.push(new ServerApprovalTask(1, steps[1], this));
      this.tasks.push(new ApproveApplicationTask(2, steps[2], this));
      this.tasks.push(new RecordRegistrationTask(3, steps[3], this));
      this.tasks.push(new PrintCertificateTask(4, steps[4], this));
      this.start();
    }

  }

  const createTemporaryCertificateFlowBrowser = (gui) => {
    let uuid = uuidv4();
    return {
        uuid,
        view: () => <TemporaryCertificateStepperBrowser gui={gui} steps={steps} welcome={() => <></>}/>
    }
  }
  gui.goTo(createTemporaryCertificateFlowBrowser, null, null);
}