import * as React from 'react';
import {v4 as uuidv4} from 'uuid';
import LoadingOverlay from 'react-loading-overlay-ts';

import { OPEN_VIEW_EVENT, PRINT_EVENT } from '../../auto/js/events/Gui';
import { getServiceUri } from '../../auto/js/metadata';
import { rest, t } from '../../auto/js/services';
import { Welcome } from './pages/Welcome';
import { showNotification } from '../../auto/js/utils';

const groupingNumber = (number) => {
    if (number != null)
        return number.toLocaleString('en', {useGrouping:true})
    return null;
}

const getProvinceStatisticsItems = (list) => {
    let items = [];
    list.forEach(element => {
        items.push(<ProvinceGeneralStatistics data={element} />)
    });
    return items;
}

const GeneralStatisticsWrapper = () => {
    const [ready, setReady] = React.useState(false);

    if (!ready)
        rest.request(getServiceUri() + 'reports/general-statistics', 'GET').then(response => {
            setReady(true)
            const printable = {
				content:<GeneralStatistics data={response} />,
				copyParentStyle:true
	        }   
            PRINT_EVENT.publish(printable)
        })

    return (
        <LoadingOverlay
            active={!ready}
            spinner
            text={'Loading General Statistics ...'}
        ><Welcome />
        </LoadingOverlay>
    )
}
export default class GeneralStatistics extends React.Component {
	constructor(props) {
		super(props);
        this.data = props.data;
	}

	render() {

        var currentdate = new Date();
		var datetime = currentdate.getDate() + "/"
			+ (currentdate.getMonth() + 1) + "/"
			+ currentdate.getFullYear()
		let provinceStatisticsItems = getProvinceStatisticsItems(this.data.provinceGeneralStatisticsList);
		return (
            <div style={{marginTop:"40px", marginLeft:"20px"}}>
            <dl>
			<dt>As of {this.props.timestamp}:</dt>
            <dd>-	A total of {groupingNumber(this.data.issuedIdCardsNumber)} ID cards are recorded to have been issued overtime.</dd>
            <dd>-	Of these, {groupingNumber(this.data.notExpiredIdCardsNumber)} have not expired yet</dd>
            <dd>-	Of these, {groupingNumber(this.data.notCancelledIdCardsNumber)} have not been canceled</dd>
            <dd>-	Of these, {groupingNumber(this.data.activatedIdCardsNumber)} have been activated</dd>
            <dd>-	Of these, {groupingNumber(this.data.distinctIndividualsIdCardsNumber)} belong to distinct individuals – in other words, some of the cards above most probably have been reissued without canceling the previous one, while the previous card is still active and not expired.</dd>
            </dl>
            <p>Those {groupingNumber(this.data.distinctIndividualsIdCardsNumber)} will be the reference for the statistics below, only considering the latest card issued for anyone who has more than one.</p>
            <p>In parallel, there are {groupingNumber(this.data.authorizedBirthRecordsNumber)} “authorized birth records”. Please note that in CRIM terminology, the line between “authorized birth records”, “checked birth records” and “persons records” is not always clear cut. “checked birth records” for example, are sometimes issued legal documents,
               and “persons records” can be changed into birth records. It also may create minor discrepancies depending on how the database is queried. For example, some records previously marked as “authorized” have been reverted to “checked”.
            </p>
            <dl>
             <dt>Since records being marked as “authorized birth records” are the most authoritative source of legal identity in RV4, they will be the basis for the statistics below.</dt>
             <dd>-	Of these, {groupingNumber(this.data.livingAuthorizedBirthRecordsNumber)} have not been marked one way or another as deceased.</dd>
             <dt>Overall, in Vanuatu:</dt>
             <dd>-	Below 18: {groupingNumber(this.data.livingMalesAgedUnder18Number)} males, {groupingNumber(this.data.livingFemalesAgedUnder18Number)} females</dd>
             <dd>-	18 and above, below 30: {groupingNumber(this.data.livingMalesAged18To29Number)} males, {groupingNumber(this.data.livingFemalesAged18To29Number)} females</dd>
             <dd>-	30 or above, below 50: {groupingNumber(this.data.livingMalesAged30To49Number)} males, {groupingNumber(this.data.livingFemalesAged30To49Number)} females</dd>
             <dd>-	50 or above: {groupingNumber(this.data.livingMalesAgedOver49Number)} males, {groupingNumber(this.data.livingFemalesAgedOver49Number)} females</dd>
             <dt>Crossing the data between ID cards and living people, we get:</dt>
             <dd>-	{groupingNumber(this.data.livingPeopleWithCardsNumber)} people recorded as living have at least one non-expired, non-canceled, activated, ID card. I’ll call those “living people with cards”. The others, recorded as living, without a card, will be called “living people without cards”.</dd>
             <dt>Age or Sex information is missing in a small number of cases. These cases are excluded those from calculations. For “living people with cards”:</dt>
             <dd>-	Below 18: {groupingNumber(this.data.livingMalesAgedUnder18WithCardsNumber)} males, {groupingNumber(this.data.livingFemalesAgedUnder18WithCardsNumber)} females</dd>
             <dd>-	18 or above, below 30: {groupingNumber(this.data.livingMalesAged18To29WithCardsNumber)} males, {groupingNumber(this.data.livingFemalesAged18To29WithCardsNumber)} females</dd>
             <dd>-	30 or above, below 50: {groupingNumber(this.data.livingMalesAged30To49WithCardsNumber)} males, {groupingNumber(this.data.livingFemalesAged30To49WithCardsNumber)} females</dd>
             <dd>-	Above 50: {groupingNumber(this.data.livingMalesAgedOver49WithCardsNumber)} males, {groupingNumber(this.data.livingFemalesAgedOver49WithCardsNumber)} females</dd>
            </dl>
            {provinceStatisticsItems}
            </div>
		)
	}
}

class ProvinceGeneralStatistics extends React.Component {
	constructor(props) {
		super(props);
        this.data = props.data;
	}

	render() {
		
		return (
            <>
            <p>{this.data.provinceName}</p>

            <p>In this province there are {groupingNumber(this.data.authorizedBirthRecordsNumber)} “authorized birth records”</p>
            <dl>
             <dt>Since records being marked as “authorized birth records” are the most authoritative source of legal identity in RV4, they will be the basis for the statistics below.</dt>
             <dd>-	Of these, {groupingNumber(this.data.livingAuthorizedBirthRecordsNumber)} have not been marked one way or another as deceased.</dd>
             <dt>Overall, in {this.data.provinceName}:</dt>
             <dd>-	Below 18: {groupingNumber(this.data.livingMalesAgedUnder18Number)} males, {groupingNumber(this.data.livingFemalesAgedUnder18Number)} females</dd>
             <dd>-	18 and above, below 30: {groupingNumber(this.data.livingMalesAged18To29Number)} males, {groupingNumber(this.data.livingFemalesAged18To29Number)} females</dd>
             <dd>-	30 or above, below 50: {groupingNumber(this.data.livingMalesAged30To49Number)} males, {groupingNumber(this.data.livingFemalesAged30To49Number)} females</dd>
             <dd>-	50 or above: {groupingNumber(this.data.livingMalesAgedOver49Number)} males, {groupingNumber(this.data.livingFemalesAgedOver49Number)} females</dd>
             <dt>Crossing the data between ID cards and living people, we get:</dt>
             <dd>-	{groupingNumber(this.data.livingPeopleWithCardsNumber)} people recorded as living have at least one non-expired, non-canceled, activated, ID card. I’ll call those “living people with cards”. The others, recorded as living, without a card, will be called “living people without cards”.</dd>
             <dt>Age or Sex information is missing in a small number of cases. These cases are excluded those from calculations. For “living people with cards”:</dt>
             <dd>-	Below 18: {groupingNumber(this.data.livingMalesAgedUnder18WithCardsNumber)} males, {groupingNumber(this.data.livingFemalesAgedUnder18WithCardsNumber)} females</dd>
             <dd>-	18 or above, below 30: {groupingNumber(this.data.livingMalesAged18To29WithCardsNumber)} males, {groupingNumber(this.data.livingFemalesAged18To29WithCardsNumber)} females</dd>
             <dd>-	30 or above, below 50: {groupingNumber(this.data.livingMalesAged30To49WithCardsNumber)} males, {groupingNumber(this.data.livingFemalesAged30To49WithCardsNumber)} females</dd>
             <dd>-	Above 50: {groupingNumber(this.data.livingMalesAgedOver49WithCardsNumber)} males, {groupingNumber(this.data.livingFemalesAgedOver49WithCardsNumber)} females</dd>
            </dl>
            </>
		)
	}
}

export const applyForGeneralStatisticsReport = (gui) => {
    let dto = {}
    dto.name = ""
    rest.request(getServiceUri() + 'reports/new-document', 'POST', dto).then(response => {
        if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Applyed for new General Statistics Report`, "success");
    })
}