import * as React from 'react';
import QRCode from "react-qr-code";

import {divorceCertificateContent} from "./DivorceCerificateCommon.jsx";
import { geoDataMetadataLoader } from '../../../auto/js/metadata/GeoDataMetadataLoader';
import { t } from '../../../auto/js/services';


const formatDate = (date) => {
	if (date != null) {
		let d = new Date(date[0], date[1]-1, date[2]);
		return d.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"});
	} else
		return t`Unknown`;
}

const formatTime = (time) => {
	if (time !== null) {
		let h = (time[0] < 0)? "0" + time[0] : time[0];
		let m = (time[0] < 0)? "0" + time[1] : time[1];
		return h + ":" + m;
	} else
		return t`Unkown Time`
}

const formatLocation = (location) => {
	let marriagePlace = [];
	if (location != null) {
		let marriagePlaceComponents = location.split(".");
		let place = location;
		marriagePlace.push(
			(geoDataMetadataLoader.getArea(place))?t(geoDataMetadataLoader.getArea(place).name + " "):t("INVALID DATA ")
		)
		for (let i = 0; i < marriagePlaceComponents.length - 1 ; i ++) {
			let parentAreaId = place.substring(0, place.lastIndexOf("."));
			place = parentAreaId;
			marriagePlace.push(
				(geoDataMetadataLoader.getArea(parentAreaId))?t(geoDataMetadataLoader.getArea(parentAreaId).name + " "):t("INVALID DATA ")
			)                
		}
	}
	else
		marriagePlace.push(<p>{t`Unknown Place`}</p>)
	return marriagePlace;
}

export class DivorceCertificate extends React.Component {
	constructor(props) {
		super(props);
		this.data = props.data;
	}

	// @ts-ignore
	render() {
		let marriagedate = this.data.marriagedate? formatDate(this.data.marriagedate):'Unknown';
		var currentdate = new Date();
		var datetime = currentdate.getDate() + "/"
			+ (currentdate.getMonth() + 1) + "/"
			+ currentdate.getFullYear() + " @ "
			+ currentdate.getHours() + ":"
			+ currentdate.getMinutes() + ":"
			+ currentdate.getSeconds();

		let declarationDate = this.data.declarationDate? formatDate(this.data.declarationDate):'Unknown';
		
		return (

			<div className="page">
				<div className="header">
					<div className="headercol1">
						<h2 className="lefttext"> {divorceCertificateContent.leftTitle1}<br/>
							{divorceCertificateContent.leftTitle2}</h2></div>
					<div className="headercol2 logos">

					</div>
					<div className="headercol3">
						<h2 className="righttext">{divorceCertificateContent.rightTitle1}<br/>{divorceCertificateContent.rightTitle2}</h2>
					</div>
					<div className="headercol4">

					</div>
					<div className="headercol5">
						<h1>{divorceCertificateContent.mainTitle}</h1>
						<h2>{divorceCertificateContent.mainSubTitle}</h2>
					</div>
					{/* <div className="headercol6">
						<h2 className="righttext idnumber1">{t`National ID Number`}: {this.data.id}</h2>
					</div> */}
				</div>
				<div className="bodycontent">
					<div class="bodyrow">
						<p class="bottom" style={{margin:"0 0 4mm 0"}}>This divorce decree offically certifies that the marriage of {this.data.partner2Name} and {this.data.partner1Name}, which occured on the {declarationDate} at {formatTime(this.data.timeOfMarriage)} in {(this.data.declarationPlace !== null)? this.data.declarationPlace: t`Unknown Place`} with the marriage certificate number {this.data.unionRecordId} has now ended.</p>

						<p class="bottom" style={{margin:"0 0 4mm 0"}}>This divorce is under the order of {this.data.annulmentOrder} dated {formatDate(this.data.dateOfOrder)}.</p>
						<p class="bottom" style={{margin:"0 0 4mm 0"}}>This divorce is effective from the {formatDate(this.data.dateOfEffect)}.</p>
						</div>


						<div class="bodyrowbottom">


						<div class="qr-code"><QRCode value={ "\nCertificate issued on " + declarationDate + ' - Signed by Director Jeffrey Langati Trief'} size={80}/></div>
						<div class="signature"></div>

						<p class="lefttext idnumber1">Certified at {"Vanuatu"} on {datetime} by</p><p style={{marginTop:"3.5mm", lineHeight:"10pt"}}> <strong>Jeffrey Langati Trief<br/>Registrar General</strong></p>

					</div> 
				</div>
			</div>

		)
	}
}