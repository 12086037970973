import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { AlertDialog } from "../../widgets";
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { updateRejectedAmendmentUnionApplicationForm, loadAmendmentUnionApplicationFormData } from "./AmendmentUnionApplicationApi";
import { amendmentUnionApplicationFields } from "../../../../main/js/forms/amendmentUnionApplication/AmendmentUnionApplicationFormCommon.jsx";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileInput } from "../../widgets/FileInput";
import { Section } from "../../../../auto/js/widgets/Section";
import { createTableComponent } from "../../widgets/TableComponent";
import { loadUnionMtlbAttachment } from "../../../../main/js/forms/amendmentUnionApplication/AmendmentUnionApplicationFormCommon.jsx";
		
let AmendmentUnionApplicationForm = createFormComponent(amendmentUnionApplicationFields);

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<AmendmentUnionApplicationForm ref={this.myRef} key={this.props.key} loadData={async () => loadFormData(this.props.id)} onSubmit={(this.props.onFinish)?(data) => update(data).then(() => this.props.onFinish({message: "Union amendment application reopened."})):update} id={this.props.id} buttons={() => getButtons(this.props.id)} />
            <FileInput label="Attachments" previewUrl={(id) => getPreviewUrl(id)} uploadUrl={getServiceUri() + 'union-mtlb/attachment/' +  this.props.id} updateFileData={(data) => updateFileData(data)} loadData={async () => loadUnionMtlbAttachment(this.props.id)} handleDelete={(id) => handleUnionMtlbAttachmentDelete(id)} handleClick={(id) => handleUnionMtlbAttachmentClick(id)}/>
		</>
		)
	}

}

const handleUnionMtlbAttachmentDelete = (id) => {
	rest.delete('union-mtlb/attachment', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const handleUnionMtlbAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'union-mtlb/attachment' + '/' + id + '/' + token;	
};
const updateFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'union-mtlb/attachment' + '/' + data.id, "PUT", filter);
}
const getPreviewUrl = (id) => {
	return getServiceUri() + 'union-mtlb/attachment' + '/preview/' + id;
}

export const displayReadRejectedAmendmentUnionApplicationForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} id={id} onFinish={onFinish}/>
	};
}

const update = async (formData) => {
	return updateRejectedAmendmentUnionApplicationForm(formData);
}

const loadFormData = async (id) => {
	return await loadAmendmentUnionApplicationFormData(id);
}

const getButtons = (id) => {
	return (
    <>
        <button style={{ minWidth: '5rem' }} type="submit"> {t`Reopen`} </button>
    </>
    )
}
