import React from "react";
import {v4 as uuidv4} from 'uuid';
import { createFormComponent } from '../../../auto/js/widgets/FormComponent';
import { rest, t } from "../../../auto/js/services";
import { showNotification } from "../../../auto/js/utils";
import { certificateStyle } from "./MarriageCertificateStyleObj";
import { divorceCertificateStyle } from "./DivorceCertificateStyleObj";

import { Section } from "../widgets/Section";
import { PersonComponent } from "../../../auto/js/widgets/PersonComponent";
import { loadPersonData } from "../utils";
import { GeoDataComponent } from "../../../auto/js/widgets/GeoDataComponent";
import { AttachmentsArea, setContextualOptions } from "../../../auto/js/widgets";
import { MarriageCertificate } from "./MarriageCertificate";
import { PRINT_EVENT } from "../../../auto/js/events/Gui";
import { DivorceCertificate } from "./DivorceCertificate";
import { displayNewDivorceRegistrationForm } from "../../../auto/js/forms/divorceRegistration/NewDivorceRegistrationForm";
import { AdvancedSearchPersonComponent } from "../widgets/AdvancedSearchPersonComponent";
import { displayAmendmentUnionApplicationList } from "../../../auto/js/lists/amendmentUnionApplication/AmendmentUnionApplicationList";
import { displayNewAmendmentUnionApplicationForm } from "../../../auto/js/forms/amendmentUnionApplication/NewAmendmentUnionApplicationForm";
import { getServiceUri } from "../../../auto/js/metadata";

const gender = {1:"MALE", 2:"FEMALE"};
const maritalStatus = {1:"SINGLE", 2:"MARRIED", 3:"DIVORCED", 4:"WIDOWED"};
const regime = {1:"Civil Marriage", 2:"Religious Marriage", 3: "Custom Marriage"};
	
/* const fields = [
    {name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name: "id", type: "number", x:1, y:2, layout:"col-md-12"},
    {name: "celebrant", type: "text", x:1, y:20, layout:"col-md-4"},
    {name: "marriageDetails", type: "custom", x:1, y:3, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "dateOfMarriage", type: "date", x:1, y: 4, layout:"col-md-6"},
    {name: "timeOfMarriage", type: "time", x:2, y:4, layout:"col-md-6"},
    {name: "unionRegime", type: "select", x:1, y: 5, layout:"col-md-6",  metadata: () => regime},
    {name:"addressBox", label: "Place", components: [
        {name: "address", type: "custom", x:1, y:1, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"191"}/>},
    ], type: "box", x:2, y:6, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},    {name: "wifeDetails", type: "custom", x:1, y:7, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "partner2VitalRecordId", type: "custom", x:1, y:8, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "husbandDetails", type: "custom", x:1, y:10, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "partner1VitalRecordId", type: "custom", x:1, y:11, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "witnessDetails", type: "custom", x:1, y:13, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "firstWitnessId", type: "custom", x:1, y:14, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} readOnly={disabled}loadData={loadPersonData}/>},
    {name: "secondWitnessId", type: "custom", x:1, y:16, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "divorceDetails", type: "custom", x:1, y:17, layout:"col-md-12", component: (name, disabled) => <Section name={name} />, display: (data) => {return data.dateOfEffect !== null }},
    {name: "dateOfEffect", type: "date",  x:1, y:18, layout:"col-md-6", display: (data) => {return data.dateOfEffect !== null }},
    {name: "dateOfOrder", type: "date",  x:2, y:18, layout:"col-md-6", display: (data) => {return data.dateOfEffect !== null }},
    {name: "declarationDetails", type: "custom", x:1, y:19, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "officer", type: "text", x:1, y:21, layout:"col-md-4"},
    {name: "position", type: "text", x:2, y:21, layout:"col-md-4"},
    {name: "Extras", type: "custom", x:1, y:22, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "notes", type: "text", x:1, y:23, layout:"col-md-12"}
]; */

const fields = [
    {name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name: "marriageDetails", type: "custom", x:1, y:2, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "id", type: "number", x:1, y:3, layout:"col-md-6", disabled: true},
    {
		name: "celebrantId", label: "Search Celebrant", type: "custom", x: 1, y: 5, layout: "col-md-12",
		component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Celebrant"} readOnly={disabled} loadData={loadPersonData} />,
	},
	{
		name: "reportedCelebrant", type: "text", x: 2, y: 6, layout: "col-md-12",
		display: (data) => { return data.reportedCelebrant !== null && data.celebrantId === null ? true : false; }
	},
	{name: "dateOfMarriage", type: "date", x:1, y: 7, layout:"col-md-6"},
    {name: "timeOfMarriage", type: "time", x:2, y:7, layout:"col-md-6"},
    {name: "unionRegime", type: "select", x:1, y: 8, layout:"col-md-6",  metadata: () => regime},
    {name:"addressBox", label: "Place", components: [
        {name: "address", type: "custom", x:1, y:1, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"191"}/>},
    ], type: "box", x:2, y:9, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
    {name: "wifeDetails", type: "custom", x:1, y:10, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "partner2VitalRecordId", type: "custom", x:1, y:11, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Bride"} readOnly={disabled} loadData={loadPersonData} />},
    {name: "husbandDetails", type: "custom", x:1, y:12, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "partner1VitalRecordId", type: "custom", x:1, y:13, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Groom"} readOnly={disabled} loadData={loadPersonData} />},
    {name: "witnessDetails", type: "custom", x:1, y:14, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "firstWitnessId", type: "custom", x:1, y:15, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search First Witness"} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "secondWitnessId", type: "custom", x:1, y:16, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Second Witness"} readOnly={disabled} loadData={loadPersonData}/>},
    
	{ name: "declarationDetails", type: "custom", x: 1, y: 17, layout: "col-md-12", component: (name, disabled) => <Section name={name} /> },
	{
		name: "declarantId", label: "Search Declarant", type: "custom", x: 1, y: 19, layout: "col-md-12",
		component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Declarant"} readOnly={disabled} loadData={loadPersonData} />,
	},
	{
		name: "reportedDeclarant", type: "text", x: 2, y: 20, layout: "col-md-12",
		display: (data) => { return data.reportedDeclarant !== null && data.declarantId === null ? true : false; }
	},
    {name:"registrationBox", label: "Registration Location", components: [
		{name: "registrationLocation", type: "custom", x:1, y:1, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"191"}/>}
	 ], type: "box", x:2, y:22, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
	{
		name: "officerId", label: "Search officer", type: "custom", x: 1, y: 23, layout: "col-md-12",
		component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Officer"} readOnly={disabled} loadData={loadPersonData} />,
	},
	{
		name: "reportedOfficer", type: "text", x: 2, y: 24, layout: "col-md-12",
		display: (data) => { return data.reportedOfficer !== null && data.officerId ===null ? true : false; }
	},
	{name: "divorceDetails", type: "custom", x:1, y:25, layout:"col-md-12", component: (name, disabled) => <Section name={name} />, display: (data) => {return data.dateOfEffect !== null }},
	{name: "dateOfEffect", type: "date",  x:1, y:26, layout:"col-md-6", display: (data) => {return data.dateOfEffect !== null }},
    {name: "dateOfOrder", type: "date",  x:2, y:26, layout:"col-md-6", display: (data) => {return data.dateOfEffect !== null }},
    {name: "courtOrderFile", label:"Court Order", type: "file", x:1, y:27, layout:"col-md-12", 
		uploadUrl: (id) => getCourtOrderUploadUrl(id), 
        previewUrl: async (id) => getCourtOrderPreviewUrl(id),
		loadData: async (id) => loadCourtOrder(id), 
		handleDelete:(id) => handleCourtOrderDelete(id), 
		handleClick: (id) => handleCourtOrderClick(id),
		updateFileData: (data) => updateCourtOrderFileData(data),
        display: (data) => {return data.dateOfEffect !== null }
	},
    {name: "Mentions", type: "custom", x:1, y:34, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "mentions", type: "text", x:1, y:35, layout:"col-md-12"},
	{name: "Extras", type: "custom", x:1, y:36, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "notes", type: "text", x:1, y:37, layout:"col-md-12"}
];

const getCourtOrderUploadUrl = (id) => {
	return getServiceUri() + 'union-record/court-order-file' + '/' + id;
}

const getCourtOrderPreviewUrl = async (id) => {
    const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	return getServiceUri() + 'union-record/court-order-file' + '/preview/' + id + '/' + token;
}

const updateCourtOrderFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'union-record/court-order-file' + '/' + data.id, "PUT", filter);
}

const handleCourtOrderClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'union-record/court-order-file' + '/' + id + '/' + token;
};
const handleCourtOrderDelete = (id) => {
	rest.delete('union-record/court-order-file', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};
const loadCourtOrder = async (id) => {
	let filter = {and: true};
	filter['union-record-court-order-file'] = {};
	filter['union-record-court-order-file']['unionRecordId'] = id;
	return rest.search('union-record/court-order-file', filter)
}


export const displayUnionRecordForm = (gui, id) => {
    const readUnionRecordForm = (onFinish) => (id) => {
        let UnionRecordForm = createFormComponent(fields);
        let uuid = uuidv4();
        return {
            uuid, view: () => {
                return (
                    <>
                    <UnionRecordForm key={uuid} loadData={async () => loadFormData(id, gui)} readOnly={true} buttons={() => null}/>
                    <AttachmentsArea label={t`Attachments`} previewUrl={(id) => getPreviewUrl(id)} loadData={async () => loadUnionRecordAttachment(id)} handleClick={(fileId) => handleUnionRecordAttachmentClick(fileId)} readOnly/>   
                    </>
                )
            }
        };
    }

    gui.goTo(readUnionRecordForm(), id);
}

const handleUnionRecordAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'union-record-attachment' + '/' + id + '/' + token;
};

const getPreviewUrl = (id) => {
	return getServiceUri() + 'union-record-attachment' + '/preview/' + id;
}

const loadUnionRecordAttachment = async (id) => {
	let filter = {and: true};
	filter['union-record-attachment'] = {};
	filter['union-record-attachment']['unionRecordId'] = id;
	return rest.search('union-record-attachment', filter)
}

const loadFormData = async (id, gui) => {
	return await rest.read('union-record', id).then(response => {
		let form = response;
        if (form.timeOfMarriage !== null)
            form.timeOfMarriage = new Date().setHours(form.timeOfMarriage[0], form.timeOfMarriage[1]);
		
            let tagFilter = {and: true};
            tagFilter['union-record-tag'] = {unionRecordId: id};
        if (response.dateOfEffect != null) {
            rest.read('divorce-certificate/read', id).then(async data => {
                setContextualOptions({
                "marriage-records": {
                    submenu: {
                        "actions": {
                            options: {
                                "amend": { 
                                label: "Amend", do: (gui) => displayNewAmendmentUnionApplicationForm(gui, form)
                                },
                                "marriage-certificate": { 
                                    label: "Certificate", do: printDivorceCertificate(data)
                                },
                            },
                            label: "Actions"
                        }
                    }
                }
            })});
        } 
        else
            rest.read('marriage-certificate/read', id).then(async data => {
                setContextualOptions({
                "marriage-records": {
                    submenu: {
                        "actions": {
                            options: {
                                "amend": { 
                                    label: "Amend", do: (gui) => displayNewAmendmentUnionApplicationForm(gui, form)
                                },
                                "marriage-certificate": { 
                                    label: "Certificate", do: printMarriageCertificate(data)
                                },
                                "apply-divorce": { 
                                    label: "Apply For Divorce", do: () => applyForDivorce(form, gui)
                                },
                            },
                            label: "Actions"
                        }
                    }
                }
            })});
        return rest.search('union-record-tag', tagFilter).then(tags => {
            form['tags'] = tags
            
            return form;
        })
	})
}

const printMarriageCertificate = (data) => () => {
	const printable = {
				content:<MarriageCertificate data={data}/>,
				style:{certificateStyle}.certificateStyle,
				copyParentStyle:false
	}
	PRINT_EVENT.publish(printable);
}

const printDivorceCertificate = (data) => () => {
	const printable = {
				content:<DivorceCertificate data={data}/>,
				style:{divorceCertificateStyle}.divorceCertificateStyle,
				copyParentStyle:false
	}
	PRINT_EVENT.publish(printable);
}

const applyForDivorce = (form, gui) => {
    displayNewDivorceRegistrationForm(gui, form);
}
