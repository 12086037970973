import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent";
import { displayReadCountryForm } from "../../forms/metadata/CountryForm";
import { getServiceUri } from "../../../../auto/js/metadata";
import { COUNTRY_ORDER_BY_NAME } from "../../../../auto/js/metadata/CountryOrderBy";
import { LOCATIONS_ORDER_BY_NAME } from "../../metadata/LocationsOrderBy";
import { GEO_DATA_ORDER_BY_NAME } from "../../metadata/GeoDataOrderBy";
import { OPEN_CONFIRMATION_DIALOG } from "../../../../auto/js/events/Gui";

const fields = [
    {title: "id", field: "id"},
    {title: "name", field: "name"},
    {title: "flag", field: "flag", render: rowData => <img src={rowData.flag} onError={(e)=>{e.target.onerror = null; e.target.src="/public/flag.png"}} style={{width: 70, height: 40}}/>}
];

export const displayCountriesList = (gui) => {
    const readCountriesList = () => {
        let CountriesList = createTableComponent(fields);
        let uuid = uuidv4();
        return {
            uuid, view: () => <CountriesList key={uuid} gui={gui} loadData={async (query) => buildData(query)} actions={getTableActions(gui)} />
        };
    }
    gui.goTo(readCountriesList)
}

const buildData = async (query) => {
    let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
    let filter = query;
    let data;
    filter["geo-data"] = {type: 1};
    filter["orderBy"] = GEO_DATA_ORDER_BY_NAME;
    filter.orderDirection = null;
    filter['and'] = true;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') 
        filter["geo-data"]["name"]= query.search;
    return await getCountriesData(filter).then(response => {
        data = filterData(response, token)
        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
}

const countData = async (filter) => {
    return await rest.count("geo-data", filter);
}

const filterData = (DefaultRows, token) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        const imageUrl = getServiceUri() + 'geo-data/flag/' + row.id + "/" + token;
        row.flag = (imageUrl != null)?imageUrl:"/public/flag.png"; 
      newRows.push(row);
    }
    return newRows;
}

const getCountriesData = async (filter) => {
    return await rest.search("geo-data", filter);
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    gui.goTo(displayReadCountryForm((options) => displayConfirmationDialog(options, gui)),rowData);
                }
        }
    )
    return actions;   
}
export const displayConfirmationDialog = (options, gui) => {
	let data = {title: "Confirmation", message: options.message, onClick: () => gui.goTo(displayCountriesList(gui))};
	OPEN_CONFIRMATION_DIALOG.publish(data);
}
