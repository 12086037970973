import React from "react";
import _ from 'lodash';
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import { dto2form, form2dto } from "../../../../main/js/forms/amendmentApplication/AmendmentApplicationFormCommon";

export const saveAmendmentApplicationForm = async (formData) => {
	let data = _.clone(formData);
    let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
    form2dto(formData, dto);
    try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/new-document', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadAmendmentApplicationFormData = async (id) => {
	return await rest.read('civil-status-mtlb', id).then(response => {
		let form = dto2form(response);
		let tagFilter = {and: true};
		tagFilter['civil-status-mtlb-tag'] = {civilStatusMtlbId: id};

		return rest.search('civil-status-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags

			return form;
		})
	})
}

export const updatePendingAmendmentApplicationForm = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/update', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const updateAmendmentApplicationForm = async (formData) => {
	let data = _.clone(formData);
	const dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/edit-document', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const deleteAmendmentApplicationForm = async (id) => {
    try {
        return rest.delete('civil-status-mtlb', id);
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedAmendmentApplicationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let data = _.clone(formData);
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/rejected/update', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const rejectAmendmentApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const rejectReadyAmendmentApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/ready/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const approveReadyAmendmentApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/ready/approve', 'POST', id);
    } catch (err) {
        alert(err);
    }
}
