import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { MTLB_TYPE_DIVORCE } from "../../../../auto/js/metadata/MtlbType";
import { UNION_MTLB_ORDER_BY_PARTNER1_FIRSTNAME } from "../../../../auto/js/metadata/UnionMtlbOrderBy";
import { rest } from "../../../../auto/js/services";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";


export const divorceRegistrationListColumns = [
    { title: "unionRecordId", field: "unionRecordId" },
    { title: "dateOfEffect", field: "dateOfEffect" },
    { title: "dateOfOrder", field: "dateOfOrder" },

];

export const getData = async (filter) => {
    let searchEndpoint = (filter.query) ? "union-mtlb/advanced-search" : "union-mtlb/search-by-union-mtlb-type-and-status/";
    return await rest.request(getServiceUri() + searchEndpoint, "POST", filter);
}

export const countData = async (filter) => {
    let countEndpoint = (filter.query) ? "union-mtlb/count/advanced-search" : "union-mtlb/count/search-by-union-mtlb-type-and-status/";
    return await rest.request(getServiceUri() + countEndpoint, "POST", filter);
}

export const getOrderBy = () => {
    return UNION_MTLB_ORDER_BY_PARTNER1_FIRSTNAME
}

export const getMtlbTypeList = () => {
    return [MTLB_TYPE_DIVORCE]
}

export const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let effectDate = row.dateOfEffect
        if (effectDate !== null)
            row.dateOfEffect = effectDate[2] + '-' + effectDate[1] + '-' + effectDate[0];
        let orderDate = row.dateOfOrder
        if (effectDate !== null)
            row.dateOfOrder = orderDate[2] + '-' + orderDate[1] + '-' + orderDate[0];
        newRows.push(row);
    }
    return newRows;
}

export const getErrorList = () => {
    let list = [];
    list.push("E32");
    return list;
}

export const advancedSearchfields = [
    { name: "caseSensitiveSearch", label: "Case Sensitive Search", type: "checkbox", x: 1, y: 1, layout: "col-md-3" },
    { name: "partner1VitalRecordId", type: "number", x: 1, y: 2, layout: "col-md-6" },
    { name: "partner2VitalRecordId", type: "number", x: 2, y: 2, layout: "col-md-6" },
    { name: "partner1Firstname", type: "text", x: 1, y: 4, layout: "col-md-6" },
    {
        name: "partner1FirstnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 4, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },
    { name: "partner1Surname", type: "text", x: 1, y: 3, layout: "col-md-6" },
    {
        name: "partner1SurnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 3, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },
    { name: "partner2Firstname", type: "text", x: 1, y: 6, layout: "col-md-6" },
    {
        name: "partner2FirstnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 6, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },
    { name: "partner2Surname", type: "text", x: 1, y: 5, layout: "col-md-6" },
    {
        name: "partner2SurnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 5, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    {
        name: "divorceBox", label: "Effect Date", components: [
            { name: "rangedivorcedate", label: "exactdivorcedate", type: "checkbox", x: 1, y: 1, layout: "col-md-12" },
            {
                name: "exactdivorcedate", type: "date", x: 1, y: 2, layout: "col-md-6", display: (data) => {
                    return !data.rangedivorcedate;
                }
            },

            {
                name: "divorcedateStart", type: "date", x: 1, y: 3, layout: "col-md-6", display: (data) => {
                    return data.rangedivorcedate;
                }
            },
            {
                name: "divorcedateEnd", type: "date", x: 2, y: 3, layout: "col-md-6", display: (data) => {
                    return data.rangedivorcedate;
                }
            },
        ], type: "box", x: 1, y: 8, layout: "col-md-11 ms-2 mt-2 pt-1 pb-2"
    },
];

export const transformAdvancedSearchData = (formData) => {
    const columns = [];

    const addColumn = (name, type, value, condition) => {
        columns.push({
            name,
            type,
            [type === 'date' ? 'dateValue' : type === 'boolean' ? 'booleanValue' : (type === 'text' ? 'textValue' : 'longValue')]: value,
            searchType: condition ? condition : null,
        });
    };
    (formData.partner1VitalRecordId !== null && formData.partner1VitalRecordId !== "") && addColumn('partner1VitalRecordId', 'number', formData.partner1VitalRecordId, null);
    (formData.partner2VitalRecordId !== null && formData.partner2VitalRecordId !== "") && addColumn('partner2VitalRecordId', 'number', formData.partner2VitalRecordId, null);

    (formData.partner1Firstname !== "") && addColumn('partner1Firstname', 'text', formData.partner1Firstname, formData.partner1FirstnameCondition);

    (formData.partner2Firstname !== "") && addColumn('partner1Firstname', 'text', formData.partner2Firstname, formData.partner2FirstnameCondition);

    (formData.partner1Surname !== "") && addColumn('partner1Surname', 'text', formData.partner1Surname, formData.partner1SurnameCondition);

    (formData.partner2Surname !== "") && addColumn('partner2Surname', 'text', formData.partner2Surname, formData.partner2SurnameCondition);
    addColumn('mtlbType', 'number', 10, null);
    addColumn('draft', 'boolean', false, null);
    const divorcedateRange = formData.divorcedateStart && formData.divorcedateEnd
        ? {
            min: formData.divorcedateStart ? formData.divorcedateStart : null,
            max: formData.divorcedateEnd ? formData.divorcedateEnd : null,
        }
        : null;

    (divorcedateRange != null || formData.exactdivorcedate != null) && addColumn('dateOfEffect', 'date', formData.exactdivorcedate, null);
    if ((divorcedateRange != null || formData.exactdivorcedate != null))
        columns[columns.length - 1].dateRange = divorcedateRange;


    return { columns, caseSensitiveSearch: formData.caseSensitiveSearch };
};