
const gender = {1:"MALE", 2:"FEMALE"};

export const adjudicationFields = [
	{name: "image", type: "image", x: 1, y: 3, layout: "col-md-12" },
	{name: "firstname", type: "text", x:1, y:5, layout:"col-md-6"},
	{name: "secondname", type: "text", x:2, y:4, layout:"col-md-6"},
	{name: "thirdname", type: "text", x:1, y: 5, layout:"col-md-6"},
	{name: "fourthname", type: "text", x:1, y: 4, layout:"col-md-6"},
	{name: "fifthname", type: "text", x:2, y: 6, layout:"col-md-6"},
	{name: "gender", type: "select", x:1, y: 7, layout:"col-md-6", metadata: () => gender},
	{name: "birthdate", type: "date", x:1, y: 8, layout:"col-md-6"},
	{name: "birthTime", type: "time", x:2, y:8, layout:"col-md-6"},
    {name: "leftThumbImage", type:"view", x:1, y:17, layout:"col-md-6"},
	{name: "leftIndexFingerImage", type:"view", x:2, y:17, layout:"col-md-6"},
	{name: "leftMiddleFingerImage", type:"view", x:1, y:18, layout:"col-md-6"},
	{name: "leftRingFingerImage", type:"view", x:2, y:18, layout:"col-md-6"},
	{name: "leftPinkyImage", type:"view", x:1, y:19, layout:"col-md-6"},
	{name: "rightThumbImage", type:"view", x:2, y:19, layout:"col-md-6"},
	{name: "rightIndexFingerImage", type:"view", x:1, y:20, layout:"col-md-6"},
	{name: "rightMiddleFingerImage", type:"view", x:2, y:20, layout:"col-md-6"},
	{name: "rightRingFingerImage", type:"view", x:1, y:21, layout:"col-md-6"},
	{name: "rightPinkyImage", type:"view", x:2, y:21, layout:"col-md-6"}
];

export const adjudicationMatchFields = [
	{name: "image", type: "image", x: 1, y: 3, layout: "col-md-12" },
	{name: "firstname", type: "text", x:1, y:5, layout:"col-md-6"},
	{name: "secondname", type: "text", x:2, y:4, layout:"col-md-6"},
	{name: "thirdname", type: "text", x:1, y: 5, layout:"col-md-6"},
	{name: "fourthname", type: "text", x:1, y: 4, layout:"col-md-6"},
	{name: "fifthname", type: "text", x:2, y: 6, layout:"col-md-6"},
	{name: "gender", type: "select", x:1, y: 7, layout:"col-md-6", metadata: () => gender},
	{name: "birthdate", type: "date", x:1, y: 8, layout:"col-md-6"},
	{name: "birthTime", type: "time", x:2, y:8, layout:"col-md-6"},
];