import React, { useCallback } from 'react';

import styled from 'styled-components';
import { createBrowserComponent } from './Browser';
import { RibbonTab, setContextualOptions } from '../widgets';
import { OPEN_SIDE_PANEL, OPEN_ADVANCED_SEARCH } from '../events/Gui';

import { ToolBar } from '../widgets/ToolBar';
import { SidePanelComponent } from '../widgets/SidePanelComponent';
import { AdvancedSearchComponent } from '../../../main/js/widgets/AdvancedSearchComponent';
import { ConfirmationDialog } from '../../../main/js/widgets/ConfirmationDialog';

const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    height: calc(100vh - 302px);
	overflow-y: auto;
	direction: ltr;
	margin-top: 20px;
	margin-bottom: 20px;
`;

export class RibbonBrowser extends createBrowserComponent() {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            openPanel: false
        }
        this.panelBody = undefined;
		this.panelTitle = "";
        OPEN_SIDE_PANEL.subscribe((panelBody, title) => {
			this.openSidePanel(panelBody, title);
		});
        
    }

    openSidePanel = (panel) => {
		this.panelBody = panel.body;
		this.panelTitle = panel.title;
		this.setState({openPanel: true});
	}

    

    closePanel = () => {
        this.setState({openPanel: false});
    }

    
    
    render() { 
        return (
            <>
            <ToolBar languages={this.props.supportedLanguages}/>
			<RibbonTab gui={this}/>
			<Main>
			{this.buildDesktop()}
            <SidePanelComponent open={this.state.openPanel} body={this.panelBody} title={this.panelTitle} closePanel={this.closePanel} />
			</Main>	
            <AdvancedSearchComponent/>
            <ConfirmationDialog/>
            </>
        )
    }
}