import React from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import {createTableComponent} from "../../../../auto/js/widgets/TableComponent";
import {geoDataMetadataLoader} from "../../metadata/GeoDataMetadataLoader";
import { displayReadZoneForm } from "../../forms/metadata/ZoneForm";
import { showNotification } from "../../../../auto/js/utils";
import { OPEN_CONFIRMATION_DIALOG } from "../../../../auto/js/events/Gui";

const fields = [
    {title: "country", field: "country"},
    {title: "province", field: "province"},
    {title: "zone", field: "zone"},
];

export const displayZoneList = (gui) => {
	const readZoneList = (onFinish) => () => {
	    let LocationsList = createTableComponent(fields);
	    let uuid = uuidv4();
	    return {
	        uuid, view: () => <LocationsList key={uuid} localData={true} loadData={geoDataMetadataLoader.getZoneList()} editable={getTableEditable()} actions={getTableActions(gui)} />
	    };
    }
    gui.goTo(readZoneList());
}

const getTableEditable = () => {
    let editables = {};
    editables.onRowDelete = rowData =>
    new Promise((resolve, reject) => {
        try {
            let dto = form2dto(rowData);
            return rest.update('geo-data', dto).then((response) =>
            {
                if (response.status) {
                    showNotification(response.message?.split('Detail: ')[1], "error");
                    reject();
                }
                else {
                    showNotification(t`Deleted Zone`, "success");
                    geoDataMetadataLoader.load().then(() => resolve());
                }
            })
        } catch (err) {
            alert(err);
            reject();
        }
    }).then(() => displayZoneList());
    return editables;
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
        icon: () => <VisibilityIcon/>,
        tooltip: t`Edit`,
        onClick: (event, rowData) => {
            gui.goTo(displayReadZoneForm((options) => displayConfirmationDialog(options, gui)),{
                "country": {key: rowData.countryId, value: rowData.country},
                "province": {key: rowData.provinceId, value: rowData.province},
                "zone": rowData.zone,
                "id": rowData.zoneId,
                "areaId": rowData.areaId
            }) 
        }
        }
    );
    return actions;
}

const form2dto = (data) => {
	let updatedata = {};
	updatedata.id = ~~(data.zoneId);
	updatedata.areaId = data.areaId;
	updatedata.name = data.zone;
	updatedata.parentId = ~~(data.provinceId);
	updatedata.type = 3;
	if(updatedata.areaId == "")
		updatedata.areaId = null;
	if(updatedata.name == "")
		updatedata.name = null;
    updatedata.disabled = true;
	return updatedata
}
export const displayConfirmationDialog = (options, gui) => {
	let data = {title: "Confirmation", message: options.message, onClick: () => gui.goTo(displayZoneList(gui))};
	OPEN_CONFIRMATION_DIALOG.publish(data);
}