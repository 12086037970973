import { t } from "../../../auto/js/services";

export const getErrorList = () => {
    let list = [];
    for (let i = 23; i < 32; i++) {
        let code = "E" + i
        list.push(code);
    }
    return list;
}

export const advancedSearchfields = [
    { name: "caseSensitiveSearch", label: "Case Sensitive Search", type: "checkbox", x: 1, y: 1, layout: "col-md-3" },
    { name: "partner1VitalRecordId", type: "number", x: 1, y: 2, layout: "col-md-6" },
    { name: "partner2VitalRecordId", type: "number", x: 2, y: 2, layout: "col-md-6" },
    { name: "partner1Firstname", type: "text", x: 1, y: 4, layout: "col-md-6" },
    {
        name: "partner1FirstnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 4, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },
    { name: "partner1Surname", type: "text", x: 1, y: 3, layout: "col-md-6" },
    {
        name: "partner1SurnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 3, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },
    { name: "partner2Firstname", type: "text", x: 1, y: 6, layout: "col-md-6" },
    {
        name: "partner2FirstnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 6, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },
    { name: "partner2Surname", type: "text", x: 1, y: 5, layout: "col-md-6" },
    {
        name: "partner2SurnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 5, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    {
        name: "marriageBox", label: "Effect Date", components: [
            { name: "rangemarriagedate", label: "exactmarriagedate", type: "checkbox", x: 1, y: 1, layout: "col-md-12" },
            {
                name: "exactmarriagedate", type: "date", x: 1, y: 2, layout: "col-md-6", display: (data) => {
                    return !data.rangemarriagedate;
                }
            },

            {
                name: "marriagedateStart", type: "date", x: 1, y: 3, layout: "col-md-6", display: (data) => {
                    return data.rangemarriagedate;
                }
            },
            {
                name: "marriagedateEnd", type: "date", x: 2, y: 3, layout: "col-md-6", display: (data) => {
                    return data.rangemarriagedate;
                }
            },
        ], type: "box", x: 1, y: 8, layout: "col-md-11 ms-2 mt-2 pt-1 pb-2"
    },
];

export const transformAdvancedSearchData = (formData) => {
    const columns = [];

    const addColumn = (name, type, value, condition) => {
        columns.push({
            name,
            type,
            [type === 'date' ? 'dateValue' : type === 'boolean' ? 'booleanValue' : (type === 'text' ? 'textValue' : 'longValue')]: value,
            searchType: condition ? condition : null,
        });
    };
    (formData.partner1VitalRecordId !== null && formData.partner1VitalRecordId !== "") && addColumn('partner1VitalRecordId', 'number', formData.partner1VitalRecordId, null);
    (formData.partner2VitalRecordId !== null && formData.partner2VitalRecordId !== "") && addColumn('partner2VitalRecordId', 'number', formData.partner2VitalRecordId, null);

    (formData.partner1Firstname !== "") && addColumn('partner1Firstname', 'text', formData.partner1Firstname, formData.partner1FirstnameCondition);

    (formData.partner2Firstname !== "") && addColumn('partner1Firstname', 'text', formData.partner2Firstname, formData.partner2FirstnameCondition);

    (formData.partner1Surname !== "") && addColumn('partner1Surname', 'text', formData.partner1Surname, formData.partner1SurnameCondition);

    (formData.partner2Surname !== "") && addColumn('partner2Surname', 'text', formData.partner2Surname, formData.partner2SurnameCondition);
    const marriagedateRange = formData.marriagedateStart && formData.marriagedateEnd
        ? {
            min: formData.marriagedateStart ? formData.marriagedateStart : null,
            max: formData.marriagedateEnd ? formData.marriagedateEnd : null,
        }
        : null;

    (marriagedateRange != null || formData.exactmarriagedate != null) && addColumn('dateOfMarriage', 'date', formData.exactmarriagedate, null);
    if ((marriagedateRange != null || formData.exactmarriagedate != null))
        columns[columns.length - 1].dateRange = marriagedateRange;

    return { columns, caseSensitiveSearch: formData.caseSensitiveSearch };
};


export const filterData = (defaultRows) => {
    const newRows = [];
    for (let i in defaultRows) {
        let row = defaultRows[i];
        if (row.dateOfEffect != null)
            row.marriageStatus = t`Divorce`;
        else
            row.marriageStatus = t`Marriage`
        newRows.push(row);
    }
    return newRows;
}
