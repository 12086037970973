import React from "react";
import _ from 'lodash';
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import { dto2form, form2dto } from "../../../../main/js/forms/adoptionApplication/AdoptionApplicationFormCommon";

export const saveAdoptionApplicationForm = async (formData) => {
	let data = _.clone(formData);
    let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
    form2dto(formData, dto);
    try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/new-document', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadAdoptionApplicationFormData = async (id) => {
	return await rest.read('civil-status-mtlb', id).then(response => {
		let form = dto2form(response);
		let tagFilter = {and: true};
		tagFilter['civil-status-mtlb-tag'] = {civilStatusMtlbId: id};

		let prevFatherFilter = {and: true};
		prevFatherFilter['adoptive-fathers'] = {current: true, childId: form.vitalRecordId}
		return rest.search('adoptive-fathers', prevFatherFilter).then(fathers => {
			if (fathers.length) {
				form.prevFatherId = fathers[0].vitalRecordId;
				form.fatherAdoptionDate = fathers[0].fatherAdoptionDate;
			}
			let prevMotherFilter = {and: true};
			prevMotherFilter['adoptive-mothers'] = {current: true, childId: form.vitalRecordId}
			return rest.search('adoptive-mothers', prevMotherFilter).then(mothers => {
				if (mothers.length) {
					form.prevMotherId = mothers[0].vitalRecordId;
					form.motherAdoptionDate = mothers[0].motherAdoptionDate;
				}
				return rest.search('civil-status-mtlb-tag', tagFilter).then(tags => {
					form['tags'] = tags
					
					return form;
				})			
			})		
		})
	})
}

export const updatePendingAdoptionApplicationForm = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/update', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const updateAdoptionApplicationForm = async (formData) => {
	let data = _.clone(formData);
	const dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/edit-document', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const deleteAdoptionApplicationForm = async (id) => {
    try {
        return rest.delete('civil-status-mtlb', id);
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedAdoptionApplicationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let data = _.clone(formData);
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/rejected/update', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const rejectAdoptionApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const rejectReadyAdoptionApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/ready/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const approveReadyAdoptionApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'civil-status-mtlb/ready/approve', 'POST', id);
    } catch (err) {
        alert(err);
    }
}
