import * as React from 'react';
import { useField } from "formik";
import { Observable } from '../../../auto/js/events/Observable';
import { geoDataMetadataLoader } from '../../../auto/js/metadata/GeoDataMetadataLoader';
import { t } from '../../../auto/js/services';
import { SimpleAutoCompleteInput } from '../../../auto/js/widgets/SimpleAutoCompleteInput';

export function GeoDataComponent({ ...props }) {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [hideProvince, setHideProvince] = React.useState(true);
    const [hideZone, setHideZone] = React.useState(true);
    const [hideDistrict, setHideDistrict] = React.useState(true);
    const [hideIsland, setHideIsland] = React.useState(true);
    const [hideLocation, setHideLocation] = React.useState(true);
    const [loaded, setLoaded] = React.useState(false);

    const instanceSpecificConstant = React.useRef({
        countryObservable: new Observable(),
        resetProvinceEvent: new Observable(),
        provinceObservable: new Observable(),
        resetZoneEvent: new Observable(),
        zoneObservable: new Observable(),
        resetDistrictEvent: new Observable(),
        districtObservable: new Observable(),
        resetIslandEvent: new Observable(),
        islandObservable: new Observable(),
        resetLocationEvent: new Observable(),
    });

    let resetList= [
        instanceSpecificConstant.current.resetProvinceEvent,
        instanceSpecificConstant.current.resetZoneEvent,
        instanceSpecificConstant.current.resetDistrictEvent,
        instanceSpecificConstant.current.resetIslandEvent,
        instanceSpecificConstant.current.resetLocationEvent
    ]


    let countryValue;

    if (props.default && !loaded && value == null ) {
        let country = geoDataMetadataLoader.getArea(props.default);
        countryValue = { key: country.id, value: country.name };
        if (country) {
            instanceSpecificConstant.current.countryObservable.publish(geoDataMetadataLoader.getChilds(country.id))
            if (Object.keys(geoDataMetadataLoader.getChilds(country.id)).length)
                setHideProvince(false);
            else
                setHideProvince(true);
        }
        setValue(geoDataMetadataLoader.getAreaId(country.id));
        setLoaded(true)
    }
    const handleCountryChange = (value) => {
        if (value.key) {
            instanceSpecificConstant.current.countryObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHideProvince(false);
            else
                setHideProvince(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            instanceSpecificConstant.current.countryObservable.publish([])
            setHideProvince(true);
        }
        resetFieldsFromIndex(0);
    }
    let provinceValue;
    const handleProvinceChange = (value) => {
        if (value.key) {
            instanceSpecificConstant.current.provinceObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHideZone(false);
            else
                setHideZone(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            instanceSpecificConstant.current.provinceObservable.publish([])
            setHideZone(true);
        }
        resetFieldsFromIndex(1);
    }
    let zoneValue;
    const handleZoneChange = (value) => {
        if (value.key) {
            instanceSpecificConstant.current.zoneObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHideDistrict(false);
            else
                setHideDistrict(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            instanceSpecificConstant.current.zoneObservable.publish([])
            setHideDistrict(true);
        }
        resetFieldsFromIndex(2);
    }
    let districtValue;
    const handleDistrictChange = (value) => {
        if (value.key) {
            instanceSpecificConstant.current.districtObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHideIsland(false);
            else
                setHideIsland(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            instanceSpecificConstant.current.districtObservable.publish([])
            setHideIsland(true);
        }
        resetFieldsFromIndex(3);
    }
    let islandValue;
    const handleIslandChange = (value) => {
        if (value.key) {
            instanceSpecificConstant.current.islandObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHideLocation(false);
            else
                setHideLocation(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            instanceSpecificConstant.current.islandObservable.publish([])
            setHideLocation(true);
        }
        resetFieldsFromIndex(4);
    }
    let locationValue;
    const handleLocationChange = (value) => {
        if (value.key) {
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
    }
    const showData = (value) => {
	    let addressComponents = value.split(".")
	    if (addressComponents[0]) {
	        let country = geoDataMetadataLoader.getArea(addressComponents[0]);
	        if (country) {
	        	countryValue = { key: country.id, value: country.name };
		        instanceSpecificConstant.current.countryObservable.publish(geoDataMetadataLoader.getGeoLevel(country.id, 1))
		        if (hideProvince)
		        	setHideProvince(false);
	        }
	    }
	    if (addressComponents[1]) {
	        let province = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1]);
	        if (province) {
	        	provinceValue = { key: province.id, value: province.name };
		        instanceSpecificConstant.current.provinceObservable.publish(geoDataMetadataLoader.getGeoLevel(province.id, 2))
		        if (hideZone)
		        	setHideZone(false);
	        }
	    }
	    if (addressComponents[2]) {
	        let zone = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2]);
	        if (zone) {
	        	zoneValue = { key: zone.id, value: zone.name };
		        instanceSpecificConstant.current.zoneObservable.publish(geoDataMetadataLoader.getGeoLevel(zone.id, 3))
		        if (hideDistrict)
		        	setHideDistrict(false);
	        }
	    }
	    if (addressComponents[3]) {
	        let district = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3]);
	        if (district) {
	        	districtValue = { key: district.id, value: district.name };
		        instanceSpecificConstant.current.districtObservable.publish(geoDataMetadataLoader.getGeoLevel(district.id, 4))
		        if (hideIsland)
		        	setHideIsland(false);
	        }
	    }
	    if (addressComponents[4]) {
	        let island = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3] + "." + addressComponents[4]);
	        if (island) {
	        	islandValue = { key: island.id, value: island.name };
		        instanceSpecificConstant.current.islandObservable.publish(geoDataMetadataLoader.getGeoLevel(island.id, 5))
		        if (hideLocation)
		        	setHideLocation(false);
	        }
	    }
	    if (addressComponents[5]) {
	        let location = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3] + "." + addressComponents[4] + "." + addressComponents[5]);
	        if (location) {
	        	locationValue = { key: location.id, value: location.name };
	        }
	    }
	}

    const resetFieldsFromIndex = (index) => {
        for (let i = index; i < resetList.length; i++)
            resetList[i].publish();
    }
    if (value != null)
        showData(value);
    else if (props.default) {
        let country = geoDataMetadataLoader.getArea(props.default);
        countryValue = { key: country.id, value: country.name };
    }
    let style = (props.linear)?"row":"";
    return (
        <>
            <div class="mainform">
                <section class={"fullwidth " + style}>
                    	<SimpleAutoCompleteInput layout={(props.linear)?"2":""} name={"country"} options={() => geoDataMetadataLoader.getRootNodes()} label={t`country`} handleChange={(data) => handleCountryChange(data)}  defaultValue={countryValue} disabled={props.disabled}/>
                    	{(!hideProvince ||provinceValue) && <SimpleAutoCompleteInput layout={(props.linear)?"2":""} name={"province"} options={() => ""} label={t`province`} handleChange={(data) => handleProvinceChange(data)} observable={instanceSpecificConstant.current.countryObservable} reset={instanceSpecificConstant.current.resetProvinceEvent} defaultValue={provinceValue} disabled={props.disabled}/>}
                    	{(!hideZone ||zoneValue) && <SimpleAutoCompleteInput layout={(props.linear)?"2":""} name={"zone"} options={() => ""} label={t`zone`} handleChange={(data) => handleZoneChange(data)} observable={instanceSpecificConstant.current.provinceObservable} reset={instanceSpecificConstant.current.resetZoneEvent} defaultValue={zoneValue} disabled={props.disabled}/>}
                    	{(!hideDistrict ||districtValue) && <SimpleAutoCompleteInput layout={(props.linear)?"2":""} name={"district"} options={() => ""} label={t`district`} handleChange={(data) => handleDistrictChange(data)} observable={instanceSpecificConstant.current.zoneObservable} reset={instanceSpecificConstant.current.resetDistrictEvent} defaultValue={districtValue} disabled={props.disabled}/>}
                    	{(!hideIsland ||islandValue) && <SimpleAutoCompleteInput layout={(props.linear)?"2":""} name={"island"} options={() => ""} label={t`island`} handleChange={(data) => handleIslandChange(data)} observable={instanceSpecificConstant.current.districtObservable} reset={instanceSpecificConstant.current.resetIslandEvent} defaultValue={islandValue} disabled={props.disabled}/>}
                    	{(!hideLocation ||locationValue) && <SimpleAutoCompleteInput layout={(props.linear)?"2":""} name={"location"} options={() => ""} label={t`location`} handleChange={(data) => handleLocationChange(data)} observable={instanceSpecificConstant.current.islandObservable} reset={instanceSpecificConstant.current.resetLocationEvent} defaultValue={locationValue} disabled={props.disabled}/>}
                </section>
            </div>
        </>
    )
}
