export const RVFOUR_BIRTH_MTLB_ORDER_BY_ID = "ID";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_FIRSTNAME = "FIRSTNAME";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_SECONDNAME = "SECONDNAME";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_THIRDNAME = "THIRDNAME";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_FOURTHNAME = "FOURTHNAME";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_FIFTHNAME = "FIFTHNAME";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_SIXTHNAME = "SIXTHNAME";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_BIRTHDATE = "BIRTHDATE";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_BIRTHTIME = "BIRTHTIME";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_BIRTH_PLACE = "BIRTH_PLACE";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_MOTHER_ID = "MOTHER_ID";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_FATHER_ID = "FATHER_ID";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_GENDER = "GENDER";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_FACE = "FACE";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_FACE_MIME_TYPE = "FACE_MIME_TYPE";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_REPORTED_FATHER_NAME = "REPORTED_FATHER_NAME";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_REPORTED_MOTHER_NAME = "REPORTED_MOTHER_NAME";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_BIRTH_DAY_UNKNOWN = "BIRTH_DAY_UNKNOWN";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_BIRTH_MONTH_UNKNOWN = "BIRTH_MONTH_UNKNOWN";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_BIRTH_YEAR_UNKNOWN = "BIRTH_YEAR_UNKNOWN";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_MARITAL_STATUS = "MARITAL_STATUS";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_OCCUPATION = "OCCUPATION";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_EMAIL = "EMAIL";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_PHONE_NUMBER = "PHONE_NUMBER";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_ADDRESS = "ADDRESS";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_MTLB_STATUS = "MTLB_STATUS";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_NOTES = "NOTES";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_DRAFT = "DRAFT";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_DISABILITY = "DISABILITY";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_VITAL_RECORD_ID = "VITAL_RECORD_ID";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_MULTI_BIRTH = "MULTI_BIRTH";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_RELIGION = "RELIGION";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_OLD_ADDRESS = "OLD_ADDRESS";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_MTLB_TYPE = "MTLB_TYPE";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_ANNEX = "ANNEX";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_NUMBER_OF_ATTACHMENTS = "NUMBER_OF_ATTACHMENTS";
export const RVFOUR_BIRTH_MTLB_ORDER_BY_TIMESTAMP = "TIMESTAMP";
