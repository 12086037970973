import { displayNewBirthRegistrationForm } from '../../../auto/js/forms/birthRegistration/NewBirthRegistrationForm'
import { displayCivilRecordAdvancedSearch, displayCivilRecordsList } from '../../../auto/js/lists/CivilRecordList'
import { displayNewOccupationForm } from '../../../auto/js/forms/metadata/OccupationForm'
import { displayNewTranslationsForm } from '../../../auto/js/forms/metadata/TranslationsForm'
import { displayNewCountryForm } from '../../../auto/js/forms/metadata/CountryForm'
import { displayCountriesList } from '../../../auto/js/lists/metadata/CountriesList'
import { displayOccupationList } from '../../../auto/js/lists/metadata/OccupationList'
import { displayTranslationsList } from '../../../auto/js/lists/metadata/TranslationsList'
import { displayNewDeathRegistrationForm } from '../../../auto/js/forms/deathRegistration/NewDeathRegistrationForm'
import { displayDeathRecordAdvancedSearch, displayDeathRecordList } from '../../../auto/js/lists/DeathRecordList'
import { displayNewMarriageRegistrationForm } from '../../../auto/js/forms/marriageRegistration/NewMarriageRegistrationForm'
import { displayNewDivorceRegistrationForm } from '../../../auto/js/forms/divorceRegistration/NewDivorceRegistrationForm'
import { displayUnionRecordAdvancedSearch, displayUnionRecordList } from '../../../auto/js/lists/UnionRecordList'
import { whoami } from '../../../auto/js/users/UserInfo';
import { displayBirthRegistrationAdvancedSearch, displayBirthRegistrationList } from '../../../auto/js/lists/birthRegistration/BirthRegistrationList'
import { displayAmendmentApplicationList, displayAmendmentApplicationdAdvancedSearch } from '../../../auto/js/lists/amendmentApplication/AmendmentApplicationList'
import { displayDeathRegistartiondvancedSearch, displayDeathRegistrationList } from '../../../auto/js/lists/deathRegistration/DeathRegistrationList'
import { displayIdCardApplicationAdvancedSearch, displayIdCardApplicationList } from '../../../auto/js/lists/idCardApplication/IdCardApplicationList'
import { displayMarriageRegistrationAdvancedSearch, displayMarriageRegistrationList } from '../../../auto/js/lists/marriageRegistration/MarriageRegistrationList'
import { displayDivorceRegistrationAdvancedSearch, displayDivorceRegistrationList } from '../../../auto/js/lists/divorceRegistration/DivorceRegistrationList'
import { displayDigitalizationApplicationList } from '../../../auto/js/lists/digitalization/DigitalizationApplicationList'
import { displayFamilyTreeRegistrationList } from '../../../auto/js/lists/familyTreeRegistration/FamilyTreeRegistrationList'
import { displayIdCardAdvancedSearch, displayIdCardList } from '../../../auto/js/lists/IssuedIdCardList'
import { displayLocationList } from '../../../auto/js/lists/metadata/LocationList'
import { displayProvinceList } from '../../../auto/js/lists/metadata/ProvinceList'
import { displayNewProvinceForm } from '../../../auto/js/forms/metadata/ProvinceForm'
import { displayZoneList } from '../../../auto/js/lists/metadata/ZoneList'
import { displayNewZoneForm } from '../../../auto/js/forms/metadata/ZoneForm'
import { displayDistrictList } from '../../../auto/js/lists/metadata/DistrictList'
import { displayNewDistrictForm } from '../../../auto/js/forms/metadata/DistrictForm'
import { displayIslandList } from '../../../auto/js/lists/metadata/IslandList'
import { displayNewIslandForm } from '../../../auto/js/forms/metadata/IslandForm'
import { displayNewLocationForm } from '../../../auto/js/forms/metadata/LocationForm'
import { applyForGeneralStatisticsReport } from '../GeneralStatistics'
import { displayGeneralStatisticsList } from '../lists/ReportingList'
import { displayTemporaryCertificateWorkflow } from '../forms/TemporaryCertificateManager'
import { displayAdoptionApplicationAdvancedSearch, displayAdoptionApplicationList } from '../../../auto/js/lists/adoptionApplication/AdoptionApplicationList'
import { displayAmendmentDeathApplicationAdvancedSearch, displayAmendmentDeathApplicationList } from '../../../auto/js/lists/amendmentDeathApplication/AmendmentDeathApplicationList'
import { displayAmendmentUnionApplicationAdvancedSearch, displayAmendmentUnionApplicationList } from '../../../auto/js/lists/amendmentUnionApplication/AmendmentUnionApplicationList'
import { displayCountryList } from '../../../auto/js/lists/metadata/CountryList'
import { OPEN_CONFIRMATION_DIALOG } from '../../../auto/js/events/Gui'
export const menu = () => {
	return {
		...((whoami().roles.includes('ALL') || whoami().roles.includes('REGISTRAR')) && {
			"civil-records": {
				submenu: {
					"birth-applications": {
						options: {
							new: { label: "New", do: (gui) => displayTemporaryCertificateWorkflow(gui) },
							//	temporaryCertificate: { label: "Temporary Certificate", do: (gui) => displayTemporaryCertificateWorkflow(gui) },
							applications: { label: "Applications", do: (gui) => displayBirthRegistrationList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayBirthRegistrationAdvancedSearch(gui) },
						},
						label: "Birth Applications"
					},
					"amendment-application": {
						options: {
							applications: { label: "Applications", do: (gui) => displayAmendmentApplicationList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayAmendmentApplicationdAdvancedSearch(gui) }
						},
						label: "Amendments"
					},
					"adoption-application": {
						options: {
							applications: { label: "Applications", do: (gui) => displayAdoptionApplicationList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayAdoptionApplicationAdvancedSearch(gui) }
						},
						label: "Adoptions"
					},
					"birth-registry": {
						options: {
							search: { label: "Records", do: (gui) => displayCivilRecordsList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayCivilRecordAdvancedSearch(gui) }
						},
						label: "Birth Registry"
					}
				},
				label: "Birth"
			},
			"marriage-records": {
				submenu: {
					"marriage-registration": {
						options: {
							new: { label: "New", do: (gui) => (displayNewMarriageRegistrationForm(gui)) },
							applications: { label: "Applications", do: (gui) => displayMarriageRegistrationList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayMarriageRegistrationAdvancedSearch(gui) }
						},
						label: "Marriage Registration"
					},
					"divorce-registration": {
						options: {
							applications: { label: "Applications", do: (gui) => displayDivorceRegistrationList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayDivorceRegistrationAdvancedSearch(gui) }

						},
						label: "Divorce Registration"
					},
					"union-amendment": {
						options: {
							applications: { label: "Applications", do: (gui) => displayAmendmentUnionApplicationList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayAmendmentUnionApplicationAdvancedSearch(gui) }

						},
						label: "Amendments"
					},
					"records": {
						options: {
							search: { label: "Records", do: (gui) => displayUnionRecordList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayUnionRecordAdvancedSearch(gui) }

						},
						label: "Registry"
					}
				},
				label: "Marriage & Divorces"
			},
			"death-records": {
				submenu: {
					"death-registration": {
						options: {
							new: { label: "New", do: (gui) => (displayNewDeathRegistrationForm(gui, undefined, () => displayDeathRegistrationList(gui))) },
							applications: { label: "Applications", do: (gui) => displayDeathRegistrationList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayDeathRegistartiondvancedSearch(gui) }

						},
						label: "Death Applications"
					},
					"death-amendment": {
						options: {
							applications: { label: "Applications", do: (gui) => displayAmendmentDeathApplicationList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayAmendmentDeathApplicationAdvancedSearch(gui) }

						},
						label: "Amendments"
					},
					"death-registry": {
						options: {
							search: { label: "Records", do: (gui) => displayDeathRecordList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayDeathRecordAdvancedSearch(gui) }
						},
						label: "Death Registry"
					}
				},
				label: "Death"
			},
			"id-database": {
				submenu: {
					"id-cards": {
						options: {
							applications: { label: "Applications", do: (gui) => displayIdCardApplicationList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayIdCardApplicationAdvancedSearch(gui) }
						},
						label: "ID Card Applications"
					},
					other: {
						options: {
							search: { label: "Issued Documents", do: (gui) => displayIdCardList(gui) },
							advancedSearch: { label: "Advanced Search", do: (gui) => displayIdCardAdvancedSearch(gui) }
						}
					}
				},
				label: "ID Cards"
			}
		}),
		...((whoami().roles.includes('ALL') || whoami().roles.includes('DIGITALIZER') || whoami().roles.includes('REGISTRAR')) && {
			"digitalization": {
				submenu: {
					"digitalization": {
						options: {
							"digitalization-forms": { label: "Digitalization Forms", do: (gui) => displayDigitalizationApplicationList(gui) }
						}
					}
				},
				label: "Digitalization"
			}
		}),
		...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
			"crvs-admin": {
				submenu: {
					"occupations": {
						options: {
							list: { label: "Occupations", do: (gui) => displayOccupationList(gui) },
							new: { label: "New", do: (gui) => gui.goTo(displayNewOccupationForm()) },
						},
						label: "Occupations"
					},
					"countries": {
						options: {
							list: { label: "Countries", do: (gui) => displayCountriesList(gui) },
							new: { label: "New", do: (gui) => gui.goTo(displayNewCountryForm(() => displayConfirmationDialog({ displayListFunc: displayCountriesList, message: "New Country submitted." }, gui))) },
						},
						label: "Countries"
					},
					"translations": {
						options: {
							list: { label: "Translations", do: (gui) => displayTranslationsList(gui) },
							new: { label: "New", do: (gui) => displayNewTranslationsForm(gui) },
						},
						label: "Translations"
					},
				},
				label: "Configuration"
			}
		}),
		...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
			"geography": {
				submenu: {
					"province": {
						options: {
							list: { label: "Province List", do: (gui) => displayProvinceList(gui) },
							new: { label: "New", do: (gui) => gui.goTo(displayNewProvinceForm(() => displayConfirmationDialog({ displayListFunc: displayProvinceList, message: "New Province submitted." }, gui))) },
						},
						label: "Province"
					},
					"zone": {
						options: {
							list: { label: "Zone List", do: (gui) => displayZoneList(gui) },
							new: { label: "New", do: (gui) => gui.goTo(displayNewZoneForm(() => displayConfirmationDialog({ displayListFunc: displayZoneList, message: "New Zone submitted." }, gui))) },
						},
						label: "Zone"
					},
					"district": {
						options: {
							list: { label: "District List", do: (gui) => displayDistrictList(gui) },
							new: { label: "New", do: (gui) => gui.goTo(displayNewDistrictForm(() => displayConfirmationDialog({ displayListFunc: displayDistrictList, message: "New District submitted." }, gui))) },
						},
						label: "District"
					},
					"island": {
						options: {
							list: { label: "Island List", do: (gui) => displayIslandList(gui) },
							new: { label: "New", do: (gui) => gui.goTo(displayNewIslandForm(() => displayConfirmationDialog({ displayListFunc: displayIslandList, message: "New Island submitted." }, gui))) },
						},
						label: "Island"
					},
					"location": {
						options: {
							list: { label: "Location List", do: (gui) => displayLocationList(gui) },
							new: { label: "New", do: (gui) => gui.goTo(displayNewLocationForm(() => displayConfirmationDialog({ displayListFunc: displayLocationList, message: "New Location submitted." }, gui))) },
						},
						label: "Location"
					},
				},
				label: "Geography"
			}
		}),
		...((whoami().roles.includes('ALL') || whoami().roles.includes('REGISTRAR')) && {
			"rv4": {
				submenu: {
					"rv4": {
						options: {
							searchFamilyTree: { label: "Family Tree Applications", do: (gui) => displayFamilyTreeRegistrationList(gui) }
						},
						label: "Rv4 Applications"
					}
				},
				label: "Rv4"
			}
		}),
		...((whoami().roles.includes('ALL') || whoami().roles.includes('REGISTRAR')) && {
			"reports": {
				submenu: {
					"report-applications": {
						options: {
							apply: { label: "Narrative Reports", do: (gui) => displayGeneralStatisticsList(gui) }
						},
						label: "General Statistics"
					},
					"actions": {
						options: {
							apply: { label: "Request Narrative Reports", do: (gui) => applyForGeneralStatisticsReport(gui) }
						},
						label: "Actions"
					}
				},
				label: "Reports"
			}
		})

	}
}

export const displayConfirmationDialog = (options, gui) => {
	let data = { title: "Confirmation", message: options.message, onClick: () => gui.goTo(options.displayListFunc(gui)) };
	OPEN_CONFIRMATION_DIALOG.publish(data);
}
