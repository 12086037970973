import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from "lodash";
import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { AlertDialog } from "../../widgets";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { saveMarriageRegistrationForm, updateMarriageRegistrationForm, deleteMarriageRegistrationForm, loadMarriageRegistrationFormData } from "./MarriageRegistrationApi";
import { buildMarriageRegistrationFormEmptyObject, marriageRegistrationFields, newMarriageRegistrationFormForm2Dto } from "../../../../main/js/forms/marriageRegistration/MarriageRegistrationFormCommon";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploader } from "../../widgets/FileUploader";
import { createTableComponent } from "../../widgets/TableComponent";

import { loadUnionMtlbAttachment } from "../../../../main/js/forms/marriageRegistration/MarriageRegistrationFormCommon";
import { FileInput } from "../../widgets/FileInput";
import { ConfirmationDialog } from "../../../../main/js/widgets/ConfirmationDialog";
import { displayMarriageRegistrationList } from "../../lists/marriageRegistration/MarriageRegistrationList";
import { OPEN_CONFIRMATION_DIALOG } from "../../events/Gui";


export class MarriageRegistrationFormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = (id) => {
		deleteMarriageRegistrationForm(id);
		this.state.closeRequested(true);
	}

	render() {
	let NewMarriageRegistrationForm = createFormComponent(this.props.fields);

		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<NewMarriageRegistrationForm ref={this.myRef} key={this.props.key} id={this.props.id} loadData={async () => loadMarriageRegistrationFormData(this.props.id)} onSubmit={this.props.onSubmit} buttons={getButtons} />
            <FileInput label="Attachments" previewUrl={(id) => getPreviewUrl(id)} uploadUrl={getServiceUri() + 'union-mtlb/attachment/' +  this.props.id} updateFileData={(data) => updateFileData(data)} loadData={async () => loadUnionMtlbAttachment(this.props.id)} handleDelete={(id) => handleUnionMtlbAttachmentDelete(id)} handleClick={(id) => handleUnionMtlbAttachmentClick(id)}/>
		</>
		)
	}

}

const handleUnionMtlbAttachmentDelete = (id) => {
	rest.delete('union-mtlb/attachment', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const handleUnionMtlbAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'union-mtlb/attachment' + '/' + id + '/' + token;	
};
const updateFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'union-mtlb/attachment' + '/' + data.id, "PUT", filter);
}
const getPreviewUrl = (id) => {
	return getServiceUri() + 'union-mtlb/attachment' + '/preview/' + id;
}

export const displayNewMarriageRegistrationForm = (gui, formData) => {
	const readNewMarriageRegistrationForm = (onFinish) => (id) => {
		console.log("ONFINISH: ", onFinish);
		let uuid = uuidv4();
		return { 
			uuid, view: () => <MarriageRegistrationFormComponent key={uuid} id={id} onSubmit={(onFinish)?(data) => save(data).then(() => onFinish()):save} fields={marriageRegistrationFields}/>
		}
	}
	let data;
	if (!formData) {
		data = buildMarriageRegistrationFormEmptyObject();
		data.draft = true;
		saveMarriageRegistrationForm(data).then((response) => {
			gui.goTo(readNewMarriageRegistrationForm((options) => displayConfirmationDialog(options, gui)), response)
		});
	}
	else {
		data = _.clone(formData);
		data.draft = true;
		newMarriageRegistrationFormForm2Dto(formData, data)
		if (formData.image) {
			if (!formData.image.isEmpty) {
				let base64Image = formData.image.url;
				fetch(base64Image)
			  .then(res => res.blob())
			  .then(blob => {      
					let faceMimeType = "image/png";
					data.face = blob;
					data.faceMimeType = faceMimeType;			   
					const reader = new FileReader();
					reader.onloadend = () => {
					  	data.image.url = reader.result;
						saveMarriageRegistrationForm(data).then((response) => {
							gui.goTo(readNewMarriageRegistrationForm((options) => displayConfirmationDialog(options, gui)), response)
						});
					};
					reader.readAsDataURL(blob); 
				});
			} else {
				saveMarriageRegistrationForm(data).then((response) => {
					gui.goTo(readNewMarriageRegistrationForm((options) => displayConfirmationDialog(options, gui)), response)
				});
			}
		} else {
			saveMarriageRegistrationForm(data).then((response) => {
					gui.goTo(readNewMarriageRegistrationForm((options) => displayConfirmationDialog(options, gui)), response)
				});
		}
	}
}

const save = async (formData) => {
	formData.draft = false;
	return updateMarriageRegistrationForm(formData);
}

const getButtons = () => {
	return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}

export const displayConfirmationDialog = (options, gui) => {
	let data = {title: "Confirmation", message: "Marriage registration application submitted.", onClick: () => gui.goTo(displayMarriageRegistrationList(gui))};
	OPEN_CONFIRMATION_DIALOG.publish(data);
}
