import React from "react";

import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { MTLB_TYPE_DEATH_REGISTRATION } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import * as Yup from 'yup';
import { formatDate, showNotification } from "../../../../auto/js/utils";
import { Section } from "../../widgets/Section";
import { PersonComponent } from "../../../../auto/js/widgets/PersonComponent";
import { loadPersonData } from "../../utils";
import { formState } from "../../../../auto/js/forms/FormState";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";
import { AdvancedSearchPersonComponent } from "../../widgets/AdvancedSearchPersonComponent";
import { DELETE_ATTACHMENT_EVENT } from "../../../../auto/js/events/Gui";
export const deathRegistrationFields = [
	{ name: "tags", type: "tags", x: 1, y: 1, layout: "col-md-12" },
	{name: "deceasedDetails", type: "custom", x:1, y:2, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "vitalRecordId", type: "custom", x:1, y: 3, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Record"} readOnly={disabled} loadData={loadPersonData}/>},
	{name: "deathDetails", type: "custom", x:1, y:4, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "dateOfDeath", type: "date", x:1, y: 5, layout:"col-md-6", "validation": Yup.date().test(
		'is-after-birthdate',
		'Date of death must be after birthdate',
		function (dateOfDeath) {
		  const birthdate = new Date(formState.getState().birthdate[0], formState.getState().birthdate[1]-1, formState.getState().birthdate[2]);
		  return dateOfDeath > birthdate;
		})},
	{name: "timeOfDeath", type: "time", x:2, y: 5, layout:"col-md-6"},
	{name:"deathPlaceBox", label: "Death Place", components: [
		{name: "deathPlace", type: "custom", x:1, y:7, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"191"}/>},
	], type: "box", x:1, y:7, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
	{name:"burialPlaceBox", label: "Burial Place", components: [
		{name: "burialPlace", type: "custom", x:1, y:7, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"191"}/>},
	], type: "box", x:1, y:8, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
	{name: "deathNotification", type: "checkbox", x:1, y:9, layout:"col-md-12"},
	{name: "deathNotificationFile", type: "file", x:1, y:10, layout:"col-md-12", 
		uploadUrl: (id) => getUploadUrl(id),
		previewUrl: (id) => getPreviewUrl(id),
		loadData: async (id) => loadDeathNotificationAttachment(id), 
		handleDelete:(id) => handleDeathNotificationDelete(id), 
		handleClick: (id) => handleDeathNotificationClick(id),
		display: (data) => {return data.deathNotification},
		updateFileData: (data) => updateFileData(data)
	},	
	{name: "declarantDetails", type: "custom", x:1, y:11, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{name: "declarantId", type: "custom", x:1, y:12, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Declarant"} readOnly={disabled} loadData={loadPersonData}/>},
	{name:"registrationBox", label: "Registration Location", components: [
		{name: "registrationLocation", type: "custom", x:1, y:1, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"191"}/>}
	 ], type: "box", x:2, y:28, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},

	{name: "Mentions", type: "custom", x:1, y:30, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "mentions", type: "text", x:1, y:31, layout:"col-md-12"},
	{name: "Extras", type: "custom", x:1, y:32, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "notes", type: "text", x:1, y:33, layout:"col-md-12"}

];

const getUploadUrl = (id) => {
	return getServiceUri() + 'civil-status-mtlb/death-notification-file' + '/' + id;
}

const getPreviewUrl = (id) => {
	return getServiceUri() + 'civil-status-mtlb/death-notification-file' + '/preview/' + id;
}

const updateFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'civil-status-mtlb/death-notification-file' + '/' + data.id, "PUT", filter);
}

const handleDeathNotificationClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'civil-status-mtlb/death-notification-file' + '/' + id + '/' + token;
};

const handleDeathNotificationDelete = (id) => {
	rest.delete('civil-status-mtlb/death-notification-file', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const loadDeathNotificationAttachment = async (id) => {
	let filter = {and: true};
	filter['civil-status-mtlb-death-notification-file'] = {};
	filter['civil-status-mtlb-death-notification-file']['civilStatusMtlbId'] = id;
	return rest.search('civil-status-mtlb/death-notification-file', filter)
}

export const loadCivilStatusMtlbAttachment = async (id) => {
	let filter = {and: true};
	filter['civil-status-mtlb-attachment'] = {};
	filter['civil-status-mtlb-attachment']['civilStatusMtlbId'] = id;
	return rest.search('civil-status-mtlb/attachment', filter)
}

export const saveDeathRegistrationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	dto.mtlbType = MTLB_TYPE_DEATH_REGISTRATION;
	try {
		return rest.request(getServiceUri() + 'apply/create-civil-status-mtlb', 'POST', dto)
	} catch (err) {
		alert(err);
	}
}

export const loadDeathRegistrationFormData = async (id) => {
	return await rest.read('civil-status-mtlb', id).then(response => {
		let form = response;
		let face = null;
		if (response.face != null) {
			let mimeType = response['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", response.face)
		}
		if (response.timeOfDeath !== null)
			response.timeOfDeath = new Date().setHours(response.timeOfDeath[0], response.timeOfDeath[1]);
		form['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: (face != null)?face: '/public/avatar.png', isEmpty: true};
		let tagFilter = {and: true};
		tagFilter['civil-status-mtlb-tag'] = {civilStatusMtlbId: id};
		
		return rest.search('civil-status-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
			
			return form;
		})
	})
}

export const updatePendingDeathRegistrationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	if (formData.timeOfDeath != null && typeof(formData.timeOfDeath) != 'string') {
		const date = new Date(formData.timeOfDeath)
		let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.timeOfDeath = birthHour + ":" + birthMinute;
	}
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/pending/update', 'POST', dto);;
	} catch (err) {
		alert(err);
	}
}

export const newDeathRegistrationFormForm2Dto = (form, dto) => {
	dto.vitalRecordId = form.id;
	dto.id = null;
	dto.mtlbType = MTLB_TYPE_DEATH_REGISTRATION;
	dto.birthTime = null;
	dto.typeOfAcquisition = null;
	if (dto.otherCitizenshipCsv !== null && dto.otherCitizenshipCsv) {
		dto.otherCitizenshipCsv = null;
	}
	dto.declarantId = null;
}

export const updateRejectedDeathRegistrationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	if (formData.timeOfDeath != null && typeof(formData.timeOfDeath) != 'string') {
		const date = new Date(formData.timeOfDeath)
		let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.timeOfDeath = birthHour + ":" + birthMinute;
	}
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/rejected/update', 'POST', dto);
	} catch (err) {
		alert(err);
	}
}

export const rejectDeathRegistrationForm = async (id) => {
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/pending/reject', 'POST', id);
	} catch (err) {
		alert(err);
	}
}

export const approveReadyDeathRegistrationForm = async (id) => {
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/ready/approve', 'POST', id);
	} catch (err) {
		alert(err);
	}
}

export const rejectReadyDeathRegistrationForm = async (id) => {
	try {
		return rest.request(getServiceUri() + 'civil-status-mtlb/ready/reject', 'POST', id);
	} catch (err) {
		alert(err);
	}
}

export const buildDeathRegistrationFormEmptyObject  = () => {
	const empty = buildEmptyObject(deathRegistrationFields);
	empty['image'] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url:'/public/avatar.png', isEmpty: true};
	return empty;
}

export const updateDeathRegistrationForm = async (formData) => {
	let dto = pojoMetadata['civil-status-mtlb'].form2dto(formData);
	if (!formData.image.isEmpty) {
		let base64Image = formData.image.url;
		let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
		let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
		dto.face = base64;
		dto.faceMimeType = faceMimeType;
	}
	if (formData.birthCountry)
		dto.birthCountry = formData.birthCountry.key;
	if (formData.timeOfDeath != null && typeof(formData.timeOfDeath) != 'string') {
		const date = new Date(formData.timeOfDeath)
		let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.timeOfDeath = birthHour + ":" + birthMinute;
	}
	dto.draft = false;
	try {
		return rest.update('civil-status-mtlb', dto);
	} catch (err) {
		alert(err);
	}
}

export const deleteDeathRegistrationForm = async (id) => {
	try {
		return rest.delete('civil-status-mtlb', id);
	} catch (err) {
		alert(err);
	}
}

export const form2dto = (formData, dto) => {
	dto.mtlbType = MTLB_TYPE_DEATH_REGISTRATION;
	if(formData.dateOfDeath != null && typeof(formData.dateOfDeath) != 'string') {
		let dateOfDeathAsDate = new Date(formData.dateOfDeath);
		dto.dateOfDeath = dateOfDeathAsDate.getFullYear() + "-" + ('0' + (dateOfDeathAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + dateOfDeathAsDate.getDate()).slice(-2);
	}
	if (formData.timeOfDeath != null && typeof(formData.timeOfDeath) != 'string') {
		const date = new Date(formData.timeOfDeath)
		let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.timeOfDeath = birthHour + ":" + birthMinute;
	}
	if (formData.image)
		if (!formData.image.isEmpty) {
			let base64Image = formData.image.url;
			let faceMimeType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
			let base64 = base64Image.substr(base64Image.indexOf(',') + 1);
			dto.face = base64;
			dto.faceMimeType = faceMimeType;
		}
}

export const dto2form = (dto) => {
	if (dto.timeOfDeath !== null) {
		dto.timeOfDeath = new Date().setHours(dto.timeOfDeath[0], dto.timeOfDeath[1]);
	}
	return dto;
}