import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { VITAL_RECORD_ORDER_BY_FIRSTNAME } from "../../../auto/js/metadata/VitalRecordOrderBy";
import { rest } from "../../../auto/js/services";
import React from "react";
import { Separator } from "../forms/CivilRecordFormCommon";
import { GeoDataComponent } from "../../../auto/js/widgets/GeoDataComponent";


export const civilRecordColumns = [
    { title: "image", field: "image", render: rowData => <img src={rowData.image} onError={(e) => { e.target.onerror = null; e.target.src = "/public/avatar.png" }} style={{ width: 40, borderRadius: '50%', height: 40 }} /> },
    { title: "id", field: "id" },
    { title: "fourthname", field: "fourthname" },
    { title: "secondname", field: "secondname" },
    { title: "thirdname", field: "thirdname" },
    { title: "firstname", field: "firstname" },
    { title: "birthdate", field: "birthdate" },
];

export const buildData = async (query) => {
    let filter = query;
    let data;
    filter["vital-record"] = { inactiveList: [false] };
    filter["orderBy"] = VITAL_RECORD_ORDER_BY_FIRSTNAME;
    filter.orderDirection = null;
    filter["filter"] = query.search;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search != '') {
        pojoMetadata["vital-record"].columns.forEach(element => {
            if (element.type == 'text') {
                filter["vital-record"][element.key] = query.search;
            }
        });
        filter["and"] = false;
    }
    return await getCivilRecordData(filter).then(response => {
        data = filterData(response)
        return countData(filter).then((count) => { return { data: data, totalCount: count, page: query.page } })
    });
}

const getCivilRecordData = async (filter) => {
    return await rest.request(getServiceUri() + "vital-record/fuzzy/search", "POST", filter);
}

const countData = async (filter) => {
    return await rest.request(getServiceUri() + "vital-record/count/search-by-active/", "POST", filter);
}

const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let face = null;
        if (row.faceId != null) {
            //    faceUrl = getServiceUri() + "face/image/" + row.faceId;
            let mimeType = "image/png";
            rest.read('face/image', row.faceId).then((response) => {
                face = "data:".concat(mimeType, ";base64,", response);
                row.image = (face != null) ? face : "/public/avatar.png";
            })
        }
        let date = row.birthdate
        if (date !== null)
            row.birthdate = date[2] + '-' + date[1] + '-' + date[0];
        row.id = formatId(row.id.toString())
        newRows.push(row);
    }
    return newRows;
}

function formatId(id) {
    if (id.length < 9) {
        let diff = 9 - id.length
        while (diff !== 0) {
            id = "0" + id;
            diff = diff - 1;
        }
    }
    let newString = id.slice(0, 3) + '-' + id.slice(3);
    let finalForm = newString.slice(0, 7) + '-' + newString.slice(7)
    return finalForm
}

export const getErrorList = () => {
    let list = [];
    for (let i = 1; i < 14; i++) {
        let code = (i < 10) ? "E" + "0" + i : "E" + i
        list.push(code);
    }
    list.push("E20")
    list.push("E21")
    for (let i = 28; i < 32; i++) {
        let code = "E" + i
        list.push(code);
    }
    return list;
}

export const advancedSearchfields = [

    { name: "exactId", type: "number", x: 1, y: 1, layout: "col-md-4" },
    { name: "idSeparator", type: "custom", x: 1, y: 2, layout: "col-md-12", component: (name, disabled) => <Separator /> },
    { name: "caseSensitiveSearch", label: "Case Sensitive Search", type: "checkbox", x: 1, y: 3, layout: "col-md-3" },

    { name: "firstname", type: "text", x: 1, y: 7, layout: "col-md-6" },
    {
        name: "firstnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 7, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    { name: "secondname", type: "text", x: 1, y: 5, layout: "col-md-6" },
    {
        name: "secondnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 5, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    { name: "thirdname", type: "text", x: 1, y: 6, layout: "col-md-6", defaultValue: "startsWith" },
    {
        name: "thirdnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 6, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    { name: "fourthname", type: "text", x: 1, y: 4, layout: "col-md-6" },
    {
        name: "fourthnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 4, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    {
        name: "genderBox", label: "Gender", components: [
            { name: "male", label: "Male", type: "checkbox", x: 1, y: 1, layout: "col-md-3" },
            { name: "female", label: "Female", type: "checkbox", x: 2, y: 1, layout: "col-md-3" },
        ], type: "box", x: 3, y: 8, layout: "col-md-11 ms-2 mt-2"
    },//mx-auto
    {
        name: "birthBox", label: "Birth Date", components: [
            { name: "rangeBirthdate", label: "exactbirthdate", type: "checkbox", x: 1, y: 1, layout: "col-md-12" },
            {
                name: "exactBirthdate", type: "date", x: 1, y: 2, layout: "col-md-6", display: (data) => {
                    return !data.rangeBirthdate;
                }
            },

            {
                name: "birthdateStart", type: "date", x: 1, y: 3, layout: "col-md-6", display: (data) => {
                    return data.rangeBirthdate;
                }
            },
            {
                name: "birthdateEnd", type: "date", x: 2, y: 3, layout: "col-md-6", display: (data) => {
                    return data.rangeBirthdate;
                }
            },
        ], type: "box", x: 1, y: 9, layout: "col-md-11 ms-2 mt-2 pt-1 pb-2"
    },

    {
        name: "birthBox", label: "Birth Place", components: [
            { name: "anyBornOutsideCountry", label: "anyBornOutsideCountry", type: "checkbox", x: 1, y: 1, layout: "col-md-12" },
            {
                name: "birthPlace", type: "custom", x: 1, y: 2, layout: "col-md-6",
                component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} />,
                display: (data) => { return !data.anyBornOutsideCountry }
            },
        ], type: "box", x: 2, y: 10, layout: "col-md-11 ms-2 mt-2 pt-1 pb-2"
    }
];

export const transformAdvancedSearchData = (formData) => {
    const columns = [];

    const addColumn = (name, type, value, condition) => {
        columns.push({
            name,
            type,
            [type === 'date' ? 'dateValue' : (type === 'text' ? 'textValue' : 'longValue')]: value,
            searchType: condition ? condition : null,
        });
    };
    (formData.firstname !== "") && addColumn('firstname', 'text', formData.firstname, formData.firstnameCondition);

    (formData.secondname !== "") && addColumn('secondname', 'text', formData.secondname, formData.secondnameCondition);

    (formData.thirdname !== "") && addColumn('thirdname', 'text', formData.thirdname, formData.thirdnameCondition);

    (formData.fourthname !== "") && addColumn('fourthname', 'text', formData.fourthname, formData.fourthnameCondition);

    (formData.exactId !== "") && addColumn('id', 'number', formData.exactId, null);
    if (formData.anyBornOutsideCountry)
        addColumn('birthPlace', 'text', '191', 'notStartsWith');
    else
        (formData.birthPlace) && addColumn('birthPlace', 'text', formData.birthPlace, formData.birthPlace ? "startsWith" : null);
    const birthdateRange = formData.birthdateStart && formData.birthdateEnd
        ? {
            min: formData.birthdateStart ? formData.birthdateStart : null,
            max: formData.birthdateEnd ? formData.birthdateEnd : null,
        }
        : null;

    (birthdateRange != null || formData.exactBirthdate != null) && addColumn('birthdate', 'date', formData.exactBirthdate, null);
    if ((birthdateRange != null || formData.exactBirthdate != null))
        columns[columns.length - 1].dateRange = birthdateRange;

    const gender = [];
    formData.male ? gender.push("1") : null;
    formData.female ? gender.push("2") : null;
    if (gender.length)
        columns.push({
            name: 'gender',
            type: 'enum',
            enumValues: gender,
        });

    return { columns, caseSensitiveSearch: formData.caseSensitiveSearch };
};