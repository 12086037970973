import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_ADVANCED_SEARCH, OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { getMtlbStatus, MtlbStatus, MTLB_STATUS_ARCHIVED, MTLB_STATUS_MANUAL_CHECK, MTLB_STATUS_READY_FOR_APPROVAL, MTLB_STATUS_REJECTED, MTLB_STATUS_AFIS_1N_MATCH_FOUND } from "../../../../auto/js/metadata/MtlbStatus";
import { getMtlbType, MtlbType } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { birthRegistrationListColumns, getData, getMtlbTypeList, getOrderBy, countData, filterData, getErrorList, transformAdvancedSearchData, advancedSearchfields } from "../../../../main/js/lists/birthRegistration/BirthRegistrationListCommon"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import { displayReadApprovedBirthRegistrationForm } from "../../../../auto/js/forms/birthRegistration/ApprovedBirthRegistrationForm";
import { displayReadPendingBirthRegistrationForm } from "../../forms/birthRegistration/PendingBirthRegistrationForm";
import { displayReadReadyBirthRegistrationForm } from "../../forms/birthRegistration/ReadyBirthRegistrationForm";
import { displayReadRejectedBirthRegistrationForm } from "../../forms/birthRegistration/RejectedBirthRegistrationForm";
import { displayAdjudicationForm } from "../../forms/birthRegistration/AdjudicationCivilStatusInclusionForm";
import { OPEN_CONFIRMATION_DIALOG } from '../../events/Gui';

const ADJUDICATION_ERROR_TAG_TYPE = "ERROR/ADJUDICATION";

const BirthRegistrationListPage = ({uuid, gui, searchData}) => {
    const [approved, setApproved] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [ready, setReady] = useState(false);
    const [pending, setPending] = useState(false);
    const [matchFound, setMatchFound] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const[selectAllStatus, setSelectAllStatus] = useState(false);
    const[selectAllError, setSelectAllError] = useState(false);
    const [advancedSearchData, setAdvancedSearchData] = useState(searchData);

    let BirthRegistrationTable = createTableComponent(birthRegistrationListColumns);

    const onFacetChange = (key, value) => {
        switch(key) {
            case ("approved"):
                setApproved(value.target.checked);
                break;
            case ("pending"):
                setPending(value.target.checked);
                break;
            case ("rejected"):
                setRejected(value.target.checked);
                break;
            case ("ready"):
                setReady(value.target.checked);
                break;
            case ("matchFound"):
                setMatchFound(value.target.checked);
                break;
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
            	setApproved(value.target.checked);
            	setPending(value.target.checked);
            	setRejected(value.target.checked);
            	setReady(value.target.checked);
                setMatchFound(value.target.checked);
				break;
        }
    }
    if (advancedSearchData != searchData)
		setAdvancedSearchData(searchData);

    const buildData = async (query) => {
        let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
        let filter = query;
        let data;
        let mtlbStatusList = [];
        let tags =[];
        if (approved)
            mtlbStatusList.push(MTLB_STATUS_ARCHIVED);
        if (pending)
            mtlbStatusList.push(MTLB_STATUS_MANUAL_CHECK);
        if (rejected)
            mtlbStatusList.push(MTLB_STATUS_REJECTED);
        if (ready)
            mtlbStatusList.push(MTLB_STATUS_READY_FOR_APPROVAL);
        if (matchFound)
            mtlbStatusList.push(MTLB_STATUS_AFIS_1N_MATCH_FOUND);
        if (!approved && !pending && !rejected && !ready && !matchFound)
            mtlbStatusList = [MTLB_STATUS_MANUAL_CHECK, MTLB_STATUS_REJECTED, MTLB_STATUS_READY_FOR_APPROVAL, MTLB_STATUS_AFIS_1N_MATCH_FOUND]
        errorList.forEach(element => {
            tags.push( {content: element} )
        });
        filter["civil-status-mtlb"] = {mtlbTypeList: getMtlbTypeList(), mtlbStatusList: mtlbStatusList, civilStatusMtlbTags: tags};
        filter['and'] = true;
        filter["orderBy"] = getOrderBy();
        if (advancedSearchData)
            filter["query"] = advancedSearchData;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["civil-status-mtlb"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["civil-status-mtlb"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            data = filterData(response, token)
            return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
    }
    
    const onErrorFacetChange = (key, value) => {
        let list = _.clone(errorList);
        if (key === "selectAllError") {
            if (value.target.checked)
                list = selectAllErrors();
            else
                list = [];
            setErrorList(list);
            setSelectAllError(value.target.checked)
        }
        if (value.target.checked && !list.includes(key)) {
            list.push(key);
            setErrorList(list);
        }
        else if (!value.target.checked && list.includes(key)) {
            let index = list.indexOf(key);
            list.splice(index, 1);
            setErrorList(list);
        }
    }
    
    const getErrorCodeFacets = () => {
      let list = [{key: "selectAllError", value: selectAllError, label: t`select/deselect all`, separator: true}];
        getErrorList().forEach(error => {
            list.push({key: error, value: errorList.includes(error), label: t(error)});

        });
        return list;
    }
    
    
    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>
            <Facets title={t`Application Status`} facets={[{key: "selectAllStatus", value: selectAllStatus, separator: true}, { key: "approved", value: approved }, { key: "pending", value: pending }, { key: "rejected", value: rejected }, { key: "ready", value: ready }]} facetsComponents={(key, value) => buildFacets(key, value, t(key), onFacetChange)} />
            <Facets title={t`Errors`} facets={getErrorCodeFacets()} facetsComponents={(key, value) => buildFacets(key, value, t(key), onErrorFacetChange)} />
            <BirthRegistrationTable name={'Birth Registration'} key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions(gui)}/>
    	</div>
    )


}

export const displayBirthRegistrationList = (gui, advancedSearchData) => {  
    const readBirthRegistrationList = () => {
    let uuid = uuidv4();
		return {
			uuid, view: () => <BirthRegistrationListPage gui={gui} key={uuid} searchData={advancedSearchData} />
		};
	}
	gui.goTo(readBirthRegistrationList)
}

export const displayBirthRegistrationAdvancedSearch = (gui) => {
    let advancedSearchFilters = {fields: advancedSearchfields, name: t`Birth Registration`, onSubmit: (filter) => onAdvancedSearchSubmit(filter, gui)};
	OPEN_ADVANCED_SEARCH.publish(advancedSearchFilters);
}

const onAdvancedSearchSubmit = (filter, gui) => {
    displayBirthRegistrationList(gui, transformAdvancedSearchData(filter));
}

const selectAllErrors = () => {
    let list = ["selectAllError"];
    getErrorList().forEach(error => {
            list.push(error);
    });
    return list;
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    switch(rowData.mtlbStatus) {
                         case (MTLB_STATUS_ARCHIVED):
                        	gui.goTo(displayReadApprovedBirthRegistrationForm((options) => displayConfirmationDialog(options, gui)), rowData.id, null);
                            break;
                        case (MTLB_STATUS_MANUAL_CHECK):
                            gui.goTo(displayReadPendingBirthRegistrationForm((options) => displayConfirmationDialog(options, gui)), rowData.id, null);
                            break;
                        case (MTLB_STATUS_READY_FOR_APPROVAL):
                            gui.goTo(displayReadReadyBirthRegistrationForm((options) => displayConfirmationDialog(options, gui)), rowData.id, null);
                            break;
                        case (MTLB_STATUS_REJECTED):
                            gui.goTo(displayReadRejectedBirthRegistrationForm((options) => displayConfirmationDialog(options, gui)), rowData.id, null);
                            break;
                        case (MTLB_STATUS_AFIS_1N_MATCH_FOUND):                     
                                getAdjudicationTag(rowData.id).then(tags => {
                                    let matchJson = JSON.parse(tags[0].content);
                                    gui.goTo(displayAdjudicationForm((options) => displayConfirmationDialog(options, gui)), {candidateId: rowData.id, matchId: matchJson.id, matchType: matchJson.type, message: t`Fingerprints match another stored record`}, null);
                                })
                            break;
                    }
                }
        }
    )
    return actions;   
}
const getAdjudicationTag = async (id) => {
    let tagFilter = {and: true};
    tagFilter['civil-status-mtlb-tag'] = {civilStatusMtlbId: id, type: ADJUDICATION_ERROR_TAG_TYPE};
    return rest.search('civil-status-mtlb-tag', tagFilter);
}

export const displayConfirmationDialog = (options, gui) => {
	let data = {title: "Confirmation", message: options.message, onClick: () => gui.goTo(displayBirthRegistrationList(gui))};
	OPEN_CONFIRMATION_DIALOG.publish(data);
}