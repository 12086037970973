import * as React from 'react';
import { useField } from "formik";
import { Observable } from '../../../auto/js/events/Observable';
import { geoMetadataLoader } from '../../../auto/js/metadata/GeoMetadataLoader';
import { t } from '../../../auto/js/services';
import { SimpleAutoCompleteInput } from '../../../auto/js/widgets/SimpleAutoCompleteInput';

const countryObservable = new Observable();
const provinceObservable = new Observable();
const resetProvinceEvent = new Observable();
const zoneObservable = new Observable();
const resetZoneEvent = new Observable();
const districtObservable = new Observable();
const resetDistrictEvent = new Observable();
const islandObservable = new Observable();
const resetIslandEvent = new Observable();
const resetLocationEvent = new Observable();

let resetList =[resetProvinceEvent,resetZoneEvent,resetDistrictEvent,resetIslandEvent,resetLocationEvent,];
export function AddressComponent({ ...props }) {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [hideProvince, setHideProvince] = React.useState(false);
    const [hideZone, setHideZone] = React.useState(true);
    const [hideDistrict, setHideDistrict] = React.useState(true);
    const [hideIsland, setHideIsland] = React.useState(true);
    const [hideLocation, setHideLocation] = React.useState(true);

    let countryValue;
    const handleCountryChange = (value) => {
        if (value.key) {
            countryObservable.publish(geoMetadataLoader.getChilds(value.key))
            if (Object.keys(geoMetadataLoader.getChilds(value.key)).length)
                setHideProvince(false);
            else
                setHideProvince(true);
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
        else {
            countryObservable.publish([])
            setHideProvince(true);
        }
        resetFieldsFromIndex(0);
    }
    let provinceValue;
    const handleProvinceChange = (value) => {
        if (value.key) {
            provinceObservable.publish(geoMetadataLoader.getChilds(value.key))
            if (Object.keys(geoMetadataLoader.getChilds(value.key)).length)
                setHideZone(false);
            else
                setHideZone(true);
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
        else {
            provinceObservable.publish([])
            setHideZone(true);
        }
        resetFieldsFromIndex(1);
    }
    let zoneValue;
    const handleZoneChange = (value) => {
        if (value.key) {
            zoneObservable.publish(geoMetadataLoader.getChilds(value.key))
            if (Object.keys(geoMetadataLoader.getChilds(value.key)).length)
                setHideDistrict(false);
            else
                setHideDistrict(true);
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
        else {
            zoneObservable.publish([])
            setHideDistrict(true);
        }
        resetFieldsFromIndex(2);
    }
    let districtValue;
    const handleDistrictChange = (value) => {
        if (value.key) {
            districtObservable.publish(geoMetadataLoader.getChilds(value.key))
            if (Object.keys(geoMetadataLoader.getChilds(value.key)).length)
                setHideIsland(false);
            else
                setHideIsland(true);
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
        else {
            districtObservable.publish([])
            setHideIsland(true);
        }
        resetFieldsFromIndex(3);
    }
    let islandValue;
    const handleIslandChange = (value) => {
        if (value.key) {
            islandObservable.publish(geoMetadataLoader.getChilds(value.key))
            if (Object.keys(geoMetadataLoader.getChilds(value.key)).length)
                setHideLocation(false);
            else
                setHideLocation(true);
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
        else {
            islandObservable.publish([])
            setHideLocation(true);
        }
        resetFieldsFromIndex(4);
    }
    let locationValue;
    const handleLocationChange = (value) => {
        if (value.key) {
            setValue(geoMetadataLoader.getAreaId(value.key));
        }
    }
    
    const showData = (value) => {
	    let addressComponents = value.split(".")
	    if (addressComponents[0]) {
	        let country = geoMetadataLoader.getArea(addressComponents[0]);
	        countryValue = { key: country.id, value: country.name };
	        countryObservable.publish(geoMetadataLoader.getGeoLevel(country.id, 1))
	    }
	    if (addressComponents[1]) {
	        let province = geoMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1]);
	        provinceValue = { key: province.id, value: province.name };
	        provinceObservable.publish(geoMetadataLoader.getGeoLevel(province.id, 2))
	    }
	    if (addressComponents[2]) {
	        let zone = geoMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2]);
	        zoneValue = { key: zone.id, value: zone.name };
	        zoneObservable.publish(geoMetadataLoader.getGeoLevel(zone.id, 3))
	    }
	    if (addressComponents[3]) {
	        let district = geoMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3]);
	        districtValue = { key: district.id, value: district.name };
	        districtObservable.publish(geoMetadataLoader.getGeoLevel(district.id, 4))
	    }
	    if (addressComponents[4]) {
	        let island = geoMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3] + "." + addressComponents[4]);
	        islandValue = { key: island.id, value: island.name };
	        islandObservable.publish(geoMetadataLoader.getGeoLevel(island.id, 5))
	    }
	    if (addressComponents[5]) {
	        let location = geoMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2] + "." + addressComponents[3] + "." + addressComponents[4] + "." + addressComponents[5]);
	        locationValue = { key: location.id, value: location.name };
	    }
	}
    
    if (value != null)
        showData(value);
    let style = (props.linear)?"row":"";
    return (
        <>
            <div class="mainform">
                <section class={"fullwidth " + style}>
                    	<SimpleAutoCompleteInput layout={(props.linear)?"col-md-2":""} name={"country"} options={() => geoMetadataLoader.getRootNodes()} label={t`Country`} handleChange={(data) => handleCountryChange(data)}  defaultValue={countryValue} disabled={props.disabled}/>
                    	{(!hideProvince || provinceValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-2":""} name={"province"} options={() => ""} label={t`Province`} handleChange={(data) => handleProvinceChange(data)} observable={countryObservable} reset={resetProvinceEvent} defaultValue={provinceValue} disabled={props.disabled}/>}
                    	{(!hideZone || zoneValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-2":""} name={"zone"} options={() => ""} label={t`Zone`} handleChange={(data) => handleZoneChange(data)} observable={provinceObservable} reset={resetZoneEvent} defaultValue={zoneValue} disabled={props.disabled}/>}
                    	{(!hideDistrict || districtValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-2":""} name={"district"} options={() => ""} label={t`District`} handleChange={(data) => handleDistrictChange(data)} observable={zoneObservable} reset={resetDistrictEvent} defaultValue={districtValue} disabled={props.disabled}/>}
                    	{(!hideIsland || islandValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-2":""} name={"island"} options={() => ""} label={t`Island`} handleChange={(data) => handleIslandChange(data)} observable={districtObservable} reset={resetIslandEvent} defaultValue={islandValue} disabled={props.disabled}/>}
                    	{(!hideLocation || locationValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-2":""} name={"location"} options={() => ""} label={t`Location`} handleChange={(data) => handleLocationChange(data)} observable={islandObservable} reset={resetLocationEvent} defaultValue={locationValue} disabled={props.disabled}/>}
                </section>
            </div>
        </>
    )
}


const resetFieldsFromIndex = (index) => {
    for (let i = index; i < resetList.length; i++)
        resetList[i].publish()
}