import { rest } from "../../../auto/js/services/RestClient";
import { LOCATIONS_ORDER_BY_TYPE } from "../../../auto/js/metadata/LocationsOrderBy";

class GeoMetadataLoader {

constructor() {
	if (!!GeoMetadataLoader.instance) {
		return GeoMetadataLoader.instance;
}
GeoMetadataLoader.instance = this;
this.areas= {};
this.children = {};
this.locationsdata = [];
this.maxCountryAreaId = 0;
return this;
}

	load = async () => {
		let filter = {};
		filter['locations'] = {};
		filter.orderBy = LOCATIONS_ORDER_BY_TYPE;
		this.rootAreaIdList = [];
		return rest.search('locations', filter)
			.then(response => {
				response.forEach(element => {
					this.areas[element.areaId] = { "name": element.name, "id": element.id };
					if (element.type < 7 ) {
						this.children[element.areaId] = [];
					}
					if (element.type == 1) {
						this.rootAreaIdList.push(element.areaId);
						if (parseInt(element.areaId) > this.maxCountryAreaId)
							this.maxCountryAreaId = parseInt(element.areaId)
					}
					if (!this.isRootNode(element.areaId)) {
						let parentareaid = this.getParentAreaId(element.areaId);
						this.children[parentareaid]? this.children[parentareaid].push(element.areaId) :
						this.children[parentareaid] = [element.areaId];
					}
				});
			})
			.then(()=> {
			this.locationsdata = [];
			this.preparelocationsdata();
			});
	}

	preparelocationsdata = () => {
		var eldetails = [];
		const getLocationsFlat = (ellist, eldetail) => {
			ellist.forEach(el => {
			var elname = eldetail + "#" + this.areas[el].name + "::" + this.areas[el].id;
			if (Array.isArray(this.children[el]) && (this.children[el].length)) {
				getLocationsFlat(this.children[el], elname);
			}
			else {
				eldetails.push({"Hierarchy":elname, "Areaid": el});
			}
			});
			return eldetails;
		}
		this.rootAreaIdList.forEach(element => {
			if (this.children[element].length)
				eldetails = getLocationsFlat(this.children[element],
					this.areas[element].name + "::" + this.areas[element].id)
		});
		
		eldetails.forEach(el => {
			const ArrayData = el.Hierarchy.split("#");
			this.locationsdata.push({
			    "country": ArrayData[0]?.split("::")[0],
			    "countryId": ArrayData[0]?.split("::")[1],
			    "province": ArrayData[1]?.split("::")[0],
			    "provinceId": ArrayData[1]?.split("::")[1],
			    "zone": ArrayData[2]?.split("::")[0],
			    "zoneId": ArrayData[2]?.split("::")[1],
			    "district": ArrayData[3]?.split("::")[0],
			    "districtId": ArrayData[3]?.split("::")[1],
			    "island": ArrayData[4]?.split("::")[0],
			    "islandId": ArrayData[4]?.split("::")[1],
			    "location": ArrayData[5]?.split("::")[0],
			    "locationId": ArrayData[5]?.split("::")[1],
			    "areaId": el.Areaid
			})
		});
	}

	getParentAreaId = (areaId) => {
		return areaId.substring(0, areaId.lastIndexOf("."))
	}

	isRootNode = (areaId) => {
		return areaId.lastIndexOf(".") === -1;
	}

	getRootNodes = () => {
  		let areaids = Object.keys(this.areas).filter(key => key.lastIndexOf(".") === -1);
	    let result = Object.fromEntries(areaids.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result;
	}

	getChilds = (id) => {
		let areaid = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let result = Object.fromEntries(this.children[areaid].map(x => [this.areas[x].id, this.areas[x].name]));
		return result;
	}
	
    getAreaId = (id) => {
        return Object.keys(this.areas).find(key => this.areas[key].id == id);
    }
    
    getChildsByAreaId = (areaId) => {
		if (this.children[areaId])
			return Object.fromEntries(this.children[areaId].map(x => [this.areas[x].id, this.areas[x].name]));
		else
			return []
	}
    
    getArea = (areaId) => {
        return this.areas[areaId];
    }
    
    getAreaLevelName = (areaId) => {
		let areaComponents = areaId.split(".");
		switch (areaComponents.length) {
			case (1):
				return "country";
			case (2):
				return "province";
			case (3):
				return "zone";
			case (4):
				return "district";
			case (5):
				return "island";
			case (6):
				return "location";
		}
	}
    
    getGeoLevel = (id, level) => {
		let areaId = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let areaIds = Object.keys(this.areas).filter(key => key.split(".").length === level + 1 && key.startsWith(areaId))
		let result = Object.fromEntries(areaIds.map(x => [this.areas[x].id, this.areas[x].name]));
	    return result; 
	}
	
	getNewCountryAreaId = () => {
		this.maxCountryAreaId = this.maxCountryAreaId + 1;
		return this.maxCountryAreaId;
	}

	incrementLocationAreaIdFromParentId = (id) => {
		let areaid = Object.keys(this.areas).find(key => this.areas[key].id == id);
		let biggerAreaId = Math.max(...this.children[areaid].map(x => x.split(".").slice(-1)[0]));
		let nextAreaId = `${areaid}.${biggerAreaId + 1}`;
		return nextAreaId;	
	}

}

export const geoMetadataLoader = new GeoMetadataLoader();
