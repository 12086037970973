import { formState } from "../../../auto/js/forms/FormState";
import { displayReadReadyBirthRegistrationForm } from "../../../auto/js/forms/birthRegistration/ReadyBirthRegistrationForm";
import { Task } from "./Task";

export class ApproveApplicationTask extends Task {
    constructor(id, name, gui) {
        super(id, name, gui);
    }
    
    start = (onFinishCallback) => {
        this.onFinishCallback = onFinishCallback;
        this.gui.goTo(displayReadReadyBirthRegistrationForm(this.complete), formState.getState().civilStatusMtlbId, null)
    }
}