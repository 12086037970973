import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { AlertDialog } from "../../widgets";
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { getServiceUri } from "../../metadata";
import { showNotification } from "../../utils";
import { approveReadyAmendmentUnionApplicationForm, rejectReadyAmendmentUnionApplicationForm, loadAmendmentUnionApplicationFormData } from "./AmendmentUnionApplicationApi";
import { amendmentUnionApplicationFields } from "../../../../main/js/forms/amendmentUnionApplication/AmendmentUnionApplicationFormCommon.jsx";

import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AttachmentsArea } from "../../widgets/Attachments";
import { Section } from "../../../../auto/js/widgets/Section";

import { createTableComponent } from "../../widgets/TableComponent";
import { loadUnionMtlbAttachment } from "../../../../main/js/forms/amendmentUnionApplication/AmendmentUnionApplicationFormCommon.jsx";

let AmendmentUnionApplicationForm = createFormComponent(amendmentUnionApplicationFields);

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<AmendmentUnionApplicationForm ref={this.myRef} key={this.props.key} loadData={async () => loadFormData(this.props.id)} readOnly onSubmit={(this.props.onFinish)?() => approve(this.props.id).then(() => this.props.onFinish({message: "Union amendment application approved."})):() => approve(this.props.id)} id={this.props.id} buttons={() => getButtons(this.props.id, this.props.onFinish)} />
            <AttachmentsArea label="Attachments" previewUrl={(id) => getPreviewUrl(id)} loadData={async () => loadUnionMtlbAttachment(this.props.id)} handleClick={(id) => handleUnionMtlbAttachmentClick(id)} readOnly/>
		</>
		)
	}

}

const handleUnionMtlbAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'union-mtlb/attachment' + '/' + id + '/' + token;
};
const getPreviewUrl = (id) => {
	return getServiceUri() + 'union-mtlb/attachment' + '/preview/' + id;
}

export const displayReadReadyAmendmentUnionApplicationForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} id={id} onFinish={onFinish}/>
	};
}

const approve = async (id) => {
	return approveReadyAmendmentUnionApplicationForm(id);
;
}

const reject = async (id) => {
    return rejectReadyAmendmentUnionApplicationForm(id);
;
}

const loadFormData = async (id) => {
	return await loadAmendmentUnionApplicationFormData(id);
}

const getButtons = (id, onFinish) => {
	return (
    <>
	<div className="row">
		<div className="col-md-6">
        	<button style={{ minWidth: '5rem' }} type="submit" className={'reject-button'}> {t`Approve`} </button>
		</div>
		<div className="col-md-6">
        	<button style={{ minWidth: '5rem' }} type="button" className={'reject-button'} onClick={(onFinish)?() => reject(id).then(() => onFinish({message: "Union amendment application rejected."})):() => reject(id)}> {t`Reject`} </button>
		</div>
	</div>
    </>
    )
}
