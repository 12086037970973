import { readCivilRecordForm } from "../../../auto/js/forms/CivilRecordForm";
import { formState } from "../../../auto/js/forms/FormState";
import { loadBirthRegistrationFormData } from "../../../auto/js/forms/birthRegistration/BirthRegistrationApi";
import { Task } from "./Task";

export class PrintCertificateTask extends Task {
    constructor(id, name, gui) {
        super(id, name, gui);
    }
    
    start = (onFinishCallback) => {
        this.onFinishCallback = onFinishCallback;
		loadBirthRegistrationFormData(formState.getState().civilStatusMtlbId).then((response) => {
            this.gui.goTo(readCivilRecordForm(this.complete), response.vitalRecordId, null)
        })
    }
}