import React, { useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { buildEmptyObject, createFormComponent } from "../../../auto/js/widgets";
import { DraggableFilterDialog } from "./DraggableFilterComponentUpgrade";
import { t } from "../../../auto/js/services";
import { OPEN_CONFIRMATION_DIALOG } from "../../../auto/js/events/Gui";
import { useConstructor } from "../../../auto/js/utils";
import { AlertDialog } from "../../../auto/js/widgets";


export const ConfirmationDialog = (props) => {
    const [data, setData] = useState(props.data)
    const [openConfirmation, setOpenConfirmation] = useState(false);

    useConstructor(() => {
        OPEN_CONFIRMATION_DIALOG.subscribe((data) => {
            openConfirmationDialog(data)
        })
    });

    const buildDialogData = async () => {
        return data;
    }

    const openConfirmationDialog = (data) => {
        setData(data)
        setOpenConfirmation(true)
    }

    const closeConfirmation = () => {
        setOpenConfirmation(false)
    }

    const agree = () => {
        closeConfirmation();
        data.onClick();
    }

    return (
        <>
            {<AlertDialog confirmMessage={data?data.message:""} open={openConfirmation} title={data?data.title:""} onClose={closeConfirmation} agree={agree} noCancel={true} />}
        </>
    )
}

const getButtons = () => {
    return <button style={{ minWidth: '5rem', position: 'fixed', right: '20px', top: '5px' }} type="button" onClick={agree}> {t`Find`} </button>
}