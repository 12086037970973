import React from "react";
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";
import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { AlertDialog } from "../../widgets";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { saveBirthRegistrationForm, updateBirthRegistrationForm, deleteBirthRegistrationForm, loadBirthRegistrationFormData } from "./BirthRegistrationApi";
import { buildBirthRegistrationFormEmptyObject, birthRegistrationFields, newBirthRegistrationFormForm2Dto } from "../../../../main/js/forms/birthRegistration/BirthRegistrationFormCommon";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploader } from "../../widgets/FileUploader";
import { createTableComponent } from "../../widgets/TableComponent";

import { addressFields, buildAddressData, getAddressEditables } from "../../../../auto/js/lists/AddressList";
import { emailFields, buildEmailData, getEmailEditables } from "../../../../auto/js/lists/EmailList";
import { phoneNumberFields, buildPhoneNumberData, getPhoneNumberEditables } from "../../../../auto/js/lists/PhoneNumberList";
import { loadCivilStatusMtlbAttachment } from "../../../../main/js/forms/birthRegistration/BirthRegistrationFormCommon";
import { Section } from "../../../../main/js/widgets/Section";
import { FileInput } from "../../../../main/js/widgets/FileInput";

export class BirthRegistrationFormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
	}

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({ closeRequested: closeMe })
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({ closeRequested: undefined });
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = (id) => {
		deleteBirthRegistrationForm(id);
		this.state.closeRequested(true);
	}

	render() {
		let NewBirthRegistrationForm = createFormComponent(this.props.fields);
		return (
			<>
				<AlertDialog
					title={t`Save your changes ?`}
					open={(this.state.closeRequested && this.isDirty()) ? true : false}
					handleClose={this.handleDialogCancel}
					noAgree={true}
					save={this.handleSave}
					dontSave={() => this.handleDontSave(this.props.id)}
				/>
				<NewBirthRegistrationForm ref={this.myRef} key={this.props.key} id={this.props.id} loadData={async () => loadBirthRegistrationFormData(this.props.id)} onSubmit={this.props.onSubmit} buttons={getButtons} />
				<FileInput label={t`Attachments`} previewUrl={(id) => getPreviewUrl(id)} uploadUrl={getServiceUri() + 'civil-status-mtlb/attachment/' + this.props.id} updateFileData={(data) => updateFileData(data)} loadData={async () => loadCivilStatusMtlbAttachment(this.props.id)} handleDelete={(id) => handleCivilStatusMtlbAttachmentDelete(id)} handleClick={(id) => handleCivilStatusMtlbAttachmentClick(id)} />
			</>
		)
	}

}

const handleCivilStatusMtlbAttachmentDelete = (id) => {
	rest.delete('civil-status-mtlb/attachment', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const getPreviewUrl = (id) => {
	return getServiceUri() + 'civil-status-mtlb/attachment' + '/preview/' + id;
}

const handleCivilStatusMtlbAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'civil-status-mtlb/attachment' + '/' + id + '/' + token;
};

const updateFileData = (data) => {
	let filter = { name: data.fileName, description: data.description };
	rest.request(getServiceUri() + 'civil-status-mtlb/attachment' + '/' + data.id, "PUT", filter);
}

export const readNewBirthRegistrationForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <BirthRegistrationFormComponent key={uuid} id={id} onSubmit={(onFinish) ? (data) => save(data).then(() => onFinish()) : save} fields={birthRegistrationFields} />
	}
}

export const displayNewBirthRegistrationForm = (gui, formData) => {
	let data;
	if (!formData) {
		data = buildBirthRegistrationFormEmptyObject();
		data.draft = true;
		saveBirthRegistrationForm(data).then((response) => {
			gui.goTo(readNewBirthRegistrationForm(), response)
		});
	}
	else {
		data = _.clone(formData);
		data.draft = true;
		newBirthRegistrationFormForm2Dto(formData, data)
		if (formData.image) {
			if (!formData.image.isEmpty) {
				let base64Image = formData.image.url;
				fetch(base64Image)
					.then(res => res.blob())
					.then(blob => {
						let faceMimeType = "image/png";
						data.face = blob;
						data.faceMimeType = faceMimeType;
						const reader = new FileReader();
						reader.onloadend = () => {
							data.image.url = reader.result;
							saveBirthRegistrationForm(data).then((response) => {
								gui.goTo(readNewBirthRegistrationForm(), response)
							});
						};
						reader.readAsDataURL(blob);
					});
			} else {
				saveBirthRegistrationForm(data).then((response) => {
					gui.goTo(readNewBirthRegistrationForm(), response)
				});
			}
		} else {
			saveBirthRegistrationForm(data).then((response) => {
				gui.goTo(readNewBirthRegistrationForm(), response)
			});
		}
	}
}

const save = async (formData) => {
	formData.draft = false;
	return updateBirthRegistrationForm(formData);
}

const getButtons = () => {
	return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}
