import React, {useState} from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Facets from "../widgets/Facets";
import { Checkbox, Divider, FormControlLabel } from "@mui/material";

import { OPEN_ADVANCED_SEARCH, OPEN_VIEW_EVENT } from "../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { displayReadDeathRecordForm } from "../forms/DeathRecordForm";
import { DEATH_RECORD_ORDER_BY_FIRSTNAME } from "../../../auto/js/metadata/DeathRecordOrderBy";
import { getErrorList, transformAdvancedSearchData, advancedSearchfields } from "../../../main/js/lists/DeathRecordListCommon";
import { AdvancedSearchComponent } from "../../../main/js/widgets/AdvancedSearchComponent";
import { OPEN_CONFIRMATION_DIALOG } from "../events/Gui";

const fields = [
    {title:"image", field:"image",  render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "id", field: "vitalRecordId"},
	{title: "fourthname", field: "fourthname"},
    {title: "secondname", field: "secondname"},
    {title: "thirdname", field: "thirdname"},
	{title: "firstname", field: "firstname"},
    {title: "dateOfDeath", field: "dateOfDeath"},
];

const DeathRecordListPage = ({uuid, gui, searchData}) => {
	const[selectAllError, setSelectAllError] = useState(false);
	const [errorList, setErrorList] = useState([]);
	const [advancedSearchData, setAdvancedSearchData] = useState(searchData);
	
    let DeathRecordsList = createTableComponent(fields);
    
    const onErrorFacetChange = (key, value) => {
	    let list = _.clone(errorList);
	    if (key === "selectAllError") {
	        if (value.target.checked)
	            list = selectAllErrors();
	        else
	            list = [];
	        setErrorList(list);
	        setSelectAllError(value.target.checked)
	    }
	    else if (value.target.checked && !list.includes(key)) {
	        list.push(key);
	        setErrorList(list);
	    }
	    else if (!value.target.checked && list.includes(key)) {
	        let index = list.indexOf(key);
	        list.splice(index, 1);
	        setErrorList(list);
	    }
	}

	if (advancedSearchData != searchData)
		setAdvancedSearchData(searchData);
	
	const buildData = async (query) => {
        let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	    let filter = query;
	    let data;
	    filter["death-record-document"] = {};
	    filter["orderBy"] = DEATH_RECORD_ORDER_BY_FIRSTNAME;
	    if (advancedSearchData)
            filter["query"] = advancedSearchData;
	    filter.orderDirection = null;
	    filter.offset = query.page * query.pageSize;
	    let deathRecordTags =[];
	    
	     errorList.forEach(element => {
	        deathRecordTags.push(element);
	    });
		filter["death-record-document"]["contentList"] =  deathRecordTags;
	    if (query.search && query.search!='') {
	        pojoMetadata["death-record"].columns.forEach(element => {
	            if(element.type=='text'){
	                filter["death-record-document"][element.key]= query.search;
	            }
	        });
	        filter["and"] = false;
			filter.fuzziness = "AUTO";
	    }
	    return await getCivilRecordData(filter).then(response => {
	        data = filterData(response, token)
	        return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
	    });
	}
	
	const getCivilRecordData = async (filter) => {
    	let searchEndpoint = (advancedSearchData)?"death-record/advanced-search":"death-record/search-document/";
		return await rest.request(getServiceUri() + searchEndpoint, "POST", filter);
	}
	
	const countData = async (filter) => {
        let countEndpoint = (advancedSearchData)?"death-record/count/advanced-search":"death-record/count-document/";
		return await rest.request(getServiceUri() + countEndpoint, "POST", filter);
	}
	
		const getErrorCodeFacets = () => {
	       let list = [{key: "selectAllError", value: selectAllError, label: t`select/deselect all`, separator: true}];
	       	getErrorList().forEach(error => {
	            list.push({key: error, value: errorList.includes(error), label: t(error)});
	
	        });
	        return list;
	    }
	    
	    const onAdvancedSearchSubmit = (data) => {
        	let dto = transformAdvancedSearchData(data);
        	setAdvancedSearchData(dto);
    	}
	    
	 	const buildFacets = (key, value, label, handleChange) => {
		    return (
		        <>
		        <FormControlLabel
		        control={<Checkbox
		          checked={value}
		          onChange={(event) => handleChange(key, event)}
		          color="primary"
		          value={value}
		        />
		        }
		        label={label}
		        style={{width: '100%'}}
		        />
		        </>
		    )
		}
	    
	    return (
	    <div>
	        <Facets title={t`Errors`} facets={getErrorCodeFacets()} facetsComponents={(key, value) => buildFacets(key, value, t(key), onErrorFacetChange)} />
	        <DeathRecordsList key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions(gui)} advancedSearchFields={advancedSearchfields} onSubmit={onAdvancedSearchSubmit}/>
	    </div>
		)
    
}

export const displayDeathRecordList = (gui, searchData) => {
	const readDeathRecordList = (onFinish) => () => {
		let uuid = uuidv4();
		return {
			uuid, view: () =>  <DeathRecordListPage uuid={uuid} gui={gui} searchData={searchData}/>
		};
	}
	gui.goTo(readDeathRecordList())
}

export const displayDeathRecordAdvancedSearch = (gui) => {
	let advancedSearchFilters = {fields: advancedSearchfields, name: t`Death Record`, onSubmit: (filter) => onAdvancedSearchSubmit(filter, gui)};
	OPEN_ADVANCED_SEARCH.publish(advancedSearchFilters);
}

const onAdvancedSearchSubmit = (filter, gui) => {
    displayDeathRecordList(gui, transformAdvancedSearchData(filter));
}

const selectAllErrors = () => {
 	let list = ["selectAllError"];
    getErrorList().forEach(error => {
            list.push(error);
    });
    return list;
}

const filterData = (DefaultRows, token) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let faceUrl = null;
        if (row.faceId != null) {
			faceUrl = getServiceUri() + "face/image/" + row.faceId + "/" + token;	
		}
        row.image = (faceUrl != null)?faceUrl:"/public/avatar.png";       
        let date = row.dateOfDeath
        if ( date !== null)
        row.dateOfDeath = date[2] + '-' + date[1] + '-' + date[0]; 
      newRows.push(row);
    }
    return newRows;
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    gui.goTo(displayReadDeathRecordForm((options) => displayConfirmationDialog(options, gui)), rowData.id);
                }
        }
    )
    return actions;
}
export const displayConfirmationDialog = (options, gui) => {
	let data = {title: "Confirmation", message: options.message, onClick: () => gui.goTo(displayDeathRecordList(gui))};
	OPEN_CONFIRMATION_DIALOG.publish(data);
}