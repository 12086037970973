import React, {useEffect} from "react";
import {v4 as uuidv4} from 'uuid';
import { CAN_I_SWITCH, OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { showNotification } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import {geoDataMetadataLoader} from "../../metadata/GeoDataMetadataLoader";
import {Observable} from "../../../../auto/js/events/Observable";
const countryObservable = new Observable();

const handleCountryChange = (value) => {
    countryObservable.publish(geoDataMetadataLoader.getChilds(value.key))
}
const provinceObservable = new Observable();

const handleProvinceChange = (value) => {
    provinceObservable.publish(geoDataMetadataLoader.getChilds(value.key))
}
const zoneObservable = new Observable();

const handleZoneChange = (value) => {
    zoneObservable.publish(geoDataMetadataLoader.getChilds(value.key))
}
const districtObservable = new Observable();

const handleDistrictChange = (value) => {
    districtObservable.publish(geoDataMetadataLoader.getChilds(value.key))
}
const islandObservable = new Observable();

const handleIslandChange = (value) => {
    islandObservable.publish(geoDataMetadataLoader.getChilds(value.key))
}
const fields = [
    {
    name:"country",
    type:"autocomplete",
    x:1, y:0, layout:"col-md-6",
        metadata: () => geoDataMetadataLoader.getRootNodes(),
        
        handleChange:(data) => handleCountryChange(data),   
    },
    {
    name:"province",
    type:"autocomplete",
    x:1, y:1, layout:"col-md-6",
        metadata: () => "",
        observable: countryObservable,
        handleChange:(data) => handleProvinceChange(data),   
    },
    {
    name:"zone",
    type:"autocomplete",
    x:1, y:2, layout:"col-md-6",
        metadata: () => "",
        observable: provinceObservable,
        handleChange:(data) => handleZoneChange(data),   
    },
    {
    name:"district",
    type:"autocomplete",
    x:1, y:3, layout:"col-md-6",
        metadata: () => "",
        observable: zoneObservable,
        handleChange:(data) => handleDistrictChange(data),   
    },
    {
    name:"island",
    type:"text",
    x:1, y:4, layout:"col-md-6",
    },
];

let IslandForm = createFormComponent(fields);

class FormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        CAN_I_SWITCH.pickUpThePhone(this.listen);
        this.state = {
        closeRequested: undefined
        }
    }
    componentDidMount() {
    this.props.loadData().then(
    	rowdata => {
        	countryObservable.publish(geoDataMetadataLoader.getChilds(rowdata?.country?.key));
        	provinceObservable.publish(geoDataMetadataLoader.getChilds(rowdata?.province?.key));
        	zoneObservable.publish(geoDataMetadataLoader.getChilds(rowdata?.zone?.key));
        	districtObservable.publish(geoDataMetadataLoader.getChilds(rowdata?.district?.key));
 		}
    )}
    listen = (closeMe) => {
        if (!this.isDirty())
            closeMe(true);
        this.setState({closeRequested: closeMe})
    }

    isDirty = () => {
        return this.myRef.current.isDirty();
    }

    handleDialogCancel = () => {
        this.state.closeRequested(false);
        this.setState({closeRequested: undefined});
    }

    handleSave = () => {
        this.myRef.current.save().then(() => {
        this.state.closeRequested(true);
    });
    }

    handleDontSave = () => {
        this.state.closeRequested(true);
    }

    render() {
        return (
        <>
        <AlertDialog
                title={t`Save your changes ?`}
                open={(this.state.closeRequested && this.isDirty())?true:false}
                handleClose={this.handleDialogCancel}
                noAgree={true}
                save={this.handleSave}
                dontSave={this.handleDontSave}
        />
        <IslandForm ref={this.myRef} key={this.props.key} loadData={() => this.props.loadData()} onSubmit={this.props.onSubmit} id={this.props.id} buttons={getButtons} />
        </>
        )
    }

}

export const displayNewIslandForm = (onFinish) => () => {
    let uuid = uuidv4();
    return {
        uuid, view: () => <FormComponent key={uuid} loadData={async () => buildEmptyObject(fields)} onSubmit={(onFinish)?(data) => save(data).then(() => onFinish({message: "Island submitted."})):save}/>
    };
}

export const displayReadIslandForm = (onFinish) => (rowdata) => {
    let uuid = uuidv4();
    return {
        uuid, view: () => <FormComponent key={uuid} loadData={() => Promise.resolve(rowdata)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish({message: "Island submitted."})):update}
        id={rowdata.islandId}/>
    };
}

const save = async (formData) => {
    let dto = form2dto(formData);
    dto.id = null;
    dto.areaId = geoDataMetadataLoader.incrementAreaIdFromParentId(dto.parentId);
    try {
        return rest.create('geo-data', dto).then((response) =>{
        if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error")
        else {
            geoDataMetadataLoader.load();
        }
        });
    } catch (err) {
    alert(err);
    }
}

const update = async (formData) => {
    let dto = form2dto(formData);
    try {
        return rest.update('geo-data', dto).then((response) =>{
            if (response.status)
                showNotification(response.message.split('Detail: ')[1], "error")
            else {
                geoDataMetadataLoader.load();
            }
        });
    } catch (err) {
    alert(err);
    }
}


const form2dto = (data) => {
    let updatedata = {};
    updatedata.id = ~~(data.id);
    updatedata.areaId = data.areaId;
    updatedata.name = data.island;
    updatedata.type = 5;
    updatedata.parentId = ~~(data.district?.key);
    if(updatedata.areaId == "")
    updatedata.areaId = null;
    if(updatedata.name == "")
    updatedata.name = null;
    updatedata.disabled = false;
    return updatedata
}

const buildEmptyObject = (fields) => {
    const empty = {};
    for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        switch (field.type) {
        case ("text"):
        empty[field.name] = "";
        break;
        case ("number"):
        empty[field.name] = "";
        break;
        case ("checkbox"):
        empty[field.name] = false;
        break;
        case ("timestampz"):
        empty[field.name] = '';
        break;
        case ("date"):
        empty[field.name] = null;
        break;
        case ("select"): // dynamic lists, loaded from the backend
        empty[field.name] = '';
        break;
        case ("list"): // static lists, hardcoded
        empty[field.name] = '';
        break;
        case ("password"):
        empty[field.name] = '';
        break;
        }
    }
    return empty;
}

const getButtons = () => {
    return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}