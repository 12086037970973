import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { ID_MTLB_ORDER_BY_VITAL_RECORD_ID } from "../../../../auto/js/metadata/IdMtlbOrderBy";
import { MTLB_TYPE_ID_CARD } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import { Separator } from "../../forms/CivilRecordFormCommon";


export const idCardApplicationListColumns = [
    { title: "image", field: "image", render: rowData => <img src={rowData.image} onError={(e) => { e.target.onerror = null; e.target.src = "/public/avatar.png" }} style={{ width: 40, borderRadius: '50%', height: 40 }} /> },
    { title: "vitalRecordId", field: "vitalRecordId" },
    { title: "fourthname", field: "fourthname" },
    { title: "secondname", field: "secondname" },
    { title: "thirdname", field: "thirdname" },
    { title: "firstname", field: "firstname" },
    { title: "status", field: "status" },
];

export const getData = async (filter) => {
    let searchEndpoint = (filter.query) ? "id-mtlb/advanced-search" : "id-mtlb/search-by-id-mtlb-type-and-status/";
    return await rest.request(getServiceUri() + searchEndpoint, "POST", filter);
}

export const countData = async (filter) => {
    let countEndpoint = (filter.query) ? "id-mtlb/count/advanced-search" : "id-mtlb/count/search-by-id-mtlb-type-and-status/";
    return await rest.request(getServiceUri() + countEndpoint, "POST", filter);
}

export const getOrderBy = () => {
    return ID_MTLB_ORDER_BY_VITAL_RECORD_ID
}

export const getMtlbTypeList = () => {
    return [MTLB_TYPE_ID_CARD]
}

export const filterData = (DefaultRows, token) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        const imageUrl = getServiceUri() + 'id-mtlb/face/' + row.id + "/" + token;
        row.image = (imageUrl != null) ? imageUrl : "/public/avatar.png";
        if (row.mtlbStatus != null)
            row.status = t(row.mtlbStatus);
        newRows.push(row);
    }
    return newRows;
}

export const getErrorList = () => {
    let list = [];
    list.push("E19");
    list.push("E22");
    return list;
}

export const advancedSearchfields = [
    { name: "caseSensitiveSearch", label: "Case Sensitive Search", type: "checkbox", x: 1, y: 1, layout: "col-md-3" },
    { name: "exactId", type: "number", x: 1, y: 2, layout: "col-md-4" },
    { name: "idSeparator", type: "custom", x: 1, y: 3, layout: "col-md-12", component: (name, disabled) => <Separator /> },

    { name: "firstname", type: "text", x: 1, y: 6, layout: "col-md-6" },
    {
        name: "firstnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 6, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    { name: "secondname", type: "text", x: 1, y: 4, layout: "col-md-6" },
    {
        name: "secondnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 4, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    { name: "thirdname", type: "text", x: 1, y: 5, layout: "col-md-6" },
    {
        name: "thirdnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 5, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },

    { name: "fourthname", type: "text", x: 1, y: 3, layout: "col-md-6" },
    {
        name: "fourthnameCondition", options: [
            { "name": "fuzzy", "label": "Fuzzy" },
            { "name": "exact", "label": "Exact" },
            { "name": "startsWith", "label": "Startswith" },
        ], type: "radio", x: 2, y: 3, layout: "col-md-6 pt-5", defaultValue: "startsWith"
    },
];

export const transformAdvancedSearchData = (formData) => {
    const columns = [];

    const addColumn = (name, type, value, condition) => {
        columns.push({
            name,
            type,
            [type === 'date' ? 'dateValue' : type === 'boolean' ? 'booleanValue' : (type === 'text' ? 'textValue' : 'longValue')]: value,
            searchType: condition ? condition : null,
        });
    };
    (formData.firstname !== "") && addColumn('firstname', 'text', formData.firstname, formData.firstnameCondition);

    (formData.secondname !== "") && addColumn('secondname', 'text', formData.secondname, formData.secondnameCondition);

    (formData.thirdname !== "") && addColumn('thirdname', 'text', formData.thirdname, formData.thirdnameCondition);

    (formData.fourthname !== "") && addColumn('fourthname', 'text', formData.fourthname, formData.fourthnameCondition);
    addColumn('mtlbType', 'number', 1, null);
    addColumn('draft', 'boolean', false, null);
    (formData.exactId !== "") && addColumn('vitalRecordId', 'number', formData.exactId, null);
    return { columns, caseSensitiveSearch: formData.caseSensitiveSearch };
};
