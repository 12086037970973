import { formState } from "../../../auto/js/forms/FormState";
import { saveBirthRegistrationForm } from "../../../auto/js/forms/birthRegistration/BirthRegistrationApi";
import { readNewBirthRegistrationForm } from "../../../auto/js/forms/birthRegistration/NewBirthRegistrationForm";
import { buildBirthRegistrationFormEmptyObject } from "../forms/birthRegistration/BirthRegistrationFormCommon";
import { Task } from "./Task";

export class BirthApplicationTask extends Task {
    constructor(id, name, gui) {
        super(id, name, gui);
    }
    
    start = (onFinishCallback) => {
        this.onFinishCallback = onFinishCallback;
        let data = buildBirthRegistrationFormEmptyObject();
		data.draft = true;
		saveBirthRegistrationForm(data).then((response) => {
            formState.addState('civilStatusMtlbId', response);
            this.gui.goTo(readNewBirthRegistrationForm(this.complete), response, null)
        })
    }
}