import React, { useState } from "react";

import { pojoMetadata } from "../../../auto/js/metadata";
import { rest, t } from "../../../auto/js/services";
import { Observable } from "../../../auto/js/events/Observable";
import { formState } from "../../../auto/js/forms/FormState";
import { geoDataMetadataLoader } from "../metadata/GeoDataMetadataLoader";
import { SimpleAutoCompleteInput } from "../widgets/SimpleAutoCompleteInput";

const countryObservable = new Observable();
const provinceObservable = new Observable();
const resetProvinceEvent = new Observable();
const zoneObservable = new Observable();
const resetZoneEvent = new Observable();
const districtObservable = new Observable();
const resetDistrictEvent = new Observable();
const islandObservable = new Observable();
const resetIslandEvent = new Observable();
const resetLocationEvent = new Observable();

let resetList =[resetProvinceEvent,resetZoneEvent,resetDistrictEvent,resetIslandEvent,resetLocationEvent,];
export const addressFields = [
 	{
    	title: "country", field: "country",
    	 editComponent: props => {
    		let [value, setValue] = useState({key: 1, value: "Vanuatu"});
    		const handleCountryChange = (value) => {
		        resetFieldsFromIndex(0);
		        if (value.key) {
		            countryObservable.publish(geoDataMetadataLoader.getChilds(value.key))
                    props.onChange(value);
		            setValue(value);
		        }
		        else {
		            countryObservable.publish([])
		        }
   			}
			return (
	        	<SimpleAutoCompleteInput  name={"country"} options={() => geoDataMetadataLoader.getRootNodes()} handleChange={(data) => handleCountryChange(data)}   defaultValue={value} disabled={props.disabled}/>
	        )
        },
        render: rowData => <>{(rowData.country)?rowData.country.value:""}</>
    },
 	{
    	title: "province", field: "province",
    	 editComponent: props => {
    		let [options, setOptions] = useState(geoDataMetadataLoader.getChilds(1));
    		let [value, setValue] = useState(props.value);
    		const handleProvinceChange = (value) => {
		        resetFieldsFromIndex(1);
		        if (value.key) {
		            provinceObservable.publish(geoDataMetadataLoader.getChilds(value.key))
                    props.onChange(value);
		            setValue(value);
		        }
		        else {
		            countryObservable.publish([])
		        }
   			}
   			if (props.rowData.country && props.rowData.country != null && options === "") {
        		setOptions(geoDataMetadataLoader.getChilds(props.rowData.country.key))
    		}
			return (
	        	<SimpleAutoCompleteInput  name={"province"} options={() => options} handleChange={(data) => handleProvinceChange(data)} observable={countryObservable} reset={resetProvinceEvent}  defaultValue={value} disabled={props.disabled}/>
	        )
        },
        render: rowData => <>{(rowData.province)?rowData.province.value:""}</>
    },
 	{
    	title: "zone", field: "zone",
    	 editComponent: props => {
    		let [options, setOptions] = useState("");
    		let [value, setValue] = useState(props.value);
    		const handleZoneChange = (value) => {
		        resetFieldsFromIndex(2);
		        if (value.key) {
		            zoneObservable.publish(geoDataMetadataLoader.getChilds(value.key))
                    props.onChange(value);
		            setValue(value);
		        }
		        else {
		            countryObservable.publish([])
		        }
   			}
   			if (props.rowData.province && props.rowData.province != null && options === "") {
        		setOptions(geoDataMetadataLoader.getChilds(props.rowData.province.key))
    		}
			return (
	        	<SimpleAutoCompleteInput  name={"zone"} options={() => options} handleChange={(data) => handleZoneChange(data)} observable={provinceObservable} reset={resetZoneEvent}  defaultValue={value} disabled={props.disabled}/>
	        )
        },
        render: rowData => <>{(rowData.zone)?rowData.zone.value:""}</>
    },
 	{
    	title: "district", field: "district",
    	 editComponent: props => {
    		let [options, setOptions] = useState("");
    		let [value, setValue] = useState(props.value);
    		const handleDistrictChange = (value) => {
		        resetFieldsFromIndex(3);
		        if (value.key) {
		            districtObservable.publish(geoDataMetadataLoader.getChilds(value.key))
                    props.onChange(value);
		            setValue(value);
		        }
		        else {
		            countryObservable.publish([])
		        }
   			}
   			if (props.rowData.zone && props.rowData.zone != null && options === "") {
        		setOptions(geoDataMetadataLoader.getChilds(props.rowData.zone.key))
    		}
			return (
	        	<SimpleAutoCompleteInput  name={"district"} options={() => options} handleChange={(data) => handleDistrictChange(data)} observable={zoneObservable} reset={resetDistrictEvent}  defaultValue={value} disabled={props.disabled}/>
	        )
        },
        render: rowData => <>{(rowData.district)?rowData.district.value:""}</>
    },
 	{
    	title: "island", field: "island",
    	 editComponent: props => {
    		let [options, setOptions] = useState("");
    		let [value, setValue] = useState(props.value);
    		const handleIslandChange = (value) => {
		        resetFieldsFromIndex(4);
		        if (value.key) {
		            islandObservable.publish(geoDataMetadataLoader.getChilds(value.key))
                    props.onChange(value);
		            setValue(value);
		        }
		        else {
		            countryObservable.publish([])
		        }
   			}
   			if (props.rowData.district && props.rowData.district != null && options === "") {
        		setOptions(geoDataMetadataLoader.getChilds(props.rowData.district.key))
    		}
			return (
	        	<SimpleAutoCompleteInput  name={"island"} options={() => options} handleChange={(data) => handleIslandChange(data)} observable={districtObservable} reset={resetIslandEvent}  defaultValue={value} disabled={props.disabled}/>
	        )
        },
        render: rowData => <>{(rowData.island)?rowData.island.value:""}</>
    },
 	{
    	title: "location", field: "location",
    	 editComponent: props => {
    		let [options, setOptions] = useState("");
    		let [value, setValue] = useState(props.value);
    		const handleLocationChange = (value) => {
		        if (value.key) {
                    props.onChange(value);
		            setValue(value);
		        }
   			}
   			if (props.rowData.island && props.rowData.island != null && options === "") {
        		setOptions(geoDataMetadataLoader.getChilds(props.rowData.island.key))
    		}
			return (
	        	<SimpleAutoCompleteInput  name={"location"} options={() => options} handleChange={(data) => handleLocationChange(data)} observable={islandObservable} reset={resetLocationEvent}  defaultValue={value} disabled={props.disabled}/>
	        )
        },
        render: rowData => <>{(rowData.location)?rowData.location.value:""}</>
    },
    {title: "fromDate", field: "fromDate", type:"date"},
    {title: "toDate", field: "toDate", type:"date"},
]

export const buildAddressData = async (query, id) => {
    let filter = query;
    let data;
    filter["address"] = {civilStatusMtlbId: id};
    filter["orderBy"] = null;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search!='') {
        pojoMetadata["address"].columns.forEach(element => {
            if(element.type=='text'){
                filter["address"][element.key]= query.search;
            }
        });
        filter["and"] = false;
    }
    return await getAddressData(filter).then(response => {
        data = filterData(response);
        data.forEach(element => {
            loadGeoData(element);
        });
        formState.setAddressList(data)
        return countAddressData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
    });
}

export const getAddressData = async (filter) => {
    return rest.search('address', filter)
}

export const loadGeoData = async (element) => {
    if (element.areaId != null) {
        let geographicData = getGeographicDataFromLocation(element.areaId);
        element.country = geographicData.country;
        element.province = geographicData.province;
        element.zone = geographicData.zone;
        element.district = geographicData.district;
        element.island = geographicData.island;
        element.location = geographicData.location;
    }
}

export const countAddressData = async (filter) => {
    return await rest.count('address', filter);
}

export const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let fromDate = row.fromDate
        if ( fromDate !== null) {
            let date = new Date(fromDate[0]+ "/" + fromDate[1] + "/" + fromDate[2]);
            date.setTime(date.getTime() + 60 * 60 * 1000)
            row.fromDate = date;
        }
        let toDate = row.toDate
        if ( toDate !== null) {
            let date = new Date(toDate[0]+ "/" +toDate[1] + "/" + toDate[2]);
            date.setTime(date.getTime() + 60 * 60 * 1000)
            row.toDate = date;
        }
        newRows.push(row);
    }
    return newRows;
}

export const getAddressEditables = (id) => {
    let editables = {}
    editables.onRowAdd = newData =>
        new Promise((resolve, reject) => {

            let dto = pojoMetadata['address'].form2dto(newData);
            dto["civilStatusMtlbId"] = id;
            form2Dto(dto, newData);
            try {
                return rest.create('address', dto).then(() => resolve());
            } catch (err) {
                alert(err);
            }
        }),
        editables.onRowUpdate = (newData, oldData) =>
            new Promise((resolve, reject) => {
                let dto = pojoMetadata['address'].form2dto(newData);
                newData.id = oldData.id;
            	form2Dto(dto, newData);
                try {
                    return rest.update('address', dto).then(() => {
                        formState.addToAddressList(newData)
                        resolve()}).catch((e) => console.table(e))

                } catch (err) {
                    alert(err);
                }
            }),
        editables.onRowDelete = oldData =>
            new Promise((resolve, reject) => {
                try {
                    return rest.purge('address', oldData.id).then(() => resolve());
                } catch (err) {
                    alert(err);
                }
            })
    return editables;
}

let getGeographicDataFromLocation = (areaId) => {
    let geoComponents = areaId.split(".");
    let geoResult = {};
    geoResult.country = { key: (geoDataMetadataLoader.areas[geoComponents.slice(0,0+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,0+1).join(".")].id:"",
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0,0+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,0+1).join(".")].name:"" };
    geoResult.province = { key: (geoDataMetadataLoader.areas[geoComponents.slice(0,1+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,1+1).join(".")].id:"",
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0,1+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,1+1).join(".")].name:"" };
    geoResult.zone = { key: (geoDataMetadataLoader.areas[geoComponents.slice(0,2+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,2+1).join(".")].id:"",
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0,2+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,2+1).join(".")].name:"" };
    geoResult.district = { key: (geoDataMetadataLoader.areas[geoComponents.slice(0,3+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,3+1).join(".")].id:"",
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0,3+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,3+1).join(".")].name:"" };
    geoResult.island = { key: (geoDataMetadataLoader.areas[geoComponents.slice(0,4+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,4+1).join(".")].id:"",
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0,4+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,4+1).join(".")].name:"" };
    geoResult.location = { key: (geoDataMetadataLoader.areas[geoComponents.slice(0,5+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,5+1).join(".")].id:"",
        value: (geoDataMetadataLoader.areas[geoComponents.slice(0,5+1).join(".")])?geoDataMetadataLoader.areas[geoComponents.slice(0,5+1).join(".")].name:"" };
    return geoResult;
}

export const form2Dto = (dto, form) => {
		if (form.location)
			dto["areaId"] = geoDataMetadataLoader.getAreaId(form.location.key);
		else if(form.island)
        	dto["areaId"] = geoDataMetadataLoader.getAreaId(form.island.key);
		else if(form.district)
        	dto["areaId"] = geoDataMetadataLoader.getAreaId(form.district.key);
		else if(form.zone)
        	dto["areaId"] = geoDataMetadataLoader.getAreaId(form.zone.key);
		else if(form.province)
        	dto["areaId"] = geoDataMetadataLoader.getAreaId(form.province.key);
		else if(form.country)
        	dto["areaId"] = geoDataMetadataLoader.getAreaId(form.country.key);
}
const resetFieldsFromIndex = (index) => {
    for (let i = index; i < resetList.length; i++) {
        resetList[i].publish()
    }
}