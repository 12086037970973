import React, { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { TabBrowser } from '../../../auto/js/browsers/TabBrowser';
import { t } from '../../../auto/js/services';
import { displayReadCivilRecordForm } from '../../../auto/js/forms/CivilRecordForm';
import { displayCivilRecordAdminInfo } from './CivilRecordAdminInfo';
import { displayCivilRecordBiometricData } from './CivilRecordBiometricData';

export const displayCivilRecordTabsView = (gui, id) => {
    
    class TabsViewComponent extends TabBrowser {
        constructor(props) {
            super(props)
            this.tabs = {
                "civilRecordForm" : {
                    title: t`Civil Record`,
                    description: t`Civil Record Form`,
                    do: () => displayReadCivilRecordForm(id, this)
                },
                "civilRecordAdminInfo" : {
                    title: t`Aministrative Information`,
                    description: t`Civil Record Aministrative Information`,
                    do: () => displayCivilRecordAdminInfo(this,  id)
                },
                "civilRecordBiometricData" : {
                    title: t`Biometric Data`,
                    description: t`Civil Record Biometric Data`,
                    do: () => displayCivilRecordBiometricData(this,  id)
                }
            }
            displayReadCivilRecordForm(id, this)
        }
    }

    const createRetornadosTabView = () => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <TabsViewComponent gui={gui} activeTab={"civilRecordForm"} welcome={() => <></>} />
        }
    }
    gui.goTo(createRetornadosTabView, null, null);
}