import React from "react";
import _ from 'lodash';
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import { dto2form, form2dto } from "../../../../main/js/forms/familyTreeRegistration/FamilyTreeRegistrationFormCommon";

export const saveFamilyTreeRegistrationForm = async (formData) => {
	let data = _.clone(formData);
    let dto = pojoMetadata['rvfour-birth-mtlb'].form2dto(data);
    form2dto(formData, dto);
    try {
		return rest.request(getServiceUri() + 'rvfour-birth-mtlb/new-document', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadFamilyTreeRegistrationFormData = async (id) => {
	return await rest.read('rvfour-birth-mtlb', id).then(response => {
		let form = dto2form(response);
		let tagFilter = {and: true};
		tagFilter['rvfour-birth-mtlb-tag'] = {rvfourBirthMtlbId: id};
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('rvfour-birth-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

export const updatePendingFamilyTreeRegistrationForm = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['rvfour-birth-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'rvfour-birth-mtlb/pending/update', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const updateFamilyTreeRegistrationForm = async (formData) => {
	let data = _.clone(formData);
	const dto = pojoMetadata['rvfour-birth-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'rvfour-birth-mtlb/edit-document', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const deleteFamilyTreeRegistrationForm = async (id) => {
    try {
        return rest.delete('rvfour-birth-mtlb', id);
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedFamilyTreeRegistrationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let data = _.clone(formData);
	let dto = pojoMetadata['rvfour-birth-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'rvfour-birth-mtlb/rejected/update', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const rejectFamilyTreeRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'rvfour-birth-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const rejectReadyFamilyTreeRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'rvfour-birth-mtlb/ready/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const approveReadyFamilyTreeRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'rvfour-birth-mtlb/ready/approve', 'POST', id);
    } catch (err) {
        alert(err);
    }
}
