import React from "react";

import { rest } from "../../../auto/js/services";
import { showNotification, swapObject } from "../../../auto/js/utils";
import { TypeOfAcquisition } from "../../../auto/js/metadata/TypeOfAcquisition";
import { getServiceUri } from "../../../auto/js/metadata";

export const loadHistoryFormData = async (id) => {
	let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	return await rest.read('vital-record-history', id).then(async response => {
		let form = response;
		if (response.faceId != null) {
			const imageUrl = getServiceUri() + 'face/image/' + response.faceId + "/" + token;
			form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: (imageUrl != null) ? imageUrl : '/public/avatar.png', isEmpty: false };
		} else
			form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true };
		
		if (response.otherCitizenshipCsv == null || response.otherCitizenshipCsv === "")
			form.otherCitizenshipCsv = []
		else {
			let values = [];
			let components = response.otherCitizenshipCsv.split(",");
			components.forEach(element => {
				values.push(parseInt(element))
			});
			form.otherCitizenshipCsv = values;
		}
		if (response.typeOfAcquisition != null)
        	form.typeOfAcquisition = TypeOfAcquisition[response.typeOfAcquisition]
        if (response.motherId == null) {
            if (response.reportedMotherName != null)
                    form.motherInfo = "motherWithoutBirthRecord"
            else if (response.unknownMother != null && response.unknownMother)
                form.motherInfo = "unknownMother"
            else
                form.motherInfo = "motherWithoutBirthRecord"
        } else
            form.motherInfo = "motherWithBirthRecord"
    
        if (response.fatherId == null) {
            if (response.reportedFatherName != null)
                form.fatherInfo = "fatherWithoutBirthRecord"
            else if (response.unknownFather != null && response.unknownFather)
                form.fatherInfo = "unknownFather"
            else
                form.fatherInfo = "fatherWithoutBirthRecord"
        } else
            form.fatherInfo = "fatherWithBirthRecord"
        let tagFilter = { and: true };
        tagFilter['vital-record-tag-history'] = { vitalRecordId: response.id };
        return rest.search('vital-record-tag-history', tagFilter).then(tags => {
            form['tags'] = tags
            
            return form;
        })
	})
}