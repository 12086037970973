import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { AlertDialog } from "../../widgets";
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import {  updatePendingAmendmentApplicationForm, rejectAmendmentApplicationForm, loadAmendmentApplicationFormData } from "./AmendmentApplicationApi";
import { amendmentApplicationFields } from "../../../../main/js/forms/amendmentApplication/AmendmentApplicationFormCommon";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileUploader } from "../../widgets/FileUploader";

import { createTableComponent } from "../../widgets/TableComponent";
import { loadCivilStatusMtlbAttachment } from "../../../../main/js/forms/amendmentApplication/AmendmentApplicationFormCommon";
import { FileInput } from "../../../../main/js/widgets/FileInput";

let AmendmentApplicationForm = createFormComponent(amendmentApplicationFields);

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<AmendmentApplicationForm ref={this.myRef} key={this.props.key} loadData={async () => loadFormData(this.props.id)} onSubmit={(this.props.onFinish)?(data) => update(data).then(() => this.props.onFinish({message: "Amendment application updated."})):update} id={this.props.id} buttons={() => getButtons(this.props.id, this.props.onFinish)} />
            <FileInput label={t`Attachments`} previewUrl={(id) => getPreviewUrl(id)} uploadUrl={getServiceUri() + 'civil-status-mtlb/attachment/' +  this.props.id} updateFileData={(data) => updateFileData(data)} loadData={async () => loadCivilStatusMtlbAttachment(this.props.id)} handleDelete={(id) => handleCivilStatusMtlbAttachmentDelete(id)} handleClick={(id) => handleCivilStatusMtlbAttachmentClick(id)}/>
		</>
		)
	}

}

const handleCivilStatusMtlbAttachmentDelete = (id) => {
	rest.delete('civil-status-mtlb/attachment', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const getPreviewUrl = (id) => {
	return getServiceUri() + 'civil-status-mtlb/attachment' + '/preview/' + id;
}

const handleCivilStatusMtlbAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'civil-status-mtlb/attachment' + '/' + id + '/' + token;
};

const updateFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'civil-status-mtlb/attachment' + '/' + data.id, "PUT", filter);
}

export const displayReadPendingAmendmentApplicationForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} id={id} onFinish={onFinish}/>
	};
}

const update = async (formData) => {
	return updatePendingAmendmentApplicationForm(formData);
}

const reject = async (id) => {
    return rejectAmendmentApplicationForm(id);
}

const loadFormData = async (id) => {
	return await loadAmendmentApplicationFormData(id);
}

const getButtons = (id, onFinish) => {
	return (
    <>
	<div className="row">
		<div className="col-md-6">
        	<button style={{ minWidth: '5rem' }} type="submit"> {t`Update`} </button>
		</div>
		<div className="col-md-6">
        	<button style={{ minWidth: '5rem' }} type="button" className={'reject-button'} onClick={(onFinish)?() => reject(id).then(() => onFinish({message: "Amendment application rejected."})):() => reject(id)}> {t`Reject`} </button>
		</div>
	</div>
    </>
    )
}
