import React from "react";
import * as Yup from 'yup';

import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { MTLB_TYPE_MARRIAGE } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";
import { formatDate, showNotification } from "../../../../auto/js/utils";
import { MTLB_STATUS_SUBMITTED } from "../../../../auto/js/metadata/MtlbStatus";
import { Section } from "../../widgets/Section";
import { PersonComponent } from "../../../../auto/js/widgets/PersonComponent";
import { loadPersonData } from "../../utils";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";
import { AdvancedSearchPersonComponent } from "../../widgets/AdvancedSearchPersonComponent";
import { buildFemaleEmptyObject, buildMaleEmptyObject } from "../birthRegistration/BirthRegistrationFormCommon";
import { Separator } from "../CivilRecordFormCommon";
import { formState } from "../../../../auto/js/forms/FormState";

const regime = {1:"Civil Marriage", 2:"Religious Marriage", 3: "Custom Marriage"};

/* export const marriageRegistrationFields = [
    {name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name: "marriageDetails", type: "custom", x:1, y:2, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "declaredDate", type: "date", x:1, y: 3, layout:"col-md-6"},
    {name: "timeOfMarriage", type: "time", x:2, y:3, layout:"col-md-6"},
    {name: "unionRegime", type: "select", x:1, y: 4, layout:"col-md-6",  metadata: () => regime},
    {name:"addressBox", label: "Place", components: [
        {name: "address", type: "custom", x:1, y:1, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"191"}/>},
    ], type: "box", x:2, y:5, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
    {name: "wifeDetails", type: "custom", x:1, y:7, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "partner2VitalRecordId", type: "custom", x:1, y:8, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Bride"} readOnly={disabled} loadData={loadPersonData} buildEmptyObject={buildFemaleEmptyObject} />},
    {name: "husbandDetails", type: "custom", x:1, y:10, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "partner1VitalRecordId", type: "custom", x:1, y:11, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Groom"} readOnly={disabled} loadData={loadPersonData} buildEmptyObject={buildMaleEmptyObject} />},
    {name: "witnessDetails", type: "custom", x:1, y:13, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "witness1VitalRecordId", type: "custom", x:1, y:14, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search First Witness"} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "witness2VitalRecordId", type: "custom", x:1, y:16, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Second Witness"} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "declarationDetails", type: "custom", x:1, y:17, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "celebrant", type: "text", x:1, y:18, layout:"col-md-4"},
    {name: "officer", type: "text", x:1, y:19, layout:"col-md-4"},
    {name: "position", type: "text", x:2, y:19, layout:"col-md-4"},
    {name: "Extras", type: "custom", x:1, y:20, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "notes", type: "text", x:1, y:21, layout:"col-md-12"}
]; */


export const marriageRegistrationFields = [
    {name: "tags", type:"tags", x:1, y:1, layout:"col-md-12"},
    {name: "marriageDetails", type: "custom", x:1, y:2, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
	{
		name: "celebrantId", label: "Search Celebrant", type: "custom", x: 1, y: 5, layout: "col-md-12",
		component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Celebrant"} readOnly={disabled} loadData={loadPersonData} />
	},
	{
		name: "reportedCelebrant", type: "text", x: 2, y: 6, layout: "col-md-12",
		display: (data) => { return data.reportedCelebrant !== null && data.celebrantId === null ? true : false; }
	},
	{name: "declaredDate", type: "date", x:1, y: 7, layout:"col-md-6"},
    {name: "timeOfMarriage", type: "time", x:2, y:7, layout:"col-md-6"},
    {name: "unionRegime", type: "select", x:1, y: 8, layout:"col-md-6",  metadata: () => regime},
    {name:"addressBox", label: "Place", components: [
        {name: "address", type: "custom", x:1, y:1, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"191"}/>},
    ], type: "box", x:2, y:9, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
    {name: "wifeDetails", type: "custom", x:1, y:10, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "partner2VitalRecordId", type: "custom", x:1, y:11, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Bride"} readOnly={disabled} loadData={loadPersonData} buildEmptyObject={buildFemaleEmptyObject} />},
    {
		name: "wifeGenderValidation", type: "validation", x: 1, y: 12, layout: "col-md-6",
		"validation": Yup.boolean().test("wifeGenderValidation", "Bride need to be female", function () {
			return (formState.getState()["partner2VitalRecordId-gender"])? formState.getState()["partner2VitalRecordId-gender"] === "FEMALE": true;
		})
	},
    {
		name: "wifeAgeValidation", type: "validation", x: 2, y: 12, layout: "col-md-6",
		"validation": Yup.boolean().test("wifeAgeValidation", "Bride must be at least 16 years old", function () {
			return (formState.getState()["partner2VitalRecordId-birthdate"])? verifyAge(formState.getState()["partner2VitalRecordId-birthdate"], 16) : true;
		})
	},
    {name: "husbandDetails", type: "custom", x:1, y:13, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "partner1VitalRecordId", type: "custom", x:1, y:14, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Groom"} readOnly={disabled} loadData={loadPersonData} buildEmptyObject={buildMaleEmptyObject} />},
    {
		name: "husbandGenderValidation", type: "validation", x: 1, y: 15, layout: "col-md-6",
		"validation": Yup.boolean().test("husbandGenderValidation", "Groom need to be male", function () {
			return (formState.getState()["partner1VitalRecordId-gender"])? formState.getState()["partner1VitalRecordId-gender"] === "MALE": true;
		})
	},
    {
		name: "husbandAgeValidation", type: "validation", x: 2, y: 15, layout: "col-md-6",
		"validation": Yup.boolean().test("husbandAgeValidation", "Groom must be at least 18 years old", function () {
			return (formState.getState()["partner1VitalRecordId-birthdate"])? verifyAge(formState.getState()["partner1VitalRecordId-birthdate"], 18) : true;
		})
	},
    {name: "witnessDetails", type: "custom", x:1, y:17, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "witness1VitalRecordId", type: "custom", x:1, y:18, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search First Witness"} readOnly={disabled} loadData={loadPersonData}/>},
    {name: "witness2VitalRecordId", type: "custom", x:1, y:19, layout:"col-md-12", component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Second Witness"} readOnly={disabled} loadData={loadPersonData}/>},
    
	{ name: "declarationDetails", type: "custom", x: 1, y: 20, layout: "col-md-12", component: (name, disabled) => <Section name={name} /> },
	{
		name: "declarantId", label: "Search Declarant", type: "custom", x: 1, y: 21, layout: "col-md-12",
		component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Declarant"} readOnly={disabled} loadData={loadPersonData} />
	},
	{
		name: "reportedDeclarant", type: "text", x: 2, y: 22, layout: "col-md-12",
		display: (data) => { return data.reportedDeclarant !== null && data.declarantId === null ? true : false; }
	},
    {name:"registrationBox", label: "Registration Location", components: [
		{name: "registrationLocation", type: "custom", x:1, y:1, layout:"col-md-12", component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} default={"191"}/>}
	 ], type: "box", x:2, y:23, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
	{
		name: "officerId", label: "Search officer", type: "custom", x: 1, y: 24, layout: "col-md-12",
		component: (name, disabled) => <AdvancedSearchPersonComponent name={name} label={"Search Officer"} readOnly={disabled} loadData={loadPersonData} />
	},
	{
		name: "reportedOfficer", type: "text", x: 2, y: 25, layout: "col-md-12",
		display: (data) => { return data.reportedOfficer !== null && data.officerId ===null ? true : false; }
	},
    {name: "Mentions", type: "custom", x:1, y:34, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "mentions", type: "text", x:1, y:35, layout:"col-md-12"},
	{name: "Extras", type: "custom", x:1, y:36, layout:"col-md-12", component: (name, disabled) => <Section name={name} />},
    {name: "notes", type: "text", x:1, y:37, layout:"col-md-12"}
];

export const verifyAge = (date, maxAge) => {
    let formattedDate = new Date(date[0], date[1] - 1, date[2]);;
    // Get the current date
    let currentDate = new Date();
    
    // Calculate the age
    let age = currentDate.getFullYear() - formattedDate.getFullYear();
    let monthDifference = currentDate.getMonth() - formattedDate.getMonth();
    
    // Adjust age if the birth month/day has not been reached yet
    if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < formattedDate.getDate())) {
        age--;
    }
    
    // Check if the age is at least 16
    return age >= maxAge;
}

export const saveMarriageRegistrationForm = async (formData) => {
    let dto = pojoMetadata['union-mtlb'].form2dto(formData);
    dto.mtlbType = MTLB_TYPE_MARRIAGE;
    form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'union-mtlb', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadUnionMtlbAttachment = async (id) => {
	let filter = {and: true};
	filter['union-mtlb-attachment'] = {};
	filter['union-mtlb-attachment']['unionMtlbId'] = id;
	return rest.search('union-mtlb-attachment', filter)
}

export const loadMarriageRegistrationFormData = async (id) => {
	return await rest.read('union-mtlb/read', id).then(response => {
		let form = response;
		let tagFilter = {and: true};
        dto2form(response, form);
		tagFilter['union-mtlb-tag'] = {unionMtlbId: id};
        if (response.status)
            showNotification(response.message.split('Detail: ')[1], "error")
        
		return rest.search('union-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags
            
			return form;
		})
	})
}

export const updatePendingMarriageRegistrationForm = async (formData) => {
	let dto = pojoMetadata['union-mtlb'].form2dto(formData);
    form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'union-mtlb/pending/update', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedMarriageRegistrationForm = async (formData) => {
	let dto = pojoMetadata['union-mtlb'].form2dto(formData);
    form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'union-mtlb/rejected/update', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const rejectMarriageRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'union-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const buildMarriageRegistrationFormEmptyObject  = () => {
    const empty = buildEmptyObject(marriageRegistrationFields);
	return empty;
}

export const updateMarriageRegistrationForm = async (formData) => {
	let dto = pojoMetadata['union-mtlb'].form2dto(formData);
    dto.mtlbStatus = MTLB_STATUS_SUBMITTED;
	dto.draft = false;
    form2dto(formData, dto);
    try {
        return rest.update('union-mtlb', dto);
    } catch (err) {
        alert(err);
    }
}

export const deleteMarriageRegistrationForm = async (id) => {
    try {
        return rest.delete('union-mtlb', id);
    } catch (err) {
        alert(err);
    }
}

export const form2dto = (formData, dto) => {
    dto.mtlbType = MTLB_TYPE_MARRIAGE;
    if (formData.partner1VitalRecordId != null)
        dto.partner1VitalRecordId = parseInt(formData.partner1VitalRecordId)
    if (formData.partner2VitalRecordId != null)
        dto.partner2VitalRecordId = parseInt(formData.partner2VitalRecordId)
    if (formData.witness1VitalRecordId != null)
        dto.witness1VitalRecordId = parseInt(formData.witness1VitalRecordId)
    if (formData.witness2VitalRecordId != null)
        dto.witness2VitalRecordId = parseInt(formData.witness2VitalRecordId)
    if (formData.timeOfMarriage != null && typeof(formData.timeOfMarriage) != 'string') {
        const date = new Date(formData.timeOfMarriage)
        let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        dto.timeOfMarriage = birthHour + ":" + birthMinute;
    }
}

export const dto2form = (dto) => {
    if (dto.timeOfMarriage !== null) {
        dto.timeOfMarriage = new Date().setHours(dto.timeOfMarriage[0], dto.timeOfMarriage[1]);
    }
    return dto;
}

export const newMarriageRegistrationFormForm2Dto = (form, dto) => {
    
}