import React, { useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Button } from "@mui/material";
import { OPEN_VIEW_EVENT, OPEN_SIDE_PANEL } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { createFormComponent } from '../../../../auto/js/widgets/FormComponent';
import { civilRecordColumns } from "../../../../main/js/lists/CivilRecordListCommon";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent";
import SearchBar from "material-ui-search-bar";
import { VITAL_RECORD_ORDER_BY_FIRSTNAME } from "../../../../auto/js/metadata/VitalRecordOrderBy";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";
import { BirthRegistrationFormComponent } from "../../../../auto/js/forms/birthRegistration/NewBirthRegistrationForm";
import { MTLB_STATUS_INITIATED, MTLB_STATUS_SUBMITTED } from "../../../../auto/js/metadata/MtlbStatus";
import { civilRecordFormfields } from "../../../../main/js/forms/CivilRecordFormCommon";
import { showNotification, swapObject } from "../../../../auto/js/utils";
import { AmendmentApplicationFormComponent } from "../../../../auto/js/forms/amendmentApplication/NewAmendmentApplicationForm";
import { MTLB_TYPE_CIVIL_STATUS_CHANGE } from "../../../../auto/js/metadata/MtlbType";
import { NotesGrid } from "../../../../auto/js/widgets/NotesGrid";
import { digitalizationFields } from "../../../../main/js/forms/digitalization/DigitalizationFormCommon"
import { windowContext } from "../../../../auto//js/WindowContext";
import { updateBirthRegistrationForm } from "../../../../auto/js/forms/birthRegistration/BirthRegistrationApi";
import { TypeOfAcquisition } from "../../metadata/TypeOfAcquisition";
import { amendmentApplicationFields } from "../../../../main/js/forms/amendmentApplication/AmendmentApplicationFormCommon";
export const DigitalizationForm = (props) => {
	const [searchResult, setSearchResult] = useState(false);
	const [newForm, setNewForm] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState(false);
	const [amendmentForm, setAmendmentForm] = useState(false);
	const [selectedId, setSelectedId] = useState(undefined);
	const [searchText, setSearchText] = useState("");
	let CivilRecordForm = createFormComponent(civilRecordFormfields);
	let DigitalizationSearchList = createTableComponent(civilRecordColumns);

	const onChange = (text) => {
		setSearchText(text);
	}

	const onRequestSearch = () => {
		setSearchResult(true);
	}

	const createNewForm = () => {
		setSearchResult(false);
		setNewForm(true);
	}

	const buildData = async (query) => {
		let filter = query;
		let data;
		filter["orderBy"] = VITAL_RECORD_ORDER_BY_FIRSTNAME;
		filter.orderDirection = null;
		filter.offset = query.page * query.pageSize;
		filter["vital-record"] = (props.query) ? props.query : {}

		filter["vital-record"]["inactiveList"] = [true, false];
		if (searchText && searchText != '') {
			pojoMetadata["vital-record"].columns.forEach(element => {
				if (element.type == 'text') {
					filter["vital-record"][element.key] = searchText;
				}
			});
			filter["and"] = false;
			filter.fuzziness = "AUTO";
		}
		return await getCivilRecordData(filter).then(response => {
			data = filterData(response)
			return countData(filter).then((count) => { return { data: data, totalCount: count, page: query.page } })
		});
	}

	const getCivilRecordData = async (filter) => {
		return await rest.request(getServiceUri() + "vital-record/search-by-active/", "POST", filter);
	}

	const countData = async (filter) => {
		return await rest.request(getServiceUri() + "vital-record/count/search-by-active/", "POST", filter);
	}

	const getTableActions = () => {
		let actions = [];
		actions.push(
			{
				icon: () => <VisibilityIcon />,
				tooltip: t`View`,
				onClick: (event, rowData) => {
					setSelectedId(rowData.id)
					setSelectedRecord(true);
				}
			}
		)
		return actions;
	}

	const returnToResult = () => {
		setSelectedRecord(false);
		setSearchResult(true);
	}

	const attachAndSubmit = async () => {
		let dto = {};
		dto.civilStatusMtlbId = props.id;
		dto.vitalRecordId = selectedId;
		return rest.request(getServiceUri() + "/apply/attach-digitalization-mtlb", "POST", dto).then(response => {
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Attached to record`, "success");
		})
	}
	const displayAmendmentForm = async () => {
		return loadFormData(selectedId).then(response => {
			let data = _.clone(response);
			let dto = pojoMetadata['civil-status-mtlb'].form2dto(data);
			dto.timestamp = null;
			dto.mtlbType = MTLB_TYPE_CIVIL_STATUS_CHANGE;
			dto.mtlbStatus = MTLB_STATUS_INITIATED;
			dto.draft = true;
			dto.vitalRecordId = response.id;
			dto.id = props.id;
			if (response.birthTime != null && typeof(response.birthTime) != 'string') {
				const date = new Date(response.birthTime)
				let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
				let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
				dto.birthTime = birthHour + ":" + birthMinute;
			}
			if (response.otherCitizenshipCsv !== null && response.otherCitizenshipCsv && Array.isArray(response.otherCitizenshipCsv)) {
				let otherCitizenshipCsv = response.otherCitizenshipCsv.join(',');
				dto.otherCitizenshipCsv = otherCitizenshipCsv;
			}
			if (response.typeOfAcquisition != null)
				dto.typeOfAcquisition = swapObject(TypeOfAcquisition)[response.typeOfAcquisition]
		
			if (response.faceId && response.faceId != null) {
				return rest.read('face', response.faceId).then(faceObject => {
					dto.face = faceObject.image;
					dto.faceMimeType = faceObject.faceMimeType;
					if (response.birthCountry)
						dto.birthCountry = response.birthCountry.key;
					try {
						return rest.update('civil-status-mtlb', dto).then(() => {
							setSelectedRecord(false);
							setAmendmentForm(true);
						})
					} catch (err) {
						alert(err);
					}
				})
			} else {
				if (response.birthCountry)
					dto.birthCountry = response.birthCountry.key;
				try {
					return rest.update('civil-status-mtlb', dto).then(() => {
						setSelectedRecord(false);
						setAmendmentForm(true);
					})
				} catch (err) {
					alert(err);
				}
			}
		})
	}

	const getButtons = () => {
		return (
			<>
				<div className="row">
					<div className="col-md-4">
						<button style={{ minWidth: '5rem', margin: 'auto' }} type="button" className={'reject-button'} onClick={() => returnToResult()}> {t`Return To Result`} </button>
					</div>
					<div className="col-md-4">
						<button style={{ minWidth: '5rem', margin: 'auto' }} type="button" className={'reject-button'} onClick={() => attachAndSubmit()}> {t`Attach And Submit`} </button>
					</div>
					<div className="col-md-4">
						<button style={{ minWidth: '5rem', margin: 'auto' }} type="button" className={'reject-button'} onClick={() => displayAmendmentForm()}> {t`Attach And Edit`} </button>
					</div>
				</div>
			</>
		)
	}

	return (
		<>
			<Button className="link-button" onClick={() => openSidePanel(props.id)}>{t`Notes`}</Button>
			{(newForm) ?
				<BirthRegistrationFormComponent key={props.uuid} id={props.id} onSubmit={save} fields={digitalizationFields} />
				: (selectedRecord) ?
					<CivilRecordForm loadData={async () => loadFormData(selectedId)} readOnly buttons={getButtons} />
					: (amendmentForm) ?
						<AmendmentApplicationFormComponent id={props.id} onSubmit={save} key={props.uuid} fields={amendmentApplicationFields} />
						:
						<>
							<div className="row">
								<div className="col-md-6">
									<button style={{ minWidth: '5rem', margin: 'auto' }} type="button" className={'reject-button'} onClick={() => createNewForm()}> {t`New Form`} </button>
								</div>
								<div className="col-md-6">
									<button style={{ minWidth: '5rem', margin: 'auto' }} type="button" className={'reject-button'} onClick={() => trash(props.id)}> {t`Trash`} </button>
								</div>
							</div>
							<div className="search">
								<SearchBar
									style={{ marginLeft: '1rem' }}
									onChange={onChange}
									onRequestSearch={onRequestSearch} />
							</div>
							{(searchResult) ?
								<DigitalizationSearchList key={props.uuid} loadData={async (query) => buildData(query)} actions={getTableActions()} noSearch={true} />
								: null}
						</>
			}
		</>
	)
}

const openSidePanel = (id) => {
	let noteFilter = { and: true };
	noteFilter['civil-status-mtlb-note'] = { civilStatusMtlbId: id };
	rest.search('civil-status-mtlb-note', noteFilter).then(notes => {
		OPEN_SIDE_PANEL.publish(
			{ body: <NotesGrid list={notes} />, title: t`Notes` }
		)
	})
}

export const displayReadDigitalizationSearchForm = (gui, id) => {
	const readDigitalizationSearchForm = (onFinish) => () => {
		let uuid = uuidv4();
		return {
			uuid, view: () => {
				return (
					<>
						<DigitalizationForm uuid={uuid} id={id} />
					</>
				)
			}
		};
	}
	readDigitalizationAttachment(id).then(async response => {
		openNewImageTab(response[0].id);
		gui.goTo(readDigitalizationSearchForm());
	})
}


export const openNewImageTab = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	const openedWindows = windowContext.getOpenedWindows();
	let imageSrc = `${getServiceUri()}civil-status-mtlb/attachment/content/${id}/${token}`;

	if (openedWindows && !openedWindows.closed) {
		openedWindows.document.body.innerHTML = `
					<div style="position: fixed;background-color: white; z-index: 2; width: 100%; height: 50px;">
					<div style="text-align: center">
						<button id="zoomInButton">Zoom In</button>
						<button id="zoomOutButton">Zoom Out</button>
					</div>
					</div>
					<div style="display: flex; justify-content: center; align-items: center; height: 100vh;">
							<div>
								<img src="${imageSrc}" alt="${id}" style="max-width: 100%; max-height: 80vh; position: absolute" />
							</div>
					</div>`;
		openedWindows.document.head.innerHTML = `<link rel="stylesheet" href="https://kit.fontawesome.com/0ca62cbbb2.css" crossorigin="anonymous">`
		openedWindows.document.body.style.marginTop = '0';
		const imageElement = openedWindows.document.querySelector('img');
		let currentScale = 1; // Initial scale factor
		let initialMouseX;
		let initialMouseY;
		let initialImageX;
		let initialImageY;
		let dragging = false;

		// Function to calculate initial image position at the center
		function centerImage() {
		// Set initial position of the image
		imageElement.style.left = '30%';
		imageElement.style.top = '10%';
		}
		// Function to zoom in
		function zoomIn() {
			currentScale += 0.1;
			imageElement.style.transform = `scale(${currentScale})`;
		}

		// Function to zoom out
		function zoomOut() {
			if (currentScale > 0.1) { // Ensure not to zoom out beyond zero
				currentScale -= 0.1;
				imageElement.style.transform = `scale(${currentScale})`;
			}
		}

		// Function to handle mouse down event for starting drag
		function startDrag(e) {
		e.preventDefault();
		dragging = true;
		initialMouseX = e.clientX;
		initialMouseY = e.clientY;
		initialImageX = parseInt(imageElement.style.left) || 0; // Use current position if already dragged
		initialImageY = parseInt(imageElement.style.top) || 0; // Use current position if already dragged
		}

		// Function to handle mouse move event for dragging
		function drag(e) {
		if (dragging) {
			const deltaX = e.clientX - initialMouseX;
			const deltaY = e.clientY - initialMouseY;
			imageElement.style.left = `${initialImageX + deltaX}px`;
			imageElement.style.top = `${initialImageY + deltaY}px`;
		}
		}

		// Function to handle mouse up event for stopping drag
		function stopDrag() {
		dragging = false;
		}

		// Attach mouse event listeners for dragging
		imageElement.addEventListener('mousedown', startDrag);
		openedWindows.document.addEventListener('mousemove', drag);
		openedWindows.document.addEventListener('mouseup', stopDrag);


		// Attach click event listeners to zoom buttons
		const zoomInButton = openedWindows.document.getElementById('zoomInButton');
		const zoomOutButton = openedWindows.document.getElementById('zoomOutButton');

		zoomInButton.addEventListener('click', zoomIn);
		zoomOutButton.addEventListener('click', zoomOut);

		zoomInButton.innerHTML = '<i class="fa-regular fa-magnifying-glass-plus"></i>';
		zoomInButton.style.backgroundColor = '#fff';
		zoomInButton.style.border = 'none';
		zoomInButton.style.cursor = 'pointer';
		zoomInButton.style.padding = '10px';
		zoomInButton.style.marginRight = '5px';
		zoomInButton.style.fontSize = '25px';
		zoomInButton.style.backgroundColor = 'transparent';

		// Create a button for zoom out with Font Awesome icon
		zoomOutButton.innerHTML = '<i class="fa-regular fa-magnifying-glass-minus"></i>';
		zoomOutButton.style.backgroundColor = '#fff';
		zoomOutButton.style.border = 'none';
		zoomOutButton.style.cursor = 'pointer';
		zoomOutButton.style.padding = '10px';
		zoomOutButton.style.fontSize = '25px';
		zoomOutButton.style.backgroundColor = 'transparent';

		// Center the image initially when it's fully loaded
		imageElement.onload = centerImage;
	} else {
		const newWindow = window.open("", "_blank", "menubar=no");
		if (newWindow && newWindow.document) {
			newWindow.document.body.innerHTML = `
							<div style="position: fixed;background-color: white; z-index: 2; width: 100%; height: 50px;">
							<div style="text-align: center">
								<button id="zoomInButton">Zoom In</button>
								<button id="zoomOutButton">Zoom Out</button>
							</div>
							</div>
							<div style="display: flex; justify-content: center; align-items: center; height: 100vh;">
									<div>
										<img src="${imageSrc}" alt="${id}" style="max-width: 100%; max-height: 80vh; position: absolute" />
									</div>
							</div>`;
			newWindow.document.head.innerHTML = `<link rel="stylesheet" href="https://kit.fontawesome.com/0ca62cbbb2.css" crossorigin="anonymous">`
			windowContext.setOpenedWindows(newWindow);
			newWindow.document.body.style.marginTop = '0';
		} else {
			console.error('Failed to open a new window or access the document.');
		}

		const imageElement = newWindow.document.querySelector('img');
		let currentScale = 1; // Initial scale factor
		let initialMouseX;
		let initialMouseY;
		let initialImageX;
		let initialImageY;
		let dragging = false;

		// Function to calculate initial image position at the center
		function centerImage() {
		// Set initial position of the image
		imageElement.style.left = '30%';
		imageElement.style.top = '10%';
		}
		// Function to zoom in
		function zoomIn() {
			currentScale += 0.1;
			imageElement.style.transform = `scale(${currentScale})`;
		}

		// Function to zoom out
		function zoomOut() {
			if (currentScale > 0.1) { // Ensure not to zoom out beyond zero
				currentScale -= 0.1;
				imageElement.style.transform = `scale(${currentScale})`;
			}
		}

		// Function to handle mouse down event for starting drag
		function startDrag(e) {
		e.preventDefault();
		dragging = true;
		initialMouseX = e.clientX;
		initialMouseY = e.clientY;
		initialImageX = parseInt(imageElement.style.left) || 0; // Use current position if already dragged
		initialImageY = parseInt(imageElement.style.top) || 0; // Use current position if already dragged
		}

		// Function to handle mouse move event for dragging
		function drag(e) {
		if (dragging) {
			const deltaX = e.clientX - initialMouseX;
			const deltaY = e.clientY - initialMouseY;
			imageElement.style.left = `${initialImageX + deltaX}px`;
			imageElement.style.top = `${initialImageY + deltaY}px`;
		}
		}

		// Function to handle mouse up event for stopping drag
		function stopDrag() {
		dragging = false;
		}

		// Attach mouse event listeners for dragging
		imageElement.addEventListener('mousedown', startDrag);
		newWindow.document.addEventListener('mousemove', drag);
		newWindow.document.addEventListener('mouseup', stopDrag);


		// Attach click event listeners to zoom buttons
		const zoomInButton = newWindow.document.getElementById('zoomInButton');
		const zoomOutButton = newWindow.document.getElementById('zoomOutButton');

		zoomInButton.addEventListener('click', zoomIn);
		zoomOutButton.addEventListener('click', zoomOut);

		zoomInButton.innerHTML = '<i class="fa-regular fa-magnifying-glass-plus"></i>';
		zoomInButton.style.backgroundColor = '#fff';
		zoomInButton.style.border = 'none';
		zoomInButton.style.cursor = 'pointer';
		zoomInButton.style.padding = '10px';
		zoomInButton.style.marginRight = '5px';
		zoomInButton.style.fontSize = '25px';
		zoomInButton.style.backgroundColor = 'transparent';

		// Create a button for zoom out with Font Awesome icon
		zoomOutButton.innerHTML = '<i class="fa-regular fa-magnifying-glass-minus"></i>';
		zoomOutButton.style.backgroundColor = '#fff';
		zoomOutButton.style.border = 'none';
		zoomOutButton.style.cursor = 'pointer';
		zoomOutButton.style.padding = '10px';
		zoomOutButton.style.fontSize = '25px'
		zoomOutButton.style.backgroundColor = 'transparent';

		// Center the image initially when it's fully loaded
		imageElement.onload = centerImage;
	}


}


export const readDigitalizationAttachment = async (civilStatusMtlbId) => {
	let attachmentFilter = { and: true };
	attachmentFilter['civil-status-mtlb-attachment'] = { civilStatusMtlbId: civilStatusMtlbId };
	return rest.search('civil-status-mtlb/attachment', attachmentFilter)
}

const filterData = (DefaultRows) => {
	const newRows = [];
	for (let i in DefaultRows) {
		let row = DefaultRows[i];
		let faceUrl = null;
		if (row.faceId != null) {
			faceUrl = getServiceUri() + "face/image/" + row.faceId;
		}
		row.image = (faceUrl != null) ? faceUrl : "/public/avatar.png";
		let date = row.birthdate
		if (date !== null)
			row.birthdate = date[2] + '-' + date[1] + '-' + date[0];
		newRows.push(row);
	}
	return newRows;
}

const save = async (formData) => {
	formData.draft = false;
	formData.mtlbStatus = MTLB_STATUS_SUBMITTED;
	return updateBirthRegistrationForm(formData).then((response) => {
		showNotification(t`Created Application`, "success");
	});
}

const loadFormData = async (id) => {
	let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	return await rest.read('vital-record', id).then(async response => {
		let form = response;
		if (response.faceId != null) {
			const imageUrl = getServiceUri() + 'face/image/' + response.faceId + "/" + token;
			form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: (imageUrl != null) ? imageUrl : '/public/avatar.png', isEmpty: false };
		} else
			form['image'] = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true };
		if (response.birthCountry != null) {
			let countryMetadata = metadataLoader.get('country');
			form.birthCountry = { key: response.birthCountry, value: countryMetadata[response.birthCountry] }
		}
		if (response.otherCitizenshipCsv == null || response.otherCitizenshipCsv === "")
			form.otherCitizenshipCsv = []
		else {
			let values = [];
			let components = response.otherCitizenshipCsv.split(",");
			components.forEach(element => {
				values.push(parseInt(element))
			});
			form.otherCitizenshipCsv = values;
		}
		if (response.birthTime !== null) {
			response.birthTime = new Date().setHours(response.birthTime[0], response.birthTime[1]);
		}
		if (response.typeOfAcquisition != null)
        	form.typeOfAcquisition = TypeOfAcquisition[response.typeOfAcquisition]
		if (response.motherId == null) {
			if (response.reportedMotherName != null)
					form.motherInfo = "motherWithoutBirthRecord"
			else if (response.unknownMother != null && response.unknownMother)
				form.motherInfo = "unknownMother"
			else
				form.motherInfo = "motherWithoutBirthRecord"
		} else
			form.motherInfo = "motherWithBirthRecord"

		if (response.fatherId == null) {
			if (response.reportedFatherName != null)
				form.fatherInfo = "fatherWithoutBirthRecord"
			else if (response.unknownFather != null && response.unknownFather)
				form.fatherInfo = "unknownFather"
			else
				form.fatherInfo = "fatherWithoutBirthRecord"
		} else
			form.fatherInfo = "fatherWithBirthRecord"
		let tagFilter = { and: true };
		tagFilter['vital-record-tag'] = { vitalRecordId: id };
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return rest.search('vital-record-tag', tagFilter).then(tags => {
			form['tags'] = tags
			if (tags.status)
				showNotification(response.message.split('Detail: ')[1], "error");
			return form;
		})
	})
}

const trash = (id) => {
	let filter = {};
	filter.mtlbId = id;
	rest.request(getServiceUri() + 'apply/trash-digitaization-mtlb', "POST", filter).then(response => {
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		else
			showNotification(t`Application sended to trash`, "success");
	})
}
