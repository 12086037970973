export const FileType = {
	"OTHER": 1
	,
	"MEDICAL_NOTIFICATION": 2
	
}
Object.freeze(FileType);
const toTypeMap = {};
Object.keys(FileType).forEach(key => {
	toTypeMap[FileType[key]] = key;
});
export function getFileType (value) {
	return toTypeMap[value];
}

export function getAllFileType () {
    return Object.keys(FileType);
}

export const FILE_TYPE_OTHER = "OTHER";
export const FILE_TYPE_MEDICAL_NOTIFICATION = "MEDICAL_NOTIFICATION";
