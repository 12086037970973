import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

export const SidePanelComponent = (props) => {

    return (
        <SlidingPane
            className="some-custom-class"
            overlayClassName="some-custom-overlay-class"
            isOpen={props.open}
            title={props.title}
            onRequestClose={() => {
                // triggered on "<" on left top click or on outside click
                props.closePanel()
            }}
        >
            {props.body}
      </SlidingPane>
    )
}