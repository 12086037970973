import React from "react";
import {v4 as uuidv4} from 'uuid';
import { Centrifuge } from "centrifuge";
import { formState } from "../../../auto/js/forms/FormState";
import { Task } from "./Task";
import { MTLB_STATUS_ARCHIVED, MTLB_STATUS_READY_FOR_APPROVAL } from "../../../auto/js/metadata/MtlbStatus";
import { rest } from "../../../auto/js/services";
import { loadingIcon } from "../../../auto/js/loadingIcon";
import { getServiceUri } from "../../../auto/js/metadata";

export class RecordRegistrationTask extends Task {
    constructor(id, name, gui) {
        super(id, name, gui);
    }
    
    start = (onFinishCallback) => {
        this.onFinishCallback = onFinishCallback;
        this.gui.goTo(displayRecordRegistrationLoading(this.complete), formState.getState().civilStatusMtlbId, null);
    }
}

const displayRecordRegistrationLoading = (onFinish) => (id) => {
    let uuid = uuidv4();
	return { 
		uuid, view: () => <LoadingComponent key={uuid} id={id} onFinish={() => onFinish()}/>
	}
}

export class LoadingComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            status: MTLB_STATUS_READY_FOR_APPROVAL,
        }

        this.centrifuge = new Centrifuge("wss://rv5.vu.chahinehamila.com/connection/websocket", {
            token: rest.oidcService.token,
            getToken: function () {
              return new Promise((resolve, reject) => {
                rest.oidcService.updateToken(55).then(function () {
                  resolve(rest.oidcService.token);
                }).catch(function (err) {
                  reject(err);
                  rest.oidcService.logout();
                });
              })
            }
          });
        
          this.centrifuge.on('connecting', function (ctx) {
            console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
          }).on('connected', function (ctx) {
            console.log(`connected over ${ctx.transport}`);
          }).on('disconnected', function (ctx) {
            console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
          }).connect();
          this.sub = this.centrifuge.newSubscription("1." + this.props.id);
    }

    componentDidMount() {
      this.getServiceStatus();
      }
  
    componentWillUnmount() {
        if (this.interval) {
        clearInterval(this.interval);
        }
    }

    getServiceStatus = () => {
      this.interval = setInterval(() => {
        let filter = {};
        filter.type = 1;
        filter.id = this.props.id
        rest.request(getServiceUri() + 'apply/get-service-status', 'POST', filter).then(response => {
            if (response.serviceStatus === MTLB_STATUS_ARCHIVED) {
                this.sub.off('publication', this.handleStatusChange);
                this.props.onFinish()
                clearInterval(this.interval);
            }
        })
      }, 3000)
    };

    handleStatusChange = (ctx) => {
        if (ctx.data.value.key === "status") {
            this.setState({status: ctx.data.value.value});
            if (ctx.data.value.value === MTLB_STATUS_ARCHIVED) {
                this.props.onFinish()
                this.sub.off('publication', this.handleStatusChange);
            }
        }
    }

    render() {
        this.sub.on('publication', this.handleStatusChange).subscribe();
        return loadingIcon;
    }

}