import React, { useState } from "react";
import { FormControl, FormLabel, IconButton, InputLabel, TextField } from '@mui/material';
import { t } from "../../../auto/js/services";
import { ImageViewer } from "../../../auto/js/widgets";
import "../../../auto/js/pages/Form.css";
import { Button } from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';



export class FileComponent extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef()
        this.state = {
            data: props.data,
            loading: true,
            loadingMessage: '',
            isEditing: false
        }
    }

    openEdit = () => {
        this.setState({ isEditing: true });
    }

    handleSave = ({ fileName, description }) => {
        let newData = this.state.data;
        newData.fileName = fileName;
        newData.description = description;
        this.setState({ isEditing: false, data: newData });
        this.props.updateFileData({ fileName: fileName, description: description, id: this.state.data.id })
    };

    handleCancel = () => {
        this.setState({ isEditing: false });
    }

    render() {
        let fileName = '';
        let description = '';
        let previewUrl = null;
        let preview = {};
        if (this.state.data) {
            fileName = this.state.data.fileName ? this.state.data.fileName : '';
            description = this.state.data.description ? this.state.data.description + ' ' : '';
            if (this.props.previewUrl && this.props.token)
                previewUrl = this.props.previewUrl() + "/" + this.props.token;
            preview = { x: 0.5, y: 0.5, scale: 1, rotate: 0, url: (previewUrl != null) ? previewUrl : '/public/file-preview.png', onErrorUrl: '/public/file-preview.png', isEmpty: false }
        }
        return (
            <>
                {(this.state.data) ?
                    <div class="row rv5info">
                        {(!this.props.disabledPhoto) &&
                            <div class="col-md-2" style={{ marginBottom: '15px' }}>
                                <ImageViewer image={preview} width={125} height={125} borderRadiusStyle={'0'} />
                            </div>}
                        <div class="col-md-7"><div class="results" style={{ paddingLeft: "0px" }}><h4>{t`File Details`}</h4>
                            {(this.state.isEditing) ?
                                <EditableForm
                                    fileName={fileName}
                                    description={description}
                                    onSave={this.handleSave}
                                    oncancel={this.handleCancel}
                                />
                                : <>
                                    <p>{t`File Name`}: {fileName}</p>
                                    <p>{t`Description`}: {description}</p>
                                </>
                            }
                        </div>
                        </div>
                        {(!this.props.readOnly) && <div class="col-md-2" style={{ textAlign: '-webkit-right' }}>
                            <IconButton aria-label={t`Edit`} onClick={() => this.openEdit()}>
                                <EditIcon />
                            </IconButton>
                            <IconButton aria-label={t`delete`} onClick={this.props.onDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </div>}
                        <div class="col-md-1">
                            <Button className="link-button" onClick={this.props.onDownload}>{t`Download File`}</Button>
                        </div>
                    </div> :
                    <div className="ValidationError">{t`File not found`}</div>
                }
            </>
        )
    }
}

const EditableForm = ({ fileName, description, onSave, oncancel }) => {
    const [editedFileName, setEditedFileName] = useState(fileName);
    const [editedDescription, setEditedDescription] = useState(description);

    const handleSave = () => {
        onSave({ fileName: editedFileName, description: editedDescription });
    };

    const handleCancel = () => {
        oncancel()
    }

    return (
        <div>
            <div className="col-md-6">
                <FormControl variant="standard">
                    <InputLabel shrink htmlFor={"fileName"}>
                        {t`File Name`}
                    </InputLabel>
                    <TextField
                        id={"fileName"}
                        value={editedFileName}
                        multiline
                        rowsMax="4"
                        onChange={(e) => setEditedFileName(e.target.value)}
                        type={"text"}
                        InputLabelProps={{ shrink: false }}
                    />
                </FormControl>
            </div>
            <div className="col-md-6">
                <FormControl variant="standard">
                    <InputLabel shrink htmlFor={"description"}>
                        {`Description`}
                    </InputLabel>
                    <TextField
                        id={"description"}
                        value={editedDescription}
                        multiline
                        rowsMax="4"
                        onChange={(e) => setEditedDescription(e.target.value)}
                        type={"text"}
                        InputLabelProps={{ shrink: false }}
                    />
                </FormControl>
            </div>
            <div className="col-md-6" style={{ textAlign: '-webkit-right' }}>
                <IconButton aria-label={t`Save`} onClick={handleSave}>
                    <CheckIcon />
                </IconButton>
                <IconButton aria-label={t`Cancel`} onClick={handleCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
        </div>
    );
};