import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services";
import { RVFOUR_BIRTH_MTLB_ORDER_BY_FIRSTNAME } from "../../../../auto/js/metadata/RvfourBirthMtlbOrderBy";
import { MTLB_TYPE_RV4_BIRTH_FAMILY_TREE_RECORD, MTLB_TYPE_RV4_BIRTH_VITAL_RECORD } from "../../../../auto/js/metadata/MtlbType";

export const familyTreeRegistrationListColumns = [
    {title:"image", field:"image", render: rowData => <img src={rowData.image} onError={(e)=>{e.target.onerror = null; e.target.src="/public/avatar.png"}} style={{width: 40, borderRadius: '50%', height: 40}}/>},
    {title: "vitalRecordId", field: "vitalRecordId"},
    {title: "fourthname", field: "fourthname"},
    {title: "firstname", field: "firstname"},
    {title: "birthdate", field: "birthdate"},
    
];

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "rvfour-birth-mtlb/search-by-mtlb-type-and-status/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "rvfour-birth-mtlb/count/search-by-mtlb-type-and-status/", "POST", filter);
}

export const getOrderBy = () => {
    return RVFOUR_BIRTH_MTLB_ORDER_BY_FIRSTNAME
}

export const getMtlbTypeList = () => {
    return [MTLB_TYPE_RV4_BIRTH_FAMILY_TREE_RECORD]
}

export const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let face = null;
        if (row.face != null) {
			let mimeType = row['faceMimeType'];
			face = "data:".concat(mimeType, ";base64,", row.face);		
		}
        row.image = (face != null)?face:"/public/avatar.png";
        let date = row.birthdate
        if ( date !== null)
        row.birthdate = date[2] + '-' + date[1] + '-' + date[0]; 
      newRows.push(row);
    }
    return newRows;
}

export const getErrorList = () => {
    return [];
}