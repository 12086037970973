export const CIVIL_STATUS_MTLB_ORDER_BY_UNKNOWN_FATHER = "UNKNOWN_FATHER";
export const CIVIL_STATUS_MTLB_ORDER_BY_AFIS_ID = "AFIS_ID";
export const CIVIL_STATUS_MTLB_ORDER_BY_BIRTH_COUNTRY = "BIRTH_COUNTRY";
export const CIVIL_STATUS_MTLB_ORDER_BY_BIRTH_DAY_UNKNOWN = "BIRTH_DAY_UNKNOWN";
export const CIVIL_STATUS_MTLB_ORDER_BY_BIRTH_MONTH_UNKNOWN = "BIRTH_MONTH_UNKNOWN";
export const CIVIL_STATUS_MTLB_ORDER_BY_BIRTH_PLACE = "BIRTH_PLACE";
export const CIVIL_STATUS_MTLB_ORDER_BY_BIRTH_TIME = "BIRTH_TIME";
export const CIVIL_STATUS_MTLB_ORDER_BY_BIRTH_TIME_UNKNOWN = "BIRTH_TIME_UNKNOWN";
export const CIVIL_STATUS_MTLB_ORDER_BY_BIRTH_YEAR_UNKNOWN = "BIRTH_YEAR_UNKNOWN";
export const CIVIL_STATUS_MTLB_ORDER_BY_BIRTHDATE = "BIRTHDATE";
export const CIVIL_STATUS_MTLB_ORDER_BY_DATE_OF_DEATH = "DATE_OF_DEATH";
export const CIVIL_STATUS_MTLB_ORDER_BY_DEATH_DAY_UNKNOWN = "DEATH_DAY_UNKNOWN";
export const CIVIL_STATUS_MTLB_ORDER_BY_DEATH_MONTH_UNKNOWN = "DEATH_MONTH_UNKNOWN";
export const CIVIL_STATUS_MTLB_ORDER_BY_DEATH_TIME_UNKNOWN = "DEATH_TIME_UNKNOWN";
export const CIVIL_STATUS_MTLB_ORDER_BY_DEATH_YEAR_UNKNOWN = "DEATH_YEAR_UNKNOWN";
export const CIVIL_STATUS_MTLB_ORDER_BY_DEDUPLICATION_MTLB_ID = "DEDUPLICATION_MTLB_ID";
export const CIVIL_STATUS_MTLB_ORDER_BY_DISABILITY = "DISABILITY";
export const CIVIL_STATUS_MTLB_ORDER_BY_DONOR = "DONOR";
export const CIVIL_STATUS_MTLB_ORDER_BY_DONOR_NOTES = "DONOR_NOTES";
export const CIVIL_STATUS_MTLB_ORDER_BY_DRAFT = "DRAFT";
export const CIVIL_STATUS_MTLB_ORDER_BY_FACE = "FACE";
export const CIVIL_STATUS_MTLB_ORDER_BY_FACE_MIME_TYPE = "FACE_MIME_TYPE";
export const CIVIL_STATUS_MTLB_ORDER_BY_FATHER_ID = "FATHER_ID";
export const CIVIL_STATUS_MTLB_ORDER_BY_FIFTHNAME = "FIFTHNAME";
export const CIVIL_STATUS_MTLB_ORDER_BY_FINGERS_MIME_TYPE = "FINGERS_MIME_TYPE";
export const CIVIL_STATUS_MTLB_ORDER_BY_FIRSTNAME = "FIRSTNAME";
export const CIVIL_STATUS_MTLB_ORDER_BY_FOURTHNAME = "FOURTHNAME";
export const CIVIL_STATUS_MTLB_ORDER_BY_GENDER = "GENDER";
export const CIVIL_STATUS_MTLB_ORDER_BY_ID = "ID";
export const CIVIL_STATUS_MTLB_ORDER_BY_LEFT_INDEX_FINGER = "LEFT_INDEX_FINGER";
export const CIVIL_STATUS_MTLB_ORDER_BY_LEFT_MIDDLE_FINGER = "LEFT_MIDDLE_FINGER";
export const CIVIL_STATUS_MTLB_ORDER_BY_LEFT_PINKY = "LEFT_PINKY";
export const CIVIL_STATUS_MTLB_ORDER_BY_LEFT_RING_FINGER = "LEFT_RING_FINGER";
export const CIVIL_STATUS_MTLB_ORDER_BY_LEFT_THUMB = "LEFT_THUMB";
export const CIVIL_STATUS_MTLB_ORDER_BY_MARITAL_STATUS = "MARITAL_STATUS";
export const CIVIL_STATUS_MTLB_ORDER_BY_MOTHER_ID = "MOTHER_ID";
export const CIVIL_STATUS_MTLB_ORDER_BY_MTLB_STATUS = "MTLB_STATUS";
export const CIVIL_STATUS_MTLB_ORDER_BY_MTLB_STATUS_DATA = "MTLB_STATUS_DATA";
export const CIVIL_STATUS_MTLB_ORDER_BY_MTLB_TYPE = "MTLB_TYPE";
export const CIVIL_STATUS_MTLB_ORDER_BY_NOTES = "NOTES";
export const CIVIL_STATUS_MTLB_ORDER_BY_NUMBER_OF_ATTACHMENTS = "NUMBER_OF_ATTACHMENTS";
export const CIVIL_STATUS_MTLB_ORDER_BY_OTHER_CITIZENSHIP_CSV = "OTHER_CITIZENSHIP_CSV";
export const CIVIL_STATUS_MTLB_ORDER_BY_REPORTED_FATHER_NAME = "REPORTED_FATHER_NAME";
export const CIVIL_STATUS_MTLB_ORDER_BY_REPORTED_ID = "REPORTED_ID";
export const CIVIL_STATUS_MTLB_ORDER_BY_REPORTED_LEGACY_ID = "REPORTED_LEGACY_ID";
export const CIVIL_STATUS_MTLB_ORDER_BY_REPORTED_MOTHER_NAME = "REPORTED_MOTHER_NAME";
export const CIVIL_STATUS_MTLB_ORDER_BY_RIGHT_INDEX_FINGER = "RIGHT_INDEX_FINGER";
export const CIVIL_STATUS_MTLB_ORDER_BY_RIGHT_MIDDLE_FINGER = "RIGHT_MIDDLE_FINGER";
export const CIVIL_STATUS_MTLB_ORDER_BY_RIGHT_PINKY = "RIGHT_PINKY";
export const CIVIL_STATUS_MTLB_ORDER_BY_RIGHT_RING_FINGER = "RIGHT_RING_FINGER";
export const CIVIL_STATUS_MTLB_ORDER_BY_RIGHT_THUMB = "RIGHT_THUMB";
export const CIVIL_STATUS_MTLB_ORDER_BY_SECONDNAME = "SECONDNAME";
export const CIVIL_STATUS_MTLB_ORDER_BY_SIGNATURE = "SIGNATURE";
export const CIVIL_STATUS_MTLB_ORDER_BY_SIGNATURE_MIME_TYPE = "SIGNATURE_MIME_TYPE";
export const CIVIL_STATUS_MTLB_ORDER_BY_THIRDNAME = "THIRDNAME";
export const CIVIL_STATUS_MTLB_ORDER_BY_TIME_OF_DEATH = "TIME_OF_DEATH";
export const CIVIL_STATUS_MTLB_ORDER_BY_TOUCHED = "TOUCHED";
export const CIVIL_STATUS_MTLB_ORDER_BY_VITAL_RECORD_ID = "VITAL_RECORD_ID";
export const CIVIL_STATUS_MTLB_ORDER_BY_WITNESS1_ID = "WITNESS1_ID";
export const CIVIL_STATUS_MTLB_ORDER_BY_WITNESS2_ID = "WITNESS2_ID";
export const CIVIL_STATUS_MTLB_ORDER_BY_MEDICAL_NOTIFICATION = "MEDICAL_NOTIFICATION";
export const CIVIL_STATUS_MTLB_ORDER_BY_BOOK = "BOOK";
export const CIVIL_STATUS_MTLB_ORDER_BY_PAGE = "PAGE";
export const CIVIL_STATUS_MTLB_ORDER_BY_SIXTHNAME = "SIXTHNAME";
export const CIVIL_STATUS_MTLB_ORDER_BY_YEAR = "YEAR";
export const CIVIL_STATUS_MTLB_ORDER_BY_DECLARANT_ID = "DECLARANT_ID";
export const CIVIL_STATUS_MTLB_ORDER_BY_UPLOADED = "UPLOADED";
export const CIVIL_STATUS_MTLB_ORDER_BY_UUID = "UUID";
export const CIVIL_STATUS_MTLB_ORDER_BY_PRIMARY_CITIZENSHIP = "PRIMARY_CITIZENSHIP";
export const CIVIL_STATUS_MTLB_ORDER_BY_DEATH_PLACE = "DEATH_PLACE";
export const CIVIL_STATUS_MTLB_ORDER_BY_BURIAL_PLACE = "BURIAL_PLACE";
