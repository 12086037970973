import React from "react";
import _ from 'lodash';
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import { dto2form, form2dto } from "../../../../main/js/forms/divorceRegistration/DivorceRegistrationFormCommon";

export const saveDivorceRegistrationForm = async (formData) => {
	let data = _.clone(formData);
    let dto = pojoMetadata['union-mtlb'].form2dto(data);
    form2dto(formData, dto);
    try {
		return rest.request(getServiceUri() + 'union-mtlb/new-document', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadDivorceRegistrationFormData = async (id) => {
	return await rest.read('union-mtlb', id).then(response => {
		let form = dto2form(response);
		let tagFilter = {and: true};
		tagFilter['union-mtlb-tag'] = {unionMtlbId: id};

		return rest.search('union-mtlb-tag', tagFilter).then(tags => {
			form['tags'] = tags

			return form;
		})
	})
}

export const updatePendingDivorceRegistrationForm = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['union-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'union-mtlb/pending/update', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const updateDivorceRegistrationForm = async (formData) => {
	let data = _.clone(formData);
	const dto = pojoMetadata['union-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'union-mtlb/edit-document', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const deleteDivorceRegistrationForm = async (id) => {
    try {
        return rest.delete('union-mtlb', id);
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedDivorceRegistrationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let data = _.clone(formData);
	let dto = pojoMetadata['union-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'union-mtlb/rejected/update', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const rejectDivorceRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'union-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const rejectReadyDivorceRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'union-mtlb/ready/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const approveReadyDivorceRegistrationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'union-mtlb/ready/approve', 'POST', id);
    } catch (err) {
        alert(err);
    }
}
