import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { t } from "../../../auto/js/services";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { issuedIdCardColumns } from "../../../main/js/lists/IssuedIdCardListCommon";
import { buildData, transformAdvancedSearchData, advancedSearchfields } from "../../../main/js/lists/IssuedIdCardListCommon";
import { displayReadIssuedDocumentsForm } from "../../../main/js/forms/IssuedDocumentsFormCommon"
import { OPEN_ADVANCED_SEARCH } from "../events/Gui";
import { OPEN_CONFIRMATION_DIALOG } from "../events/Gui";
 
const IdCardListPage = ({gui, uuid, searchData}) => {
    const [advancedSearchData, setAdvancedSearchData] = useState(searchData);

    let IdCardList = createTableComponent(issuedIdCardColumns);
    if (advancedSearchData != searchData)
		setAdvancedSearchData(searchData);

        return (
            <IdCardList key={uuid} gui={gui} loadData={async (query) => buildData(query, advancedSearchData)} actions={getTableActions(gui)} />
        );
}

export const displayIdCardList = (gui, searchData) => {

    const readIdCardList = (onFinish) => () => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <IdCardListPage key={uuid} gui={gui} searchData={searchData} />
        };
    }
    gui.goTo(readIdCardList())
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    gui.goTo(displayReadIssuedDocumentsForm((options) => displayConfirmationDialog(options, gui)), rowData.id);
                }
        }
    )
    return actions;
}



export const displayIdCardAdvancedSearch = (gui) => {
    let advancedSearchFilters = {fields: advancedSearchfields, name: t`ID Card`, onSubmit: (filter) => onAdvancedSearchSubmit(filter, gui)};
	OPEN_ADVANCED_SEARCH.publish(advancedSearchFilters);
}

const onAdvancedSearchSubmit = (filter, gui) => {
    displayIdCardList(gui, transformAdvancedSearchData(filter));
}

export const displayConfirmationDialog = (options, gui) => {
	let data = {title: "Confirmation", message: options.message, onClick: () => gui.goTo(displayIdCardList(gui))};
	OPEN_CONFIRMATION_DIALOG.publish(data);
}