import React from "react";

import {getServiceUri, pojoMetadata} from "../../../auto/js/metadata";
import {rest, t} from "../../../auto/js/services";
import {ISSUED_DOCUMENTS_ORDER_BY_ID} from "../../../auto/js/metadata/IssuedDocumentsOrderBy";
import {DOCUMENT_TYPE_ID_CARD} from "../../../auto/js/metadata/DocumentType";
import { Separator } from "../forms/CivilRecordFormCommon";

export const issuedIdCardColumns = [
    {title: "documentId", field: "documentId"},
    {title: "vitalRecordId", field: "vitalRecordId"},
    {title: "Name", field: "fullName"},
    {title: "issuedDate", field: "issuedDate"},
    {title: "expires", field: "expires"},
    {title: "cancelledDate", field: "cancelledDate"}
];

export const buildData = async (query, advancedSearchData) => {
    let filter = query;
    let data;
    filter["issued-documents"] = {documentTypeList: [DOCUMENT_TYPE_ID_CARD]};
    filter["orderBy"] = ISSUED_DOCUMENTS_ORDER_BY_ID;
    if (advancedSearchData)
        filter["query"] = advancedSearchData;
    filter.orderDirection = null;
    filter.offset = query.page * query.pageSize;
    if (query.search && query.search != '') {
        pojoMetadata["issued-documents"].columns.forEach(element => {
            if (element.type == 'text') {
                filter["issued-documents"][element.key] = query.search;
            }
        });
        filter["and"] = false;
    }
    return await getIssuedDocumentData(filter).then(response => {
        data = filterData(response)
        return countData(filter).then((count) => {
            return {data: data, totalCount: count, page: query.page}
        })
    });
}
const getIssuedDocumentData = async (filter) => {
    let searchEndpoint = (filter.query)?"issued-documents/advanced-search":"issued-documents/search-by-document-type/";
    return await rest.request(getServiceUri() + searchEndpoint, "POST", filter);
}
const countData = async (filter) => {
    let countEndpoint = (filter.query)?"issued-documents/count/advanced-search":"issued-documents/count/search-by-document-type/";
    return await rest.request(getServiceUri() + countEndpoint, "POST", filter);
}
const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let issuedDate = row.issuedDate
        if (issuedDate !== null)
            row.issuedDate = issuedDate[2] + '-' + issuedDate[1] + '-' + issuedDate[0];
        let expires = row.expires
        if (expires !== null)
            row.expires = expires[2] + '-' + expires[1] + '-' + expires[0];
        let cancelledDate = row.cancelledDate
        if (cancelledDate !== null)
            row.cancelledDate = cancelledDate[2] + '-' + cancelledDate[1] + '-' + cancelledDate[0];
        newRows.push(row);
    }
    return newRows;
}

function formatId(eId) {
    if (eId.length < 9) {
        let diff = 9 - eId.length
        while (diff !== 0) {
            eId = "0" + eId;
            diff = diff - 1;
        }
    }
    let newString = eId.slice(0, 3) + '-' + eId.slice(3);
    let finalForm = newString.slice(0, 7) + '-' + newString.slice(7)
    return finalForm
}

export const getErrorList = () => {
    let list = [];
    list.push("E19");
    list.push("E22");
    return list;
}

export const advancedSearchfields = [
    {name: "caseSensitiveSearch",label:"Case Sensitive Search", type: "checkbox", x:1, y:1, layout:"col-md-3"},
    {name: "exactId", type: "number", x:1, y: 2, layout:"col-md-4"},
    {name: "exactDocumentId", type: "text", x:2, y: 2, layout:"col-md-4"},
    {name: "idSeparator", type: "custom", x:1, y:4, layout:"col-md-12", component: (name, disabled) => <Separator />},

    {name: "fullName", type: "text", x:1, y:5, layout:"col-md-6"},
    {name:"fullNameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:5, layout:"col-md-6 pt-5",defaultValue:"startsWith"},
        
    {name:"issuedBox", label: "Issued Date", components: [
        {name: "rangeIssueddate",label:"Exact Issued Date", type: "checkbox", x:1, y:1, layout:"col-md-12"},
        {name: "exactIssueddate", type: "date", x:1, y: 2, layout:"col-md-6", display: (data)=>{
            return !data.rangeIssueddate;
        }},
        
        {name: "issueddateStart", type: "date", x:1, y: 3, layout:"col-md-6",display: (data)=>{
            return data.rangeIssueddate;
        }},
        {name: "issueddateEnd", type: "date", x:2, y: 3, layout:"col-md-6", display: (data)=>{
            return data.rangeIssueddate;
        }},
    ], type: "box", x:1, y:8, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
];

export const transformAdvancedSearchData = (formData) => {
    const columns = [];
    
    const addColumn = (name, type, value, condition) => {
        columns.push({
        name,
        type,
        [type === 'date' ? 'dateValue' : type === 'boolean' ? 'booleanValue' : (type === 'text' ? 'textValue' : 'longValue')]: value,
        searchType: condition ? condition : null,
        });
    };
    (formData.fullName !== "") && addColumn('fullName', 'text', formData.fullName, formData.fullNameCondition);
    
   
    addColumn('documentType', 'number', 1, null);
    (formData.exactDocumentId !== "") && addColumn('documentId', 'text', formData.exactDocumentId, "exact");
    (formData.exactId !== "") && addColumn('vitalRecordId', 'number', formData.exactId, null);

    const issueddateRange = formData.issueddateStart && formData.issueddateEnd
    ? {
            min: formData.issueddateStart ? formData.issueddateStart : null,
            max: formData.issueddateEnd ? formData.issueddateEnd : null,
        }
    : null;
    (issueddateRange != null || formData.exactIssueddate != null) && addColumn('issuedDate', 'date', formData.exactIssueddate, null);
    if ((issueddateRange != null || formData.exactIssueddate != null))
        columns[columns.length - 1].dateRange = issueddateRange;

    return { columns, caseSensitiveSearch: formData.caseSensitiveSearch };
};
