import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT } from '../../events/Gui';
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { updateMarriageRegistrationForm, loadMarriageRegistrationFormData } from "./MarriageRegistrationApi";
import { marriageRegistrationFields } from "../../../../main/js/forms/marriageRegistration/MarriageRegistrationFormCommon";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AttachmentsArea } from "../../widgets/Attachments";

import { createTableComponent } from "../../widgets/TableComponent";
import { loadUnionMtlbAttachment } from "../../../../main/js/forms/marriageRegistration/MarriageRegistrationFormCommon";

let MarriageRegistrationForm = createFormComponent(marriageRegistrationFields);
	
class FormComponent extends React.Component {
	constructor(props) {
		super(props);
    }

	render() {
		return (
		<>
			<img alt="approved.png" src="/public/approved.png" style={{maxWidth: '130px'}}/>
			<MarriageRegistrationForm key={this.props.key} loadData={async () => loadFormData(this.props.id)} readOnly onSubmit={() => null} id={this.props.id} buttons={() => null} />
            <AttachmentsArea label="Attachments" previewUrl={(id) => getPreviewUrl(id)} loadData={async () => loadUnionMtlbAttachment(this.props.id)} handleClick={(id) => handleUnionMtlbAttachmentClick(id)} readOnly/>
		</>
		)
	}

}

const handleUnionMtlbAttachmentClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'union-mtlb/attachment' + '/' + id + '/' + token;
};
const getPreviewUrl = (id) => {
	return getServiceUri() + 'union-mtlb/attachment' + '/preview/' + id;
}
	

export const displayReadApprovedMarriageRegistrationForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} id={id} />
	};
}

const loadFormData = async (id) => {
	return await loadMarriageRegistrationFormData(id);
}
